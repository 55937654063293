import React from 'react'

import AuthorEdit from "./AuthorEdit"


class AddAuthorSection extends React.Component{
    render(){
        return (
            <section >
                <div className="container">
                    <div className="panel panel-primary">
                            <div className="panel-heading">
                                <h1 className="panel-title" >
                                       Create Author
                                </h1> 
                            </div>
                            <div className="panel-body">
                                <AuthorEdit create={true}/>
                            </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default AddAuthorSection;

