import React from 'react'
import api from "../api"
import confirmDelete from "./DialogConfirmDelete"
import MessageAlert from "./MessageAlert";
import {withRouter} from "react-router-dom"
import LoadIndicator from "./LoadIndicator"

class BookEditAuthors extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            authors: null,
            suggestions: [],
            successMessage: "",
            errorMessage: "",
            isSearching: false,
            searchText: this.props.searchText,
            typingTimeout: 0,
            isLoading: false
        }

        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
        this.getBookAuthors();

        if(this.state.searchText){
            this.performSearch(this.state.searchText)
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    componentDidUpdate(prevProps){
        if(prevProps.bookid !== this.props.bookid){
            this.getBookAuthors();
        }
        
        if(this.props.searchText !== prevProps.searchText){
            this.setState({searchText: this.props.searchText})
            this.performSearch(this.props.searchText)
        }
    }

    getBookAuthors = async  () => {
        this.setState({isLoading: true})
        const response = await api.auth.getBookAuthors(this.props.bookid);

        if(response && response.success){
            this.setState({authors: response.data, isLoading: false})
        }
    }


    onCreateClick = () => {
        const {history} = this.props
        history.push({
            pathname: `/author`,
            redirectUrl: history.location.pathname,
        })
    }

    removeFromBook = async (idx) => {
        
        let {authors} = this.state;
        const author = authors[idx]
        const deleteConfirmation = await confirmDelete.show({
            text: `Are you sure you want to remove "${author.firstname} ${author.lastname}" as author from this book?`
        });

        if(deleteConfirmation) { 
           
            // remove frontend
            authors.splice(idx, 1);
            this.setState({authors})

            // remove backend
            const response = await api.auth.removeBookAuthor(this.props.bookid, author.authorid);

            if(this._isMounted){
                if(response && response.success){
                    this.setState({successMessage: response.message, errorMessage: ""})
                }else{
                    // handle error
                    this.setState({successMessage: "", errorMessage: response.message})
                }
            }
        }
    }

    onAddAuthor = async (e, authorid) => {
        e.preventDefault();
      
        this.setState({isLoading: true, suggestions: [], searchText: ""})
        const response = await api.auth.addBookAuthor(this.props.bookid, authorid)

        if(this._isMounted){     
            if(response && response.success){
                // get author info 
                const authorData = await api.open.requestAuthorInfos(authorid);
                if(this._isMounted){
                    if(authorData){
                        let {authors} = this.state
                        authors.push(authorData);
                        this.setState({successMessage: response.message, errorMessage: "", authors, suggestions: [], searchText: "", isLoading: false})
                    }      
                }
            }else{
                this.setState({successMessage: "", errorMessage: response.message, isLoading: false})
            }
        }
    }

    onChange = (e) => {
        const searchText = e.target.value

        if(this.state.typingTimeout){
            clearTimeout(this.state.typingTimeout)
        }

        this.setState({
            searchText,
            typingTimeout: setTimeout(() => {
                this.performSearch(searchText)
            }, 1000)
        })   
    }

    performSearch = async (searchText) => {
        this.setState({isSearching: true})
        
        let response = []
        if(searchText){
            response = await api.auth.searchAuthors(searchText);
        }
        this.setState({isSearching: false, suggestions: response})
    }

    removeErrorMessage = () => {
        this.setState({errorMessage: ""})
    }

    removeSuccessMessage = () => {
        this.setState({successMessage: ""})
    }

    render(){
        return (
            <div>
                <div style={{marginBottom: "1rem"}}>
                        <form className="" onSubmit={this.handleSubmit}>
                            <fieldset stlye={{display: "flex", width: "100%"}}>
                                <div className="autocomplete" style={{width: "100%"}}>
                                    <input 
                                        name="search" 
                                        type="text" 
                                        className="autocompleteInput typeahead form-control"
                                        value={this.state.searchText}
                                        autoComplete="off" onChange={this.onChange}
                                        placeholder="Search for author" 
                                        disabled={this.state.isLoading}
                                        />
                                    <div className="autocomplete-items" style={{maxHeight: "200px", overflowY: "scroll"}}>
                                        {this.state.suggestions.map(({text, authorid}, idx) =>
                                            <div key={idx} onClick={(e) => this.onAddAuthor(e,authorid)}>{text}</div>)}
                                    </div>
                                </div>
                               
                            </fieldset>
                           
                        </form>
                
                    
                    <button className="btn btn-primary btn-block" onClick={this.onCreateClick} style={{margin: "10px 0"}}>Create new author</button>
                </div>
                <div>
                    <MessageAlert type="error" message={this.state.errorMessage} onClose={this.removeErrorMessage}/>
                    <MessageAlert type="success" message={this.state.successMessage} onClose={this.removeSuccessMessage}/>
                </div>
                <LoadIndicator isLoading={this.state.isLoading} loadingText="Loading authors...">
                <div className="table-responsive">
                    <table className="table table-bordered table-striped table-sm">
                    <thead>
                        <tr>
                            <th>Author Id</th>
                            <th>First name</th>
                            <th>Last name</th>                    
                            <th>Country</th>
                            <th style={{width: "1px", whiteSpace: "nowrap"}}>Edit</th>
                            <th style={{width: "1px", whiteSpace: "nowrap"}}>Remove</th>                  
                        </tr>
                    </thead>
                    
                        <tbody>
                        {this.state.authors && this.state.authors.length > 0 ? 
                            this.state.authors.map((authorData, idx) => {
                               
                                return <AuthorTableRowRouted                         
                                            authorData={authorData}
                                            key={`author-${idx}`} 
                                            idx={idx}
                                            onRemove={this.removeFromBook}
                                            bookid={this.props.bookid}
                                        />
                                
                            })
                            :
                            (
                                <tr><td colSpan="7" className="text-center">No authors connected to this book yet</td></tr>
                            )
                        }
                        </tbody>
                    </table>
                </div>
                </LoadIndicator>
            </div>
        )
    }
}

class AuthorTableRow extends React.Component{

    constructor(props){
        super(props)

        this.state = {
           
        }
    }


    onEditClick = () => {
    
        const {history} = this.props
        history.push({
            pathname: `/author/${this.props.authorData.authorid}/edit`,
            redirectUrl: history.location.pathname
        })
    }

    render(){
        const {authorData} = this.props
        let rowClass = "dynamic-table-row";
        if(!authorData.active){
            rowClass += " dynamic-table-row--inactive"
        }
        return (
            <tr className={rowClass}>
                <td>
                    {authorData ? authorData.authorid : "...ID"}
                </td>
                <td>
                    {authorData ? authorData.firstname : "...firstname"}
                </td>

                <td>
                    {authorData ? authorData.lastname : "...lastname"}
                </td>

                <td>
                    {authorData ? authorData.country : "...country"}
                </td>
                <td>
                    <button className="btn btn-primary" onClick={this.onEditClick}>Edit</button>
                </td>
                <td>
                    <button className="btn btn-danger" onClick={() => this.props.onRemove(this.props.idx)}>Remove</button>
                </td>
            </tr>
        )
    }
}

const AuthorTableRowRouted = withRouter(AuthorTableRow)


export default withRouter(BookEditAuthors)