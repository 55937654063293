import React from 'react'
import UserCreate from "./UserCreate"
import UserRecordList from "./UserRecordList"
import api from "../api"
import LoadingIndicator from "./LoadIndicator"


class UserManageSection extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            showCreateForm: false,
            users: [],
            isLoadingUsers: false,
        }
    }

    componentDidMount(){
        this.getUsers();
    }

    getUsers = async () => {
        this.setState({isLoadingUsers: true})
        const response = await api.auth.getUsers();
        if(response && response.success){

            this.setState({users: response.data})
        }  
        this.setState({isLoadingUsers: false})
    }

    onCreateSuccess = (newUser) => {
        this.setState({users: [{...newUser, justAdded: true}, ...this.state.users]})
    }

    render(){

        return (
            <section >
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 page-header" style={{marginTop: "20px"}}>
                            
                            <h1 style={{fontSize: "1.8em"}}>
                                <div>
                                <small>Create / Edit / Delete users</small>
                                </div>
                                Manage users                               
                            </h1>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <UserCreate onCreateSuccess={this.onCreateSuccess}/>             
                        </div>


                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <LoadingIndicator isLoading={this.state.isLoadingUsers} loadingText="loading users...">
                                <UserRecordList users={this.state.users}/>
                            </LoadingIndicator>
                        </div>    
                    </div>
                </div>
            </section>     
        )
    }
}

export default UserManageSection
