import authApi from "./authApi"
import openApi from "./openApi"
export {AUTH_API_URL, OPEN_API_URL} from "../CryptobooksConfig"

export default {
    auth: authApi,
    open: openApi,
}




export const getUrlEncodedParams = (params) => {
    return Object.keys(params)
        .map(p => encodeURIComponent(p) + '=' + encodeURIComponent(params[p]))
        .join('&');
}

export const buildApiUrl = (apiUrl, action, getParams) => {
    return `${apiUrl}?${getUrlEncodedParams({
        action,
        ...getParams
    })}`
}


export const openFetch = async(url, method="GET", data=null) => {
    let fetchSettings = {
        method: method
    }

    // add request body
    if(method.toUpperCase() !== "GET" && data){
        fetchSettings = {
            ...fetchSettings, 
            body: JSON.stringify(data)
        }
    }

    try{
        // make fetch call
        const response = await fetch(url, fetchSettings)

        // get raw response text (for debugging php)
        const responseText = await response.text(); 
        // show raw reponse // dev only for loggin php errors
        console.log(responseText)

        const responseJson = JSON.parse(responseText);
        console.log(responseJson)
        // return json
        return responseJson

    }catch(ex){
        console.log(ex)
        return null;
    }

}

export const authFetchUploadFile = async (url, method="POST", data=null, settings={}) => {
   const formData = new FormData();
   Object.keys(data).forEach(key => formData.append(key, data[key]));

   return await authFetch(url, method, data, {
        headers: {},
        body: formData,
        ...settings
   })
}
export const authFetch = async (url, method="GET", data=null, settings={}) => {

    // default settings
    let fetchSettings = {
        method: method,
        credentials:"include",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        },
        ...settings,
    }

    // add request body
    if(method.toUpperCase() !== "GET" && data && !settings.body){
        fetchSettings = {
            ...fetchSettings, 
            body: JSON.stringify(data)
        }
    }



    try{
        // make fetch call
        const response = await fetch(url, fetchSettings)

        // get raw response text (for debugging php)
        const responseText = await response.text(); 
        // show raw reponse // dev only for loggin php errors
        console.log(responseText)

        const responseJson = JSON.parse(responseText);
        console.log(responseJson)
        // return json
        return responseJson

    }catch(ex){
        console.log(ex)
        return null;
    }
}   


/*export const authFetch = async (url, method="GET", data=null, settings={}) => {

    // default settings
    let fetchSettings = {
        method: method,
        credentials:"include",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        },
        ...settings,
    }

    // add request body
    if(method.toUpperCase() !== "GET" && data){
        fetchSettings = {
            ...fetchSettings, 
            body: JSON.stringify(data)
        }
    }

    try{
        // make fetch call
        const response = await fetch(url, fetchSettings)

        // get raw response text (for debugging php)
        const responseText = await response.text(); 
        // show raw reponse // dev only for loggin php errors
        console.log(responseText)

        const responseJson = JSON.parse(responseText);
        console.log(responseJson)
        // return json
        return responseJson

    }catch(ex){
        console.log(ex)
        return null;
    }
}   */


