/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './App.css';

import RoutePrivate, { RouteAdmin } from "./components/RoutePrivate"

import AppHeader from './components/AppHeader.js';
import SearchResults from './components/SearchResults.js';
import BookDetails from './components/BookDetails.js';
import AuthorDetails from './components/AuthorDetails.js';
import AuthorsBooks from './components/AuthorsBooks.js';
import HomeSection from './components/HomeSection.js';
import AllBooksSection from './components/AllBooksSection.js';
import ContactSection from './components/ContactSection.js';
import PrivacySection from './components/PrivacySection.js';
import ImprintSection from './components/ImprintSection.js';
import LinksSection from './components/LinksSection.js';

import LoginSection from './components/LoginSection';
import UserManageSection from "./components/UserManageSection";
import UserEditSection from "./components/UserEditSection";
import AuthorCreateSection from "./components/AuthorCreateSection";


import { AuthProvider } from "./components/AuthContext";
import AuthorEditSection from './components/AuthorEditSection';

import BookCreateSection from "./components/BookCreateSection";
import BookEditSection from "./components/BookEditSection";

import UserContributionSection from "./components/UserContributionsSection";
import DeleteLandingPage from "./components/DeleteLandingPage";
import ManageInactiveSection from "./components/ManageInactiveSection";
import CategoriesManageSection from "./components/CategoriesManageSection"


class App extends React.Component {


  render() {

    return (
      <div className="main-layout">
        <AuthProvider>
          <Router>
            <AppHeader />

            <main className="main-layout__main">
              <Route path="/" exact component={HomeSection} />
              <Route path="/imprint" component={ImprintSection} />
              <Route path="/links" component={LinksSection} />
              <Route path="/contact" component={ContactSection} />
              <Route path="/privacy" component={PrivacySection} />
              <Route path="/books" component={AllBooksSection} />
              <Route path="/login" component={LoginSection} />
              <Route path="/search" component={SearchResultsSection} />
              <Route path="/book" component={BookDetailsSection} />
              <RoutePrivate path="/deleted" component={DeleteLandingPage} />

              <Route path="/author" component={AuthorDetailsSection} />

              <RoutePrivate path="/manage-inactive" component={ManageInactiveSection} />

              <RouteAdmin path="/categories" component={CategoriesManageSection} exact />
              <RouteAdmin path="/users" component={UserManageSection} exact />
              <RoutePrivate path="/users/:userid/contributions" component={UserContributionSection} />
              <RoutePrivate path="/users/:userid/edit" component={UserEditSection} />


            </main>

            <Footer />
          </Router>
        </AuthProvider>
      </div>
    );
  }
}

function Footer() {
  return (
    <footer id="tg-footer" className="tg-footer">
      <div className="tg-footerbar">
        <a id="tg-btnbacktotop" className="tg-btnbacktotop" href="javascript:void(0);">
          <i className="icon-chevron-up"></i>
        </a>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <span className="tg-copyright">2020 &copy; CryptoBooks Contributors</span>
              <ul className="tg-footerbar-nav">
                <li>
                  <Link to="/imprint">Imprint</Link>
                </li>
                <li>|</li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                <li>|</li>
                <li>
                  <Link to="/privacy">Privacy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>);
}

function SearchResultsSection({ match }) {
  const parseYearNumber = numberString => numberString ? parseInt(numberString) : null;
  const renderSearchResults = ({ match, location }) => {
    const params = new URLSearchParams(location.search);
    return (
      <SearchResults
        searchRequest={match.params.word}
        yearFrom={parseYearNumber(params.get('yearFrom'))}
        yearTo={parseYearNumber(params.get('yearTo'))} />
    );
  };

  return (
    <section className="tg-sectionspace tg-haslayout">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="tg-sectionhead">
              <h2>
                <span>Search result</span>
                CryptoBooks
              </h2>
            </div>
          </div>

          <Route
            path={`${match.path}/:word`}
            render={renderSearchResults} />

          <Route
            exact
            path={match.path}
            render={renderSearchResults} />
        </div>
      </div>
    </section>
  );
}

function BookDetailsSection({ match }) {
  return (
    <div>
      <RoutePrivate path={`${match.path}/:bookId/edit`} component={BookEditSection} />
      <Route
        path={`${match.path}/:bookId`}
        exact
        render={({ match }) =>
          <BookDetails
            bookId={match.params.bookId} />} />
      <RoutePrivate
        exact
        path={match.path}
        component={BookCreateSection}
      />
    </div>
  );
}

function AuthorDetailsContent({ authorId }) {

  return (
    <div>
      <AuthorDetails authorId={authorId} />
      <AuthorsBooks authorId={authorId} />
    </div>
  );
}

function AuthorDetailsSection({ match }) {

  return (
    <div>
      <RoutePrivate path={`${match.path}/:authorId/edit`} component={AuthorEditSection} />
      <Route
        path={`${match.path}/:authorId`}
        render={({ match }) => <AuthorDetailsContent authorId={match.params.authorId} />}
        exact
      />
      <RoutePrivate path={match.path} component={AuthorCreateSection} exact />
    </div>
  );
}

export default App;
