import React from 'react';
import api from '../api';
import ReactMarkdown from "react-markdown/with-html";


class EditionsViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { editions: null, loading: false };
    }

    componentDidMount() {
        this.fetchEditions(this.props.bookId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.bookId !== prevProps.bookId) {
            this.fetchEditions(this.props.bookId);
        }
    }

    async fetchEditions(bookId) {
        if (bookId) {

            this.setState({ editions: null, loading: true });
            const editions = await api.open.requestEditions(bookId, false);
            this.setState({ editions: editions, loading: false })
        }
    }

    render() {
        let editions = this.state.editions;
        if (!editions) {
            return <span />;
        }
        
        return (
            <section className="tg-sectionspace tg-haslayout">
                <div className="container">
                    <div className="row">
                        <table className="table table-striped editionstable">
                            <thead>
                                <tr>
                                    <th>Edition</th>
                                    <th>Author name</th>
                                    <th>Title</th>
                                    <th>Publisher</th>
                                    <th>Year</th>
                                    <th>Language</th>
                                    <th>Pages</th>
                                </tr>
                            </thead>
                            <tbody>
                                {editions && editions.length > 0 ? 
                                    editions.map(edition =>
                                        <Edition key={edition.editionid}
                                            edition={edition}
                                            bookTitle={this.props.bookTitle} />
                                    )
                                    :
                                    <tr><td colSpan="7" className="text-center" style={{background: "#ddd"}}>No editions yet.</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        );
    }
}

function Edition({ bookTitle, edition }) {
    return (
        <tr>
            <td data-label="Edition">{edition.editionnumber}</td>
            <td data-label="Author name">
                <ReactMarkdown
                    source={edition.author_display_name ? edition.author_display_name: ""}
                    escapeHtml={false} />
           
            </td>
            <td data-label="Title">{edition.revisedtitle || bookTitle}</td>
            <td data-label="Publisher">{edition.publisher}</td>
            <td data-label="Year">{edition.year}</td>
            <td data-label="Language">{edition.language}</td>
            <td data-label="Pages">{edition.pages}</td>
        </tr>
    );
}

export default EditionsViewer;