import React from 'react'

import confirmDelete from "./DialogConfirmDelete"
import MessageAlert from "./MessageAlert";
import api from "../api"
import {userHasOnePermission, PERMISSION_ROLES} from "../CryptobooksConfig"
import {withAuthContext} from "./AuthContext"


const emptyEdition = {
    number: "",
    year: "",
    author_display_name: "",
    pages: "",
    language: "",
    publisher: "",
    addendum: "",
    active: 0,
    printtype: "",
    revisedtitle: "",
    links: "",
    keywords: "",
}

class BookEditEditions extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            editions: [],
            editEditon: emptyEdition,
            editEditionIndex: null, // null or edition index (decides if edit or create)
            errorMessage: "",
            successMessage: "",
            isAdmin: false,
            isLoading: false
        }

        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
        this.fetchEditions();
        this.checkIsAdmin();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    componentDidUpdate(prevProps){
        if(prevProps.bookId !== this.props.bookId){
            this.fetchEditions();
        }

        if(this.props.authContext && prevProps.authContext.user.userid !== this.props.authContext.user.userid){
            this.checkIsAdmin();
        }
    }

    checkIsAdmin = () => {
        if(this.props.authContext){
            if(userHasOnePermission(this.props.authContext.user, PERMISSION_ROLES.admin)){
                this.setState({isAdmin: true})
            }else{
                this.setState({isAdmin: false})
            }
        }   
    }

    fetchEditions = async () => {
        if(this.props.bookId){
            //const editions = await api.open.requestEditions(this.props.bookId)
            const editions = await api.auth.getEditions(this.props.bookId, true)

            let {editEditon, editEditionIndex} = this.state;
            if(!editEditionIndex){
                editEditon = {
                    ...emptyEdition,
                    number: this.getNextEditionNumber(editions)
                }
            }

            this.setState({editions, editEditon, activeEdition: null})
        }     
    }

    getNextEditionNumber = (editions) => {
        if(editions && editions.length >0){
            return editions.length + 1;
        }
        return 1;
    }

    // reset editEdition & editEditionIndex
    cancelEdit = (e) => {
        e.preventDefault();
        this.setState({
            editEditon: {
                ...emptyEdition,
                number: this.getNextEditionNumber(this.state.editions) 
            },
            editEditionIndex: null
        })

    }

    onDeleteEdition = async (e, idx) => {
        e.preventDefault();
        
        const {editions} = this.state
        if(editions){
            const {editionnumber, editionid} = editions[idx]; 
            // ask for delete confirmation
        
            const deleteConfirmation = await confirmDelete.show({
                text: `Are you sure you want to permanently remove edition no. "${editionnumber}" (ID: ${editionid})?`
            });

            if(deleteConfirmation) {


                // remove edition frontend
                // check if editEdition was the deleted edition   
                const isEditEditionDeleted = this.state.editEditon.editionid === editionid
                let {editions} = this.state
                this.state.editions.splice(idx, 1)

                this.setState({
                    editions,
                    editEditon: isEditEditionDeleted ? emptyEdition : this.state.editEditon,
                    editEditionIndex: isEditEditionDeleted ? null : this.state.editEditionIndex,
                })
    
                // remove edition backend
                const response = await api.auth.deleteEdition(editionid)

                if(this._isMounted){
                    if(response.success && response.data){
                        this.setState({
                            successMessage: response.message,     
                            errorMessage: ""           
                        })                  
                    }else{
                        this.setState({
                            errorMessage: "Failed to delete edition.",
                            successMessage: ""
                        })
                    }
                }
            }
        }     
    }

    onChange = (e) => {
        const {name, value} = e.target;
        this.setState({
            editEditon: {
                ...this.state.editEditon,
                [name]: value,
            }
        })
    }

    onCheckboxChange = (e) => {
        this.setState({
            editEditon: {
                ...this.state.editEditon,
                active: e.target.checked ? 1 : 0,
            }
        })
    }

    onEditClick = (e, idx) => {
        this.setState({
            editEditionIndex: idx, 
            editEditon: {
                ...this.state.editions[idx],

                // !!! changed var name to match db. 
                number: this.state.editions[idx].editionnumber
            }
        })
    }

    insertEdition = async (e) => {
        e.preventDefault();
        this.setState({isLoading: true})
        const {editEditon} = this.state

        const response = await api.auth.insertEdition(
            this.props.bookId,
            editEditon.number,
            editEditon.revisedtitle,
            editEditon.year,
            editEditon.pages,
            editEditon.language,
            editEditon.links,
            editEditon.keywords,
            editEditon.printtype,
            editEditon.publisher,
            editEditon.addendum, 
            editEditon.author_display_name,
            editEditon.active,
        )

        if(this._isMounted){
            if(response && response.data){

                let {editions} = this.state

                let successMessage = "Successfully inserted new Edition. It is pending to be activated."

                editions.push({     
                    ...editEditon,
                    editionid: response.data.editionid,
                    bookid: this.props.bookId,
                    editionnumber: editEditon.number,
                    active: response.data.active,
                    new: true,
                })

                if(response.data.active){          
                    successMessage = response.message 
                }

                
                this.setState({
                    editions,
                    successMessage,
                    editEditionIndex: null,
                    editEditon: {
                        ...emptyEdition,
                        number: this.getNextEditionNumber(editions)
                    },
                    errorMessage: "",
                    isLoading: false
                })
            }else{
                this.setState({errorMessage: response.message, successMessage: "", isLoading: false})
            }
        }
   
    }

    updateEdition = async (e) => {
        e.preventDefault();
        this.setState({isLoading: true})
        const {editEditon} = this.state

        const response = await api.auth.updateEdition(
            editEditon.editionid,
            this.props.bookId,
            editEditon.number,
            editEditon.revisedtitle,
            editEditon.year,
            editEditon.pages,
            editEditon.language,
            editEditon.links,
            editEditon.keywords,
            editEditon.printtype,
            editEditon.publisher,
            editEditon.addendum, 
            editEditon.author_display_name,
            editEditon.active,
        )

        if(this._isMounted){
            if(response && response.data){

                let {editions, editEditionIndex} = this.state
        
                editions[editEditionIndex] = {
                    ...editEditon,
                    editionnumber: editEditon.number
                }
        
                
                this.setState({
                    editions,
                    successMessage: response.message,
                    editEditionIndex: null,
                    editEditon: emptyEdition,
                    errorMessage: "",
                    isLoading: false
                })
            }else{
                this.setState({errorMessage: response.message, successMessage: "", isLoading: false})
            }
        }
    }

    removeErrorMessage = () => {
        this.setState({errorMessage: ""})
    }

    removeSuccessMessage = () => {
        this.setState({successMessage: ""})
    }

    render(){

        const {editEditon} = this.state

        return (
            <div>             
                <div className="table-responsive">
                    <table className="table table-bordered table-striped table-sm">
                    <thead>
                        <tr>
                            <th>Edition no.</th>
                            <th>Year</th>                    
                            <th>Pages</th>
                            <th>Author name</th>
                            <th>Publisher</th>
                            <th style={{width: "1px", whiteSpace: "nowrap"}}>Edit</th>
                            <th style={{width: "1px", whiteSpace: "nowrap"}}>Delete</th>                  
                        </tr>
                    </thead>
                    
                        <tbody>
                        {this.state.editions && this.state.editions.length > 0 ? 
                            this.state.editions.map((edition, idx) => {
                                const style = edition.active ? {} : {background: "#ff7f7f"};
                                return (
                                <tr key={`edition-${idx}`} style={style}>
                                    <td>{edition.editionnumber}</td>
                                    <td>{edition.year}</td>
                                    <td>{edition.pages}</td>
                                    <td>{edition.author_display_name}</td>
                                    <td>{edition.publisher}</td>
                                    <td>
                                        <button className="btn btn-primary" onClick={(e) => this.onEditClick(e, idx)}>Edit</button>
                                    </td>
                                    <td>
                                        <button className="btn btn-danger" onClick={(e) => this.onDeleteEdition(e, idx)}>Delete</button>
                                    </td>
                                </tr>
                                )
                            })
                            :
                            (
                                <tr><td colSpan="7" className="text-center">No Editions yet</td></tr>
                            )
                        }
                        </tbody>
                    </table>
                </div>

                {/* start of form part */}

                <hr />
                <h3>
                    {
                    this.state.editEditionIndex !== null ?
                        <span>Edit edition no: {this.state.editEditon.number}</span>
                    : 
                        <span>Create edition</span>
                    }
                </h3>

                <div>
                    <MessageAlert type="error" message={this.state.errorMessage} onClose={this.removeErrorMessage}/>
                    <MessageAlert type="success" message={this.state.successMessage} onClose={this.removeSuccessMessage}/>
                </div>
                
                <form>
                <div className="row">   
                    <div className="col-md-3">
                        <div className="form-group">
                            <label htmlFor="firstname" >Number</label>
                            <input 
                                type="number"
                                min="0"
                                max="999"
                                className="form-control" 
                                name="number" 
                                placeholder="Number" 
                                value={editEditon.number}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label htmlFor="year">Year</label>
                            <input 
                                type="number"
                                min="0"
                                max="9999"
                                className="form-control" 
                                name="year" 
                                placeholder="Year" 
                                value={editEditon.year}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="author_display_name">Author display name</label>
                            <input 
                                className="form-control" 
                                name="author_display_name" 
                                placeholder="Author display name" 
                                value={editEditon.author_display_name}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                            <label htmlFor="pages">Pages</label>
                            <input 
                                type="number"
                                min="0"
                                max="99999"
                                className="form-control" 
                                name="pages" 
                                placeholder="Pages" 
                                value={editEditon.pages}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label htmlFor="language">Language</label>
                            <input 
                                className="form-control" 
                                name="language" 
                                placeholder="language" 
                                value={editEditon.language}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="publisher">Publisher</label>
                            <input 
                                className="form-control" 
                                name="publisher" 
                                placeholder="Publisher" 
                                value={editEditon.publisher}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="addendum">Addendum</label>
                            <textarea 
                                className="form-control" 
                                name="addendum" 
                                placeholder="Addendum" 
                                value={editEditon.addendum}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <hr />
                        <a className="btn btn-link" data-toggle="collapse" data-target="#editions-advanced" style={{padding: "10px 0px"}}>Advanced</a>
                        <div id="editions-advanced" className="collapse">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="revisedtitle">Revised title</label>
                                        <input 
                                            className="form-control" 
                                            name="revisedtitle" 
                                            placeholder="Revised title" 
                                            value={editEditon.revisedtitle ? editEditon.revisedtitle : ""}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="printtype">Print type</label>
                                        <input 
                                            className="form-control" 
                                            name="printtype" 
                                            placeholder="Print type" 
                                            value={editEditon.printtype ? editEditon.printtype : ""}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="links">Links</label>
                                        <input 
                                            className="form-control" 
                                            name="links" 
                                            placeholder="links" 
                                            value={editEditon.links ? editEditon.links : ""}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="keywords">Keywords</label>
                                        <input 
                                            className="form-control" 
                                            name="keywords" 
                                            placeholder="Keywords" 
                                            value={editEditon.keywords}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>

                {this.state.isAdmin && 
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                            <label className="checkbox-inline">
                                <input 
                                    type="checkbox" 
                                    name="active" 
                                    value={true} 
                                    onChange={this.onCheckboxChange} 
                                    checked={editEditon.active}
                                    /> 
                                    active
                            </label>
                            </div>
                        </div>
                    </div>
                }

                <div className="row">
                    <div className="col-md-12">
                        {this.state.editEditionIndex !== null ?
                            <React.Fragment>
                                <button className="btn btn-primary" onClick={this.updateEdition} disabled={this.state.isLoading}>
                                   {this.state.isLoading ? "Saving changes..." : "Save changes"}
                                </button>
                                <button className="btn btn-primary" style={{marginLeft: "10px"}} onClick={this.cancelEdit}>Cancel edit</button>
                            </React.Fragment>
                            :
                            <button className="btn btn-primary" onClick={this.insertEdition} disabled={this.state.isLoading}>
                                {this.state.isLoading ?  "Adding edition..." : "Add edition"}
                            </button>
                        }
                        
                        
                    </div>
                </div>
                
                </form>
            </div>
        )
    }
}

export default withAuthContext(BookEditEditions)