import React from 'react'
import {Link} from "react-router-dom";

import UserEditBaseInfo from "./UserEditBaseInfo";
import UserEditAdmin from "./UserEditAdmin";
import UserChangePassword from "./UserChangePassword";

import {userHasOnePermission, PERMISSION_ROLES} from "../CryptobooksConfig"

import api from "../api"
import LoadIndicator from './LoadIndicator';


/*
    The Profile / Edit User Section of the applications
    ----------------------------------------------------
    displays forms to edit user information
    renders subcomponents:
    - UserEditBaseInfo
    - UserEditAdmin
    - UserChangePassword
*/

class UserEditSection extends React.Component{
    
    constructor(props){
        super(props)
  
        this.state = {
            user: null,
            isAdmin: false,
            isLoading: false,
            userLoadError: "",
        }

        // get the userid param from the url
        const {params} = this.props.match
        this.userIdParam = (params && params.userid) ? parseInt(params.userid) : null;
    }

    componentDidMount(){
        this.fetchUser(this.userIdParam)
    }

    componentDidUpdate(prevProps){

        // fetch new user if userid url param changed
        if(prevProps.match.params.userid !== this.props.match.params.userid){
            this.userIdParam = parseInt(this.props.match.params.userid)
            this.fetchUser(this.props.match.params.userid)
        }
    }


    fetchUser = async (userid) => {
        this.setState({isLoading: true, userLoadError: ""})

        let stateUpdate = { isLoading: false }

        if(userid){
            const currentUser = this.props.user
            if(this.userIdParam  && currentUser){

                const response = await api.auth.getUser(userid)

                if(response && response.success){
                    const isAdmin = userHasOnePermission(this.props.user, PERMISSION_ROLES.admin)
                    stateUpdate = {...stateUpdate, user: response.data, isAdmin}    
                }else{
                    stateUpdate = {...stateUpdate, userLoadError: response.data}
                }
            } 
        }

        this.setState(stateUpdate)
    }

    render(){
        return (
            <section >
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 page-header" style={{marginTop: "20px"}}>                  
                            <h1 style={{fontSize: "1.8em"}}>
                                <div>
                                    <small>
                                        {this.state.user && this.state.isAdmin ? 
                                            <div style={{display: "flex", flexWrap: "wrap"}}>
                                                <Link to="/users" className="btn-link">Manage users</Link> 
                                                <span>
                                                    <i className="icon-chevron-right" style={{fontSize: "10px", fontWeight: "bold", margin: "0 5px"}}></i>
                                                    {this.state.user.username}
                                                </span> 
                                                <span>
                                                    <i className="icon-chevron-right" style={{fontSize: "10px", fontWeight: "bold", margin: "0 5px"}}></i>
                                                    <Link to={`/users/${this.state.user.userid}/contributions`} className="btn-link">contributions</Link>
                                                </span>                                             
                                            </div>
                                            : this.state.user ? 
                                                <div style={{display: "flex", flexWrap: "wrap"}}>
                                                    <span>                                              
                                                        {this.state.user.username}
                                                    </span> 
                                                    <span>
                                                        <i className="icon-chevron-right" style={{fontSize: "10px", fontWeight: "bold", margin: "0 5px"}}></i>
                                                        <Link to={`/users/${this.state.user.userid}/contributions`} className="btn-link">contributions</Link>
                                                    </span>                                             
                                                </div>
                                                : "No user"
                                        }
                                       
                                    </small>
                                </div>
                                User Profile                          
                            </h1>
                        </div>
                        <LoadIndicator isLoading={this.state.isLoading} loadingText="fetching user...">
                            {this.state.user ? 
                                <React.Fragment>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12"> 
                                        <UserEditBaseInfo user={this.state.user} />
                                    </div>  

                                    { this.props.user.userid === this.userIdParam && (
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12"> 
                                            <UserChangePassword user={this.state.user} />
                                        </div>  
                                    )}
                                    
                                    { this.state.isAdmin && (
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12"> 
                                            <UserEditAdmin user={this.state.user} />
                                        </div> 
                                    )}
                                </React.Fragment>         
                            : (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12"> 
                                    No user found or access denied.
                                 </div> 
                            )}                   
                        </LoadIndicator>                  
                    </div>
                </div>
            </section>
        )
    }
}

export default UserEditSection;