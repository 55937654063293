import React from 'react';
import api from '../api';
import LoadIndicator from './LoadIndicator';
import BookInfoViewer from './BookInfoViewer.js';

class AuthorBooks extends React.Component {
    constructor(props) {
        super(props);
        this.state = { authorsBooks: null, loading: false };
    }

    componentDidMount() {
        this.fetchAuthorsBooks(this.props.authorId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.authorId !== prevProps.authorId) {
            this.fetchAuthorsBooks(this.props.authorId);
        }
    }

    async fetchAuthorsBooks(authorId) {
        if (authorId) {
            this.setState({ authorsBooks: null, loading: true });
            const authorsBooks = await api.auth.getAuthorBooks(authorId);
            this.setState({ authorsBooks: authorsBooks, loading: false })
        }
    }

    render() {
        let authorsBooks = this.state.authorsBooks;
        if (!authorsBooks) {
            authorsBooks = [];
        }

        return (
            <section className="tg-sectionspace tg-haslayout">
                <div className="container">
                    <LoadIndicator isLoading={this.state.loading} loadingText="Loading author's books...">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="tg-sectionhead">
                                    <h2>
                                        <span>Author's books</span>
                                        CryptoBooks
                                </h2>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="row row-eq-height-wrap">
                                    {authorsBooks && authorsBooks.length > 0 ?
                                        authorsBooks.map(bookInfos =>
                                            <BookInfoViewer
                                                key={bookInfos.bookid}
                                                bookInfos={bookInfos} />
                                        )
                                        :
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">No books found.</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </LoadIndicator>
                </div>
            </section>
        );
    }
}

export default AuthorBooks;