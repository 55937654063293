import React from 'react'
import {Route, Redirect} from "react-router-dom"
import {withAuthContext} from "./AuthContext"

import {userHasOnePermission, PERMISSION_ROLES} from "../CryptobooksConfig"

/* 
    RoutePrivate Component:
    A wrapper for the Route (react-router-dom) Component that
    only allows authenticated  users to view a page by checking if
    a users is Logged In.

    Additionally an "permissions" prop can be given to further narrow down
    authenticated users to only those with the given permissions 

    Not authenticated users are redirected to / login and a returnUrl gets
    set.

    Usage:
    <RoutePrivate path="/users" component={UserManageSection} exact permissions={["admin"]} />
    <RoutePrivate path="/users/:userid/edit" component={UserEdit} />

*/



function RoutePrivate ({component: Component, authContext, permissions=[""], ...otherProps}){
    
    return (
        <Route 
            {...otherProps}
            render={(props) => {
                const {user, isLoggedIn, isLoading} = authContext
                const isAuthorized = user ? userHasOnePermission(user, ...permissions) : false

                if(isLoading){
                    return <div>isLoading...</div>
                }else if(!isLoading && isLoggedIn && isAuthorized){
                    return <Component {...props} user={user}/>
                }else{
                    return <Redirect 
                    to={{
                        pathname: "/login",
                        state: {from: props.location.pathname}
                    }}
                    />
                }               
            }}
        />
    )
}

/* helper Routes  where you don't manually have to set permissions */

const RoutePrivateEnhanced = withAuthContext(RoutePrivate)

export const RouteAdmin = ({...props}) => (
    <RoutePrivateEnhanced {...props} permissions={[PERMISSION_ROLES.admin]}/>
)



export default RoutePrivateEnhanced