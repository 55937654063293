import React from 'react';
import { Link } from "react-router-dom";
import api from '../api';
import SortBar from './SortBar.js';
import LoadIndicator from './LoadIndicator';

function AllBooksSection() {
    return (
        <section className="tg-sectionspace tg-haslayout">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="tg-sectionhead">
                            <h2>
                                <span>All books</span>
                                CryptoBooks
                                </h2>
                        </div>
                        <div className="tg-description">
                            <AllBooksContent />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

class AllBooksContent extends React.Component {
    constructor(props) {
        super(props);
        this.changeOrderByModeHandler = this.changeOrderByModeHandler.bind(this);

        this.state = {
            orderByMode: api.open.orderByMode.title,
            orderByAscDescMode: api.open.ascDescMode.asc,
            header: null,
            searchResults: null,
            loading: false
        };
    }

    async componentDidMount() {
        await this.fetchInitialSearchResults();
    }

    async fetchInitialSearchResults() {
        this.setState({ searchResults: [] });
        await this.fetchSearchResults();
    }

    async fetchSearchResults() {
        this.setState({ loading: true });
        const orderBy = this.state.orderByMode;
        const ascDesc = this.state.orderByAscDescMode;
        const searchResultsObject = await api.open.performSearch("", null, null, orderBy, ascDesc, 0, 1000);
        this.setState(
            {
                header: searchResultsObject.header,
                searchResults: searchResultsObject.books,
                loading: false
            });
    }

    changeOrderByModeHandler(mode, ascDescMode) {
        this.setState(
            {
                orderByMode: mode,
                orderByAscDescMode: ascDescMode
            },
            this.fetchSearchResults);
    }

    render() {
        let header = this.state.header;
        if (!header) {
            header = { totalcount: "-", query_execution_time: 0 };
        }
        let searchResults = this.state.searchResults;
        if (!searchResults) {
            searchResults = [];
        }

        return (
            <LoadIndicator isLoading={this.state.loading} loadingText="Loading books...">
                <div style={{ marginTop: 0, fontSize: "13px" }}>
                    <div style={{ float: "left" }}>
                        Found <b>{header.totalcount}</b> total results in <b>{(header.query_execution_time * 1000).toFixed(2)}</b> milliseconds
                    </div>
                    <div style={{ float: "right" }}>
                        <div>Showing <b>{searchResults.length}</b> results</div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row">
                        <div style={{ float: "right" }}>
                            <SortBar
                                currentMode={this.state.orderByMode}
                                currentAscDescMode={this.state.orderByAscDescMode}
                                changeModeHandler={this.changeOrderByModeHandler} />
                        </div>
                    </div>
                    <div className="row">
                        <table className="table table-condensed">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Author</th>
                                    <th>Year</th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchResults.map(bookResult =>
                                    <tr key={bookResult.bookid}>
                                        <td>
                                            <Link to={`/book/${bookResult.bookid}`}>
                                                {bookResult.title}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/author/${bookResult.authorid}`}>
                                                {bookResult.firstname} {bookResult.lastname}
                                            </Link>
                                        </td>
                                        <td>{bookResult.year}</td>
                                    </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </LoadIndicator>
        );
    }
}

export default AllBooksSection;