import React from 'react';
import api from '../api';
import ModalPictureDialog from './ModalPictureDialog.js';

function BookCoverPicture({ bookInfos, withDialog = false, width = null }) {
    if (!bookInfos.pictureid) {
        return null;
    }
    const pictureUrl = api.open.getPictureUrl(bookInfos.pictureid, width);
    const description = `Cover of book "${bookInfos.title}"`;
    const img = <img src={pictureUrl} alt={description} />;

    if (withDialog) {
        const fullScalePictureUrl = api.open.getPictureUrl(bookInfos.pictureid);

        return (
            <ModalPictureDialog pictureUrl={fullScalePictureUrl} title={description}>
                <img src={pictureUrl} alt={description} />
            </ModalPictureDialog>
        );
    }
    return img;
}

export default BookCoverPicture;