import React from 'react'
import {Link} from "react-router-dom"


import confirmDelete from "./DialogConfirmDelete"

const UserRecordItem = ({user: {userid, username, email, permissions, justAdded}, onDelete}) => {

    const askDeleteConfirmation = async () => {
        // ask for delete confirmation
        const deleteConfirmation = await confirmDelete.show({
            text: `Are you sure you want to permanently remove user "${username}" (ID: ${userid})?`
        });

        if(deleteConfirmation) onDelete(userid);
    }

    return (
        <tr style={justAdded ? {backgroundColor: "#dff0d8"}: {}}>
            <th scope="row">{userid}</th>
            <td>{username}</td>
            <td>{email}</td>
            <td>{permissions}</td>

            <td>
                <Link to={`/users/${userid}/edit`} className="btn btn-primary">Edit</Link>       
            </td>
            <td>
                <button className="btn btn-danger" onClick={askDeleteConfirmation}>Delete</button>
            </td>
        </tr>
    )
}

export default UserRecordItem