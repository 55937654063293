export const API_BASE = "https://cryptobooks.org/api/";

export const OPEN_API_URL = `${API_BASE}/index.php`;
export const AUTH_API_URL = `${API_BASE}/modify.php`;


export const PERMISSION_ROLES = {
    standard: "",
    admin: "admin",
}


export const PERMISSION_DISPLAY_VALUES = {
    [PERMISSION_ROLES.admin]: "Admin",
}


export const userHasOnePermission = (user, ...permissions) => {
    permissions = permissions && permissions.length > 0 ? permissions : [""]

    let hasPermission = false
    permissions.forEach(permission => {
        if(user.permissions.includes(permission)) hasPermission = true
    })

    return hasPermission;
}

