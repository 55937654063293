import React from 'react';
import api from '../api';
import ReCAPTCHA from "react-google-recaptcha";

class ContactSection extends React.Component {
    constructor(props) {
        super(props);
        this.sentHandler = this.sentHandler.bind(this);

        this.state = { sendResult: null };
    }

    sentHandler(result) {
        this.setState({ sendResult: result });
    }

    render() {
        const sentHandler = this.sentHandler;
        const sendResult = this.state.sendResult;
        function SectionContent() {
            if (!sendResult) {
                return <ContactForm sentHandler={sentHandler} />;
            }
            if (sendResult.error && (sendResult.error !== 200)) {
                return (
                    <div className="panel panel-danger">
                        <div className="panel-heading">Error trying to send message.</div>
                        <div className="panel-body">{sendResult.message}</div>
                    </div>
                );
            }
            return (
                <div className="panel panel-success">
                    <div className="panel-heading">Message was successfully sent.</div>
                    <div className="panel-body">You will receive an answer soon.</div>
                </div>
            );
        }

        return (
            <section className="tg-sectionspace tg-haslayout">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="tg-sectionhead">
                                <h2>
                                    <span>Contact</span>
                                    CryptoBooks
                                </h2>
                            </div>
                            <div className="tg-description">
                                <SectionContent />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.recaptchaOnChange = this.recaptchaOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = { name: null, email: null, subject: null, message: null, recaptchaValue: null };
    }

    recaptchaSiteKey = "6Lf-h6YUAAAAAAb5XmK8NeDDk694-4bCqBlrp_Cl";

    recaptchaOnChange(value) {
        this.setState({ recaptchaValue: value });
    }

    async sendContactMail() {
        return await api.open.sendContactMail(
            this.state.name,
            this.state.email,
            this.state.subject,
            this.state.message,
            this.state.recaptchaValue);
    }

    async handleSubmit(event) {
        event.preventDefault();
        const result = await this.sendContactMail();
        this.props.sentHandler(result);
    }

    handleChange(event) {
        const target = event.target;
        this.setState({ [target.name]: target.value });
    }

    isSendButtonEnabled() {
        return this.state.recaptchaValue
            && this.state.name && (this.state.email && this.state.email.search("@") > 0)
            && this.state.subject
            && this.state.message;
    }

    render() {
        return (
            <form className="form-horizontal" onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <label htmlFor="inputName" className="col-sm-2 control-label">Name</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputName" name="name" placeholder="Name" onChange={this.handleChange} />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="inputEmail" className="col-sm-2 control-label">Email</label>
                    <div className="col-sm-10">
                        <input type="email" className="form-control" id="inputEmail" name="email" placeholder="Email" onChange={this.handleChange} />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="inputSubject" className="col-sm-2 control-label">Subject</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputSubject" name="subject" placeholder="Subject" onChange={this.handleChange} />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="inputMessage" className="col-sm-2 control-label">Message</label>
                    <div className="col-sm-10">
                        <textarea className="form-control" id="inputMessage" rows="3" name="message" placeholder="Message" onChange={this.handleChange} />
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-sm-offset-2 col-sm-10">
                        <ReCAPTCHA
                            sitekey={this.recaptchaSiteKey}
                            onChange={this.recaptchaOnChange} />
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-sm-offset-2 col-sm-10">
                        <button type="submit" className="btn btn-default" disabled={!this.isSendButtonEnabled()}>Send</button>
                    </div>
                </div>
            </form>
        );
    }
}

export default ContactSection;