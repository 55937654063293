/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import ReactMarkdown from "react-markdown/with-html";
import { Link, withRouter} from "react-router-dom";
import {userHasOnePermission, PERMISSION_ROLES} from "../CryptobooksConfig"
import {withAuthContext} from "./AuthContext"
import api from '../api';
import LoadIndicator from './LoadIndicator';
import ModalPictureDialog from "./ModalPictureDialog"


class AuthorDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = { authorInfos: null, pictureInfo: null, loading: false };
    }

    componentDidMount() {
        this.fetchAuthorDetails(this.props.authorId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.authorId !== prevProps.authorId) {
            this.fetchAuthorDetails(this.props.authorId);
        }
    }

    async fetchAuthorDetails(authorId) {
        if (authorId) {
            this.setState({ authorInfos: null, loading: true });
            const authorInfos = await api.auth.getAuthor(authorId)

      
            this.setState({ authorInfos: authorInfos, loading: false })

            if(authorInfos){
                const pictureInfo = await api.open.getPictureInfo(authorInfos.pictureid);
           
                this.setState({ pictureInfo})
            }
            
            
        }
    }

    render() {
        let authorInfos = this.state.authorInfos;
        /*if (!authorInfos) {
            authorInfos = { pictureId: null, firstname: "", lastname: "", description: "..." };
        }*/
        return (
            <section className="tg-sectionspace tg-haslayout">
                <div className="container">
                    <div className="row">
                        <LoadIndicator isLoading={this.state.loading} loadingText="Loading author details...">
                            <AuthorDetailsElement authorInfos={authorInfos} pictureInfo={this.state.pictureInfo} isLoading={this.state.loading}/>
                        </LoadIndicator>
                    </div>
                </div>
            </section>
        );
    }
}

function AuthorPicture({ pictureId, pictureInfo }) {
    if (!pictureId) {
        return null;
    }


    const pictureDescription = pictureInfo && pictureInfo.description ? pictureInfo.description : "";
 
    const pictureTitle = pictureInfo && pictureInfo.name? pictureInfo.name : "A picture of the author.";
   

    const pictureUrl = api.open.getPictureUrl(pictureId, 300);
    const fullScalePictureUrl = api.open.getPictureUrl(pictureId);

    return (
        <ModalPictureDialog pictureUrl={fullScalePictureUrl} title={pictureTitle} description={pictureDescription}>
            <img style={{ width: 300 }} src={pictureUrl} alt={pictureTitle} />
        </ModalPictureDialog>
    );
}



function AuthorEditButton({author, authContext: {user}, history}){
    if(!user || !author || !author.authorid) return null;

    if(user.userid !== author.userid  && !userHasOnePermission(user, PERMISSION_ROLES.admin)){
        return null;
    }

    const onClick = () => {
        history.push({
            pathname: `/author/${author.authorid}/edit`,
            redirectUrl: history.location.pathname
        })
    }

    return (
        <button className="btn btn-primary" onClick={onClick} style={{position: "absolute", right: "10px", top: "20px", zIndex: "1"}}>
            Edit author
        </button>
    )
}

// pass user auth context information to AuthorEditButton
const AuthorEditButtonWithUser = withRouter(withAuthContext(AuthorEditButton))


function AuthorDetailsElement({ authorInfos, pictureInfo, isLoading}) {
  
    if(!isLoading && authorInfos && !authorInfos.authorid){
        return null;
    }

    if(!authorInfos){
        return (
            <div className="tg-sectionhead">            
                <h2>
                    Author not found
                </h2>
                <div style={{ clear: "both", paddingTop: 20 }}>
                    No author found or acccess denied.
                </div>
            </div>
        )
    }

    return (
        <div className="tg-newrelease" style={{position: "relative"}}>
            {(authorInfos && authorInfos.authorid && !authorInfos.active) && (             
                <div className="alert alert-danger" role="alert" style={{margin: "10px 0 50px 0", paddingRight: "100px"}}>
                    Author cannot be viewed by normal users yet. An administrator is needed to activate this
                    author entry so it becomes visible for everyone.
                </div>
            )}
            <AuthorEditButtonWithUser author={authorInfos}  />
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                <div className="row">
                    <div className="tg-newreleasebooks">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <figure>
                                <AuthorPicture pictureId={authorInfos.pictureid} pictureInfo={pictureInfo}/>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                
                <div className="tg-sectionhead">            
                    <h2><span>About the Author</span>
                        <Link to={`/author/${authorInfos.authorid}`}>
                            {authorInfos.firstname} {authorInfos.lastname}</Link>
                    </h2>
                    <div style={{ clear: "both", paddingTop: 20 }}>
                        <div>
                            <span>* {authorInfos.born || "N/A"}</span>
                            <span style={{ paddingLeft: 20, paddingRight: 20 }}> - </span>
                            <span>† {authorInfos.died || "N/A"}</span>
                            <span style={{ paddingLeft: 20, paddingRight: 20 }}> - </span>
                            <span>{authorInfos.country}</span>
                        </div>
                        <div style={{ paddingTop: 20 }}>
                            Pseudonyms: {authorInfos.pseudonyms ? authorInfos.pseudonyms.join() : "-"}
                        </div>
                    </div>
                </div>
                <div className="tg-description" >
                    <ReactMarkdown
                        source={authorInfos.description}
                        escapeHtml={false} />
                </div>
            </div>
        </div>
    );
}

export default AuthorDetails;