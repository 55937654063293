import React, {createContext} from 'react'
import api from "../api";


/* 
    An Auth Context wrapper
    as per https://reactjs.org/docs/context.html

    Provided global state for all things auth 
    Contains...
    {
        user (obj)
        isLoggedIn (boolean)
        login (func)
        logout (func)
    }

    exports...
    AuthContext
    AuthConsumer
    AuthProvider
    withAuthContext

*/

export const AuthContext = createContext({})

export const AuthConsumer = AuthContext.Consumer;


// Higher Order Component for easier access of auth context
// usage: withAuthContext(Component)

export const withAuthContext = (Component) => (props) =>  (
    <AuthConsumer>
        {authContext => <Component {...props} authContext={authContext} />}
    </AuthConsumer>
)

const USER_SESSION_NAME = "user"


export class AuthProvider extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            user: null,
            isLoggedIn: false,
            isLoading: true
        }
    }

    componentDidMount(){    
        // premtively set user to user from sessionStorage
        const userSession = JSON.parse(sessionStorage.getItem(USER_SESSION_NAME))
        this.setUserState(userSession)

        // double check user login on page reload
        this.checkUserStatus();
    }

    checkUserStatus = async () => {
        // call api to check user login status
        const response = await api.auth.checkUserLoginStatus();

        // set user state based on response
        const user = response && response.success ? response.data : null;
        this.setUserState(user)

    }

    setUserState = (user) => {
        this.setState({user, isLoggedIn: !!user, isLoading: false})

        if(user){
            sessionStorage.setItem(USER_SESSION_NAME, JSON.stringify(user))
        }else{
            sessionStorage.removeItem(USER_SESSION_NAME)
        }
    }

    updateUserState = (userUpdates) => {
        this.setUserState({
            ...this.state.user,
            ...userUpdates
        })
    }

    logout = async () => {
        // call api to logout backend
        const response = await api.auth.logout();

        // logout fronted 
        this.setUserState(null);

        // reload page on logout to update the current page
        window.location.reload(false);

        return response;
    }

    login = async (username, password) => {
        // call api to login in backend
        const response = await api.auth.login(username, password);

        // use response data to set user in frontend
        if(response){
            this.setUserState(response.data)
        }
        
        return response;
    }

    render(){
        const value = {
            user: this.state.user,
            isLoggedIn: this.state.isLoggedIn,
            isLoading: this.state.isLoading,
            updateUserState: this.updateUserState,
            login: this.login,
            logout: this.logout,
        }

        return (
            <AuthContext.Provider value={value}>
                {this.props.children}
            </AuthContext.Provider>
        )
    }
}





