import React from 'react'
import MessageAlert from "./MessageAlert"
import {PERMISSION_DISPLAY_VALUES} from "../CryptobooksConfig"
import SelectMultiple from "./SelectMultiple"
import CollapsiblePanel from "./CollapsiblePanel"
import {withAuthContext} from "./AuthContext"

import api from "../api"

/*
    UserEditAdmin
    ----------------------------------------------------
    Form for admins to edit user permissions and password
*/

class UserEditAdmin extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            userData: {
                permissions: this.props.user ? this.props.user.permissions : "",
                new_password: "",
                confirm_new_password: "",
            },
            userDataChanged: false,
            errorMessage: "",
            successMessage: "",
            isLoading: false
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.user !== this.props.user){
            const {userData} = this.state
            this.setState({
                userData: {...userData, permissions: this.props.user.permissions}
            })
        }
    }

    // call api to update user admin information
    onSubmit = async (e) => {
        e.preventDefault();

        this.setState({isLoading: true})
        const {permissions, new_password, confirm_new_password} = this.state.userData
        if(new_password === confirm_new_password){
            const response = await api.auth.updateUserAdmin(this.props.user.userid, permissions, new_password)

            if(response && response.success){
                this.setState({successMessage: response.message, errorMessage: "", isLoading: false})

                // update authContext to match user updates in frontend
                const {authContext, user} = this.props
                if(user.userid ===  authContext.user.userid){
                    authContext.updateUserState({permissions: this.state.userData.permissions})
                }
            }else{
                this.setState({errorMessage: response.message, successMessage: "", isLoading: false})
            }
        }else{
            this.setState({errorMessage: "Passwords do not match.", isLoading: false, successMessage: ""})
        }
        
    }

    // update form field state
    onChange = (e) => {
        const {name, value} = e.target
        const userData = this.state.userData
        this.setState({userData: {...userData, [name]: value}, userDataChanged: true});
    }

    removeErrorMessage = () => this.setState({errorMessage: ""})
    removeSuccessMessage = () => this.setState({successMessage: ""})

    render(){
        const {userData} = this.state
        return(

            <CollapsiblePanel type="danger" id="editAdminInfo" title="Edit admin info">           
                <form className="form">
                    <div className="row">
                        <div className="col-md-12">
                            <MessageAlert type="error" message={this.state.errorMessage} onClose={this.removeErrorMessage}/>
                            <MessageAlert type="success" message={this.state.successMessage}  onClose={this.removeSuccessMessage}/>
                            
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="permissions" className="sr-only"></label>
                                    <SelectMultiple 
                                        name="permissions" 
                                        placeholder="Permissions" 
                                        value={userData.permissions}
                                        onChange={this.onChange} 
                                        options={PERMISSION_DISPLAY_VALUES}
                                    />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="new_password" className="sr-only"></label>
                                <input 
                                    className="form-control" 
                                    type="password"
                                    name="new_password" 
                                    placeholder="New password" 
                                    value={userData.new_password}
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                    </div>
                   
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="password" className="sr-only"></label>
                                <input 
                                    className="form-control" 
                                    type="password"
                                    name="confirm_new_password" 
                                    placeholder="Confirm new password" 
                                    value={userData.confirm_new_password}
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                    </div>
                   
                    <button className="btn btn-danger" onClick={this.onSubmit} disabled={!this.state.userDataChanged} disabled={this.state.isLoading}>
                        {this.state.isLoading ? "Saving..." : "Save changes"}
                    </button>
                             
                </form>
            </CollapsiblePanel>
        )
    }
}

export default withAuthContext(UserEditAdmin)