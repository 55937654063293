import React from 'react';
import ReactMarkdown from "react-markdown/with-html";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModalPictureDialog extends React.Component {
    constructor(props) {
        super(props);
        this.setVisible = this.setVisible.bind(this);
        this.state = { visible: false };
    }

    setVisible(visible) {
        this.setState({ visible: visible });
    }

    render() {
        return (
            <div>
                <Dialog
                    pictureUrl={this.props.pictureUrl}
                    title={this.props.title}
                    description={this.props.description}
                    visible={this.state.visible}
                    closeHandler={() => this.setVisible(false)} />
                <div onClick={() => this.setVisible(true)}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

function Dialog({ visible, pictureUrl, title, description, closeHandler }) {
    return (
        <Modal isOpen={visible} toggle={closeHandler} size="lg" modalClassName="in" backdropClassName="in">
            <ModalHeader toggle={closeHandler}>{title}</ModalHeader>
            <ModalBody>
                <img style={{
                    height: "auto",
                    verticalAlign: "center",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto"
                }}
                    src={pictureUrl}
                    alt={title} />
                {description ? (
                    <div style={{margin: "10px 0"}}>
                    <ReactMarkdown
                    source={description}
                    escapeHtml={false} />
                    </div>
                ) : null}
            </ModalBody>
            <ModalFooter>
                <a href={pictureUrl} target="_blank">
                    <Button color="primary">Open</Button>
                </a>
            </ModalFooter>
        </Modal>
    );
}

function Dialog2({ visible, pictureUrl, title, closeHandler }) {
    if (!visible) {
        return null;
    }
    return (
        <div>
            <div className="modal-backdrop fade in" />
            <div className="modal fade in" tabIndex="-1" role="dialog"
                style={{ display: "block" }} aria-labelledby="titleLabel">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" aria-label="Close" onClick={closeHandler}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title" id="titleLabel">{title}</h4>
                        </div>
                        <div className="modal-body">
                            <img style={{
                                height: "auto",
                                verticalAlign: "center",
                                display: "block",
                                clear: "both",
                                float: "none",
                                marginLeft: "auto",
                                marginRight: "auto"
                            }}
                                src={pictureUrl}
                                alt={title} />
                        </div>
                        <div className="modal-footer">
                            <a href={pictureUrl} target="_blank">
                                <Button color="primary">Open</Button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalPictureDialog;