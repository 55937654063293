import React from 'react'
import {Link} from "react-router-dom"

const DeleteLandingpage = ({location: {state}}) => {

    const title = state && state.title ? state.title : "Deletion Confirmation Page.";
    const buttonText = state && state.buttonText ? state.buttonText : "Back to homepage";
    const buttonPath = state && state.buttonPath ? state.buttonPath : "/";

    return (
        <div className="container" style={{height: "100%"}}> 
            <div style={{height: "100%"}}>
                    <h1 className="text-center" style={{marginBottom: "5rem", marginTop: "5rem"}}>{title}</h1>
                    <div className="text-center">
                            <Link to={buttonPath || "/"} className="btn btn-primary btn-lg">{buttonText}</Link>
                    </div>                   
            </div>
        </div>
    )
}

export default DeleteLandingpage





