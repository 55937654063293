import React from 'react';
import api from '../api';

function SortBar({ currentMode, currentAscDescMode, changeModeHandler }) {

    function SortButton({ children, buttonMode }) {
        const isActive = (currentMode & buttonMode);
        function clickHandler() {
            //Toggle button:
            if (isActive) {
                if (currentAscDescMode === api.open.ascDescMode.asc) {
                    changeModeHandler(buttonMode, api.open.ascDescMode.desc);
                } else {
                    changeModeHandler(buttonMode, api.open.ascDescMode.asc);
                }
            } else {
                changeModeHandler(buttonMode, api.open.ascDescMode.asc);
            }
        }

        if (isActive) {
            return <button
                type="button"
                className="btn btn-primary"
                onClick={clickHandler}>
                {children} <AscDescBadge ascDescMode={currentAscDescMode} />
            </button>;
        }
        return <button
            type="button"
            className="btn btn-default"
            onClick={clickHandler}>
            {children} <AscDescBadge ascDescMode={api.open.ascDescMode.asc}/>
        </button>;
    }

    return (
        <div className="btn-group" role="group" aria-label="...">
            <SortButton buttonMode={api.open.orderByMode.title}>Title</SortButton>
            <SortButton buttonMode={api.open.orderByMode.year}>Year</SortButton>
            <SortButton buttonMode={api.open.orderByMode.firstname}>First Name</SortButton>
            <SortButton buttonMode={api.open.orderByMode.lastname}>Last Name</SortButton>
        </div>
    );
}

function AscDescBadge({ ascDescMode }) {
    if (ascDescMode === api.open.ascDescMode.asc) {
        return <span className="badge"><span className="glyphicon glyphicon-triangle-bottom" /></span>;
    } else {
        return <span className="badge"><span className="glyphicon glyphicon-triangle-top" /></span>;
    }
}

export default SortBar;