import React from 'react';
import './LoadIndicator.css';

function LoadIndicator({ children, isLoading, loadingText }) {
    if (isLoading) {
        return (
            <div id="loadIndicator">
                <div id="overlay">
                    <div id="loadCaption">
                        <span id="loadSpinner">
                            <i className="icon-spinner" />
                        </span>
                        <span>{loadingText}</span>
                    </div>
                </div>
                {children}

                {/* The following div needs to be present to let the parent div consume
                the same space as its content, because the content of "children" may be floated. */}
                <div id="clearFloat" />
            </div>);
    }
    return children;
}

export default LoadIndicator;