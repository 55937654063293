import React from 'react'

import api from "../api"
import confirmDelete from "./DialogConfirmDelete"
import LoadingIndicator from "./LoadIndicator"
import {Link} from "react-router-dom"
import MessageAlert from "./MessageAlert";


import CollapsiblePanel from "./CollapsiblePanel"
import {userHasOnePermission, PERMISSION_ROLES} from "../CryptobooksConfig"

import BookEditInfo from "./BookEditInfo";
import BookEditPictures from "./BookEditPictures"
import BookEditEditions from "./BookEditEditions"
import BookEditAuthors from "./BookEditAuthors"

import "./BookEditSection.css"


const emptyBookData = {
    title: "",
    description: "",
    year: "",
    keywords: "",
    categories: "",
}



class BookEditSection extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            bookData: null,
            editBookData: emptyBookData,
            isLoading: false,
            isUpdating: false,
            successMessage: "",
            errorMessage: "",
            isAdmin: false,
            isDeleting: false,
        }

        this.bookid = this.props.match.params.bookId
        this.mainPanel = React.createRef();
    }

    componentDidMount(){
        this.fetchBookData();
    }

    componentDidUpdate(prevProps){
        if(this.props.match.params.bookId !== prevProps.match.params.bookId){
            this.bookid = this.props.match.params.bookId;
            this.fetchBookData();
        }           
    }

    fetchBookData = async () => {
        if(this.bookid){
            this.setState({ isLoading: true });
            const response = await api.auth.getBook(this.bookid, true);
            let bookData = response.data;

            if(bookData){
                bookData.categories = bookData.categories.join();

               const {user} = this.props
                // check if owner
                let isAdmin = false;
                if(userHasOnePermission(user, PERMISSION_ROLES.admin)){
                    isAdmin = true
                }
                

                // check if owner or admin
                let canEdit = false;
                if(isAdmin || bookData.owner === user.userid){
                    canEdit = true;
                }

                if(canEdit){
                    this.setState({
                        isLoading: false,
                        bookData,
                        editBookData: {
                            title: bookData.title,
                            description: bookData.description || "",
                            year: bookData.year,
                            keywords: bookData.keywords || "",
                            categories: bookData.categories,
                            active: bookData.active
                        },
                        isAdmin,
                        canEdit,
                    })
                }else{
                    this.setState({isLoading: false, bookData: null})
                }
            }else{
                this.setState({
                    isLoading: false,
                    bookData: null
                })
            }

        }
    }


    handleBookDelete = async (e) => {
        e.preventDefault();
        
        const {bookData} = this.state
        if(bookData){
            const {title, bookid} = bookData; 
            // ask for delete confirmation
            const deleteConfirmation = await confirmDelete.show({
                text: `Are you sure you want to permanently remove book "${title}" (ID: ${bookid})?`
            });

            if(deleteConfirmation) {
                this.setState({isDeleting: true, isLoading: true})
                // call delete in api
                const response = await api.auth.deleteBook(this.bookid);             

               if(response && response.success){
                    // handle successfull delete   
                    this.setState({isDeleting: false, isLoading: false}, () => {
                        this.props.history.push({
                            pathname: "/deleted",
                            state: {
                                title: `Book (ID: ${this.bookid}) has been successfully deleted.`
                            }
                        })
                    })         
                }else{
                    this.setState({errorMessage: "Failed to delete book.", isDeleting: false, isLoading: false})
                }
            }
        }     
    }

    // call api to update book info
    editBookInfo = async (e) => {
        e.preventDefault();
        this.setState({isUpdating: true})

        const {editBookData} = this.state
        
        const response = await api.auth.updateBook(
            this.bookid,
            editBookData.title,
            editBookData.year,
            editBookData.keywords,
            editBookData.description,
            editBookData.categories,
            editBookData.active,
        )

        if(response && response.data){
            this.setState({
                successMessage: response.message, 
                isUpdating: false,
                bookData: {
                    ...this.state.bookData,
                    ...editBookData
                }
            })
        }else{
            this.setState({errorMessage: response.message, isUpdating: false})
        }
    }

    onChange = (e) => {
        const {name, value} = e.target

        this.setState({
            editBookData: {
                ...this.state.editBookData,
                [name]: value
            }
        })
    }

    onCheckboxChange = (e) => {
        this.setState({
            editBookData: {
                ...this.state.editBookData,
                active: e.target.checked ? 1 : 0,
            }
        })
    }
    

    removeErrorMessage = () => {
        this.setState({errorMessage: ""})
    }

    removeSuccessMessage = () => {
        this.setState({successMessage: ""})
    }

    render(){

        const {bookData, editBookData} = this.state

        const authorSearchText = this.props.location.state ? this.props.location.state.authorSearchText : "";



        return (
            <section >
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 page-header book-edit-header" >
                        <h1 className="book-edit-header__heading">
                            {bookData ? (
                               <span> 
                                   Edit Book:&nbsp;
                                   <Link to={`/book/${bookData.bookid}`}>{bookData.title}</Link>
                                </span>
                            ): "Book Not Found"}
                            
                            
                        </h1>
                        {this.state.bookData && this.state.isAdmin && 
                            <div>
                                <button className="btn btn-danger btn-block" onClick={this.handleBookDelete} disabled={this.state.isDeleting}>
                                    {this.state.isDeleting ? "Deleting" : "Delete Book"}
                                </button>
                            </div>
                        }             
                    </div>
                   

                    {/* edit book body */}
                    {this.state.isLoading || bookData ? 
                        <LoadingIndicator isLoading={this.state.isLoading} loadingText="Loading book details...">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    {this.state.bookData && !this.state.bookData.active && (                                
                                            <div className="alert alert-danger" role="alert" style={{margin: "10px 0 50px 0", paddingRight: "100px"}}>
                                                Book cannot be viewed by normal users yet. An administrator is needed to activate this
                                                book entry so it becomes visible for everyone.
                                            </div>

                                    )}
                                    

                                <div className="accordion" id="accordion2">
                                        <CollapsiblePanel
                                            type="primary" 
                                            id="p1" 
                                            title="Edit book info"
                                            dataParent="accordion2"
                                            initialExpand={authorSearchText ? false : true}
                                  
                                        >
                                            <div>
                                                <div>
                                                    <MessageAlert type="error" message={this.state.errorMessage} onClose={this.removeErrorMessage}/>
                                                    <MessageAlert type="success" message={this.state.successMessage} onClose={this.removeSuccessMessage}/>
                                                </div>
                                                <BookEditInfo 
                                                    data={editBookData}
                                                    onChange={this.onChange}
                                                    onCheckboxChange={this.onCheckboxChange}
                                                />

                                                <div>
                                                    <button className="btn btn-primary" onClick={this.editBookInfo} disabled={this.state.isUpdating}>
                                                        {this.state.isUpdating ? "Saving changes..." : "Save changes"}
                                                    </button>
                                                </div>

                                            </div>
                                           
                                        </CollapsiblePanel>
                                        
                                        <CollapsiblePanel
                                            type="primary" 
                                            id="p2" 
                                            title="Edit book editions"
                                            dataParent="accordion2"       
                                                    
                                        >
                                            <BookEditEditions bookId={this.bookid}/>
                                        </CollapsiblePanel>
                                        <CollapsiblePanel
                                            type="primary" 
                                            id="p3" 
                                            title="Edit book authors"
                                            dataParent="accordion2"
                                            initialExpand={authorSearchText ? true : false}
                            
                                        >
                                            <BookEditAuthors searchText={authorSearchText} bookid={this.bookid} authorIds={this.state.bookData ? this.state.bookData.authorids : []}/>
                                        </CollapsiblePanel>

                                        <CollapsiblePanel
                                            type="primary" 
                                            id="p4" 
                                            title="Edit book pictures"
                                            dataParent="accordion2"                                       
                                        >
                                            <BookEditPictures bookId={this.bookid}/>
                                        </CollapsiblePanel>
                                    </div>



                                </div>       
                        </LoadingIndicator>
                        :                       
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            No book with ID: {this.bookid} found.
                        </div>
                    }
                    
                </div>
                
            </div>
            </section>
        )
    }
}

export default BookEditSection;