import React from 'react'
import {withRouter} from "react-router-dom"
import {userHasOnePermission, PERMISSION_ROLES} from "../CryptobooksConfig"

import {withAuthContext} from "./AuthContext"
import PictureEdit from "./PictureEdit"
import MessageAlert from "./MessageAlert"

import api from "../api"


const emptyAuthorData = {
    firstname: "",
    lastname: "",
    pseudonyms: "",
    country: "",
    born: "",
    died: "",
    description: "",
    picture_src: null,
    picture_title: "",
    picture_description: "",
    active: false,
}

/*
    AuthorEdit
    ----------------------------------------------------
    Combined form component to update / create author
*/

class AuthorEdit extends React.Component{

    constructor(props){
        super(props);


        let isAdmin = false;
        const {user} = this.props.authContext
        if(userHasOnePermission(user, PERMISSION_ROLES.admin)){
            isAdmin = true
        }

        this.state = {
            authorData: this.getAuthorDataFromProps(),
            pictureChanged: false,
            isLoading: false,
            errorMessage: "",
            successMessage: "",
            isAdmin,
        }

    }


    getAuthorDataFromProps = () => {
        return  this.props.author ? {
            ...this.props.author,
            born: this.props.author.born ? this.props.author.born : "",
            died: this.props.author.died ? this.props.author.died : "", 
        } : emptyAuthorData
    }

    componentDidUpdate(prevProps, prevState){
        // update form data on user change
        if(prevProps.author !== this.props.author){
            this.setState({           
                authorData: this.getAuthorDataFromProps()
            })
        }
    }

    // call api to create author
    createAuthor = async (e) => {
        e.preventDefault();
        this.setState({isLoading: true})

        const response = await api.auth.insertAuthor(
            this.state.authorData.firstname,
            this.state.authorData.lastname,
            this.state.authorData.pseudonyms,
            this.state.authorData.country,
            this.state.authorData.born,
            this.state.authorData.died,
            this.state.authorData.description,
            this.state.authorData.picture_src,
            this.state.authorData.picture_title,
            this.state.authorData.picture_description,
            this.state.authorData.active,
        )    

        if(response && response.success){
            const authorSearchText = (this.state.authorData.firstname + " " + this.state.authorData.lastname).trim();
            this.setState({
                authorData: emptyAuthorData,
                successMessage: response.message,
                isLoading: false           
            })
            

            if(this.props.location.redirectUrl){
                // currently only used to redirect to bookeditsection       
                this.props.history.push({
                    pathname: this.props.location.redirectUrl,
                    state: {
                        authorSearchText           
                    }
                })
            }else{
                // standard redirect to author details page
                this.props.history.push(`/author/${response.data.authorid}`)
            } 
            
        }else{
            this.setState({
                errorMessage: response.message,
                successMessage: "",
                isLoading: false
            })
        }
    }

    // call api to update author
    editAuthor = async (e) => {
        e.preventDefault();

        this.setState({isLoading: true})

 

        const response = await api.auth.updateAuthor(
            this.state.authorData.authorid,
            this.state.authorData.firstname,
            this.state.authorData.lastname,
            this.state.authorData.pseudonyms,
            this.state.authorData.country,
            this.state.authorData.born,
            this.state.authorData.died,
            this.state.authorData.description,
            this.state.authorData.picture_src,
            this.state.authorData.picture_title,
            this.state.authorData.picture_description,     
            this.state.pictureChanged ? false: true,
            this.state.authorData.active,
        )

        if(response && response.success){

            // redirect to given url if one is set
            if(this.props.location.redirectUrl){
                this.setState({isLoading: false}, () => {
                    this.props.history.push({
                            pathname: this.props.location.redirectUrl,
                            state: {forceUpdate: true}           
                    })
                })       
            }else{
                // default redirect to author detail page
                this.setState({
                    successMessage: response.message,
                    errorMessage: "",
                    isLoading: false
                }, () => {
                    this.props.history.push({
                       pathname: `/author/${this.state.authorData.authorid}`,
                       state: {forceUpdate: true} 
                    })
                })       
            }
        }else{
            // handle error in update author
            this.setState({
                successMessage:"",
                errorMessage:  response.message,
                isLoading: false
            })
        }
    }

    // update form field state
    onChange = (e) => {
        const {name, value} = e.target

        this.setState({
            authorData: {
                ...this.state.authorData,
                [name]: value,
            }
        })
    }

    // update state on picture change in PictureEdit component
    onPictureChange = (picture) => {
        this.setState({
            authorData: {
                ...this.state.authorData,
                picture_src: picture
            },
            pictureChanged: true,
        })
    }

    // update state on checkbox change
    onCheckboxChange = (e) => {
        this.setState({
            authorData: {
                ...this.state.authorData,
                active: e.target.checked ? 1 : 0,
            }
        })
    }

    removeErrorMessage = () => this.setState({errorMessage: ""})
    removeSuccessMessage = () => this.setState({successMessage: ""})

    render(){
        const {authorData} = this.state
        return(
            <div>
                <form >
                <div className="row">
                    <div className="col-md-12">
                        <MessageAlert type="error" message={this.state.errorMessage} onClose={this.removeErrorMessage}/>
                        <MessageAlert type="success" message={this.state.successMessage} onClose={this.removeSuccessMessage}/>
                    </div>
                    
                </div>
                    <div className="row">
                        <div className="col-md-3 col-sm-4 col-xs-12">
                            <h5>Author picture</h5>
                            <PictureEdit 
                                onChange={this.onChange}
                                onPictureChange={this.onPictureChange}
                                data={authorData}
                                namePrefix="picture"
                            />
                        </div>
                        <div className="col-md-9 col-sm-8 col-xs-12">

                            <h5>Author info</h5>                 

                            <div className="row" >   
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="firstname" >First name</label>
                                        <input                      
                                            className="form-control" 
                                            name="firstname" 
                                            placeholder="First name" 
                                            value={authorData.firstname ? authorData.firstname : ""}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="lastname">Last name</label>
                                        <input 
                                            className="form-control" 
                                            name="lastname" 
                                            placeholder="Last name" 
                                            value={authorData.lastname ? authorData.lastname : ""}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row" >
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="pseudonyms">Pseudonyms</label>
                                        <input                           
                                            className="form-control" 
                                            name="pseudonyms" 
                                            placeholder="Pseudonyms"
                                            value={authorData.pseudonyms ? authorData.pseudonyms : ""}
                                            onChange={this.onChange} 
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row" >
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="birth_date">Country</label>
                                        <input                                       
                                            className="form-control" 
                                            name="country" 
                                            placeholder="Country" 
                                            value={authorData.country ? authorData.country : ""}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>             
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label htmlFor="born">Born</label>
                                        <input 
                                            type="date"
                                            className="form-control" 
                                            name="born" 
                                            placeholder="Born" 
                                            value={authorData.born}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label htmlFor="died">Died</label>
                                        <input 
                                            type="date"
                                            className="form-control" 
                                            name="died" 
                                            placeholder="Died" 
                                            value={authorData.died}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-md-12">
                                    <textarea                            
                                        className="form-control" 
                                        name="description" 
                                        placeholder="Description" 
                                        value={authorData && authorData.description ? authorData.description : ""}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>

                                {this.state.isAdmin && 
                                <div className="row" >
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="checkbox-inline">
                                                <input 
                                                    type="checkbox" 
                                                    name="active" 
                                                    value={true} 
                                                    onChange={this.onCheckboxChange} 
                                                    checked={authorData.active}
                                                    /> 
                                                    active
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                }

                            <div className="row">
                                <div className="col-md-12" style={{margin: "1rem 0"}}>

                                    {this.state.authorData && this.state.authorData.authorid ? 
                                        <button className="btn btn-primary" onClick={this.editAuthor} disabled={this.state.isLoading}>
                                            {this.state.isLoading ? "Saving..." : "Save changes"}
                                        </button>
                                        :
                                        <button className="btn btn-primary" onClick={this.createAuthor} disabled={this.state.isLoading}>
                                            {this.state.isLoading ? "Creating author...": "Create author"}
                                        </button>
                                    }
                                    
                                </div>
                            </div>   
                                                                     
                        </div>                  
                    </div>         
                </form>
            </div>
        )
    }
}

export default withRouter(withAuthContext(AuthorEdit));