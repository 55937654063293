import React from 'react'

import "./PictureInput.css"

/*
    PICTURE INPUT COMPONENT
    a file input component for images
    valid props: 
        value
        onChange
        name
    example usage:
    <PictureInput name="picture" value={this.state.picture} onChange={this.onChange} />
    Note: the onChange prop receives the image file not the event itself
*/


class PictureInput extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            previewImage: null,
            isDragedOn: false,
            dragCounter: 0,
        }

        this.file_input = null;
    }

    componentDidMount(){
        this.handleValueUpdate(this.props.value);  
    }


    handleValueUpdate = (value) => {   

        let reader = new FileReader();
     
        // update previewImage state on file load
        reader.onloadend = () => {
            this.setState({   
                previewImage: reader.result     
           });
        }


        // read file if it is of type image
        if(value && value['type'].split('/')[0] === 'image'){
            reader.readAsDataURL(value);
        }else{
            this.setState({previewImage: null})
        }
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.value !== this.props.value){ 
            this.handleValueUpdate(this.props.value)
        }
    }

    
    onFileChange = async (e) => {
        e.preventDefault();
        // read first file of file input
        const files = e.target.files || e.dataTransfer.files;      
        const imgSrc = files[0];

        this.setState({
            dragCounter: 0,
            isDragedOn: false,
        })
        

        if(imgSrc && imgSrc['type'].split('/')[0] === 'image'){
           this.props.onChange(imgSrc) 
        }
    }
    
    // remove image handler
    clearPicture = (e) => {
        e.preventDefault();
        this.props.onChange(null)
    }



    /* handle drag and drop */

    // checks if node is draged on 
    onDragHover = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const {dragCounter} = this.state
        let newDragCounter = dragCounter;

        if(e.type === "dragenter"){
            newDragCounter++;
        }

        if(e.type === "dragleave"){
            newDragCounter--;
        }

        if(newDragCounter !== dragCounter){
            this.setState({dragCounter: newDragCounter, isDragedOn: (newDragCounter > 0)})
        } 
    }



    render(){
        const {previewImage, isDragedOn} = this.state

        let uploadFieldClasses = "upload-field" 
                                + (isDragedOn ? " upload-field--dragged" : "")
                                + (previewImage ? " upload-field--non-empty" : "")
     
        const imageClasses = previewImage ? 'upload-field__image' : 'upload-field__image hidden';
        const removeBtnClasses = previewImage ? 'upload-field__remove-btn' : 'hidden';
        const ctaClasses = previewImage ? 'upload-field__cta hidden' : 'upload-field__cta';


        const id = this.props.id ? this.props.id : "picture-upload"

        return (
            <div className={uploadFieldClasses} >
                <input id={id} type="file" name={this.props.name} accept="image/*" className="upload-field__input" onChange={this.onFileChange} ref={ref => (this.file_input = ref)}/>
                <button onClick={this.clearPicture} className={removeBtnClasses}>
                    <i className="icon-cross"></i>
                </button>   
                <label 
                    htmlFor={id}
                    className="upload-field__label" 
                    onDragEnter={this.onDragHover}
                    onDragOver={this.onDragHover} 
                    onDragLeave={this.onDragHover} 
                    onDrop={this.onFileChange} 
                    >
                    <img id="file-image" src={previewImage} alt="Preview" className={imageClasses}/>
                    <div className={ctaClasses}>
                        <div className="upload-field__cta-icon">
                            <i className="icon-upload"></i>
                        </div>
                        
                        <div>Select file or drag here</div>
                        <span className="btn btn-primary">Select a file</span>
                    </div>
                    
                </label>
            </div>
        )
    }
}


export default PictureInput;