/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Table } from 'reactstrap';
import api from '../api';
import LoadIndicator from './LoadIndicator';

class LinksSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = { links: null, loading: false };
    }

    componentDidMount() {
        this.fetchLinks();
    }

    async fetchLinks() {
        this.setState({ loading: true });
        const links = await api.open.requestLinks();
        this.setState({ links: links, loading: false })
    }

    render() {
        return (
            <section className="tg-sectionspace tg-haslayout">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="tg-sectionhead">
                                <h2>
                                    <span>Links</span>
                                    CryptoBooks
                                </h2>
                            </div>
                            <LoadIndicator isLoading={this.state.loading} loadingText="Loading links...">
                                <LinksViewer links={this.state.links} />
                            </LoadIndicator>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

function LinksViewer({ links }) {
    return (
        <div className="tg-description">
            <Table>
                <thead>
                    <tr>
                        <th>Picture</th>
                        <th>Website</th>
                        <th>Description</th>
                        <th>Link</th>
                    </tr>
                </thead>
                <tbody>
                    <LinksRows links={links} />
                </tbody>
            </Table>
        </div>
    );
}

function LinksRows({ links }) {
    if (links) {
        return links.map(link =>
            <tr key={link.linkid}>
                <th scope="row"><LinkPicture link={link} /></th>
                <th scope="row">{link.linktext}</th>
                <td>{link.description}</td>
                <td><a href={link.url} target="_blank">{link.url}</a></td>
            </tr>
        );
    }
    return null;
}

function LinkPicture({ link }) {
    if (!link.pictureid) {
        return null;
    }
    return <img style={{ width: 300 }} src={api.open.getPictureUrl(link.pictureid)} alt={link.description} />;
}

export default LinksSection;