import React from 'react';
import api from '../api';
import BookInfoViewer from './BookInfoViewer.js';
import SortBar from './SortBar.js';
import LoadIndicator from './LoadIndicator';

class SearchResults extends React.Component {
    constructor(props) {
        super(props);
        this.changeOrderByModeHandler = this.changeOrderByModeHandler.bind(this);
        this.loadMoreClickHandler = this.loadMoreClickHandler.bind(this);
        this.loadAllClickHandler = this.loadAllClickHandler.bind(this);

        this.state = {
            orderByMode: api.open.orderByMode.title,
            orderByAscDescMode: api.open.ascDescMode.asc,
            lastSearchHeader: null,
            searchResults: null,
            moreAvailable: false,
            loading: false
        };
    }

    async componentDidMount() {
        await this.fetchInitialSearchResults();
    }

    async componentDidUpdate(prevProps) {
        if (this.props.searchRequest !== prevProps.searchRequest
            || this.props.yearFrom !== prevProps.yearFrom
            || this.props.yearTo !== prevProps.yearTo) {
            await this.fetchInitialSearchResults();
        }
    }

    async fetchInitialSearchResults(amountPages) {
        this.setState({ searchResults: [] });
        await this.fetchSearchResults(0, amountPages, false);
    }

    async fetchNextSearchResults() {
        const lastHeader = this.state.lastSearchHeader;
        if (lastHeader) {
            if (lastHeader.page_to < lastHeader.pages) {
                await this.fetchSearchResults(lastHeader.page_to, lastHeader.page_to + 1, true);
            }
        }
    }

    async fetchAllRemainingSearchResults() {
        const lastHeader = this.state.lastSearchHeader;
        if (lastHeader) {
            if (lastHeader.page_to < lastHeader.pages) {
                await this.fetchSearchResults(lastHeader.page_to, lastHeader.pages, true);
            }
        }
    }

    async fetchSearchResults(pageFrom, pageTo, keepPrevious) {
        const searchRequest = this.props.searchRequest;
        const yearFrom = this.props.yearFrom;
        const yearTo = this.props.yearTo;
        if (!searchRequest && !yearFrom && !yearTo) {
            this.setState({ searchResults: null, loading: false });
            return;
        }

        this.setState({ loading: true });
        const orderBy = this.state.orderByMode;
        const ascDesc = this.state.orderByAscDescMode;
        const searchResultsObject = await api.open.performSearch(
            searchRequest,
            yearFrom,
            yearTo,
            orderBy,
            ascDesc,
            pageFrom,
            pageTo);
        this.setState(prevState => (
            {
                lastSearchHeader: searchResultsObject.header,
                searchResults: [...(keepPrevious ? prevState.searchResults : []), ...searchResultsObject.books],
                loading: false,
                moreAvailable: searchResultsObject.header.page_to < searchResultsObject.header.pages
            }));
    }

    async loadMoreClickHandler() {
        await this.fetchNextSearchResults();
    }

    async loadAllClickHandler() {
        await this.fetchAllRemainingSearchResults();
    }

    changeOrderByModeHandler(mode, ascDescMode) {
        const amountPages = this.state.lastSearchHeader ? this.state.lastSearchHeader.page_to : null;
        this.setState(
            {
                orderByMode: mode,
                orderByAscDescMode: ascDescMode
            },
            () => this.fetchSearchResults(0, amountPages, false));
    }

    render() {
        let header = this.state.lastSearchHeader;
        if (!header) {
            header = { totalcount: "-", query_execution_time: 0 };
        }
        let searchResults = this.state.searchResults;
        if (!searchResults) {
            searchResults = [];
        }

        return (
            <LoadIndicator isLoading={this.state.loading} loadingText="Loading search results...">
                <div style={{ marginTop: 0, fontSize: "13px" }}>
                    <div style={{ float: "left" }}>
                        Found <b>{header.totalcount}</b> total results in <b>{(header.query_execution_time * 1000).toFixed(2)}</b> milliseconds
                    </div>
                    <div style={{ float: "right" }}>
                        <div>Showing <b>{searchResults.length}</b> results</div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row">
                        <div style={{ float: "right" }}>
                            <SortBar
                                currentMode={this.state.orderByMode}
                                currentAscDescMode={this.state.orderByAscDescMode}
                                changeModeHandler={this.changeOrderByModeHandler} />
                        </div>
                    </div>
                    <div className="row row-eq-height-wrap">
                        {searchResults.map(bookResult =>
                            <BookInfoViewer
                                key={bookResult.bookid}
                                bookInfos={bookResult} />)}
                    </div>
                </div>
                {this.state.moreAvailable ?
                    <div className="tg-btns">
                        <button className="tg-btn tg-active" onClick={this.loadMoreClickHandler}>More</button>
                        <button className="tg-btn" onClick={this.loadAllClickHandler}>Show all</button>
                    </div>
                    : null}
            </LoadIndicator>
        );
    }
}

export default SearchResults;