import React from 'react'
import api from "../api"
import LoadingIndicator from "./LoadIndicator"
import {Link} from "react-router-dom"
import DynamicTable, {DynamicTableCol, intialTableState} from "./DynamicTable"

import "./UserContributionsSection.css"

class UserContributionSection extends React.Component{


    constructor(props){
        super(props)

        this.state = {
            inactive: [],
            isLoading: false,
            searchType: "",
            totalInactive: 0,
            tableState: {
                ...intialTableState,
                orderDir: "DESC"
            }
        }
    }

    componentDidMount(){
        this.fetchInactive();
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.searchType !== this.state.searchType){
            this.fetchInactive()
        }
    }

    fetchInactive = async () => {
        this.setState({isLoading: true})
        const {orderBy, orderDir, page} = this.state.tableState;
        const {searchType} = this.state
      
        const response = await api.auth.getInactive(orderBy, orderDir, page, searchType);
        this.setState({
            inactive: response.results, 
            isLoading: false, 
            totalInactive: response.totalResults,
            tableState: {
                ...this.state.tableState, 
                totalPages: response.totalPages
            }
        })
    }

    onTableUpdate = (newTableState) => {
        // update table state and refetch table data
        this.setState({tableState: newTableState}, () => {
            this.fetchInactive();
        })
    }

    onChange = (e) => {
        const {value, name} = e.target
        this.setState({[name]: value})
    }

    render(){
        return (
            <section className="tg-sectionspace tg-haslayout">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 page-header" style={{marginTop: "20px"}}>
                            
                            <h1 style={{fontSize: "1.8em"}}>
                                <div>
                                <small>Books / Editions / Authors</small>
                                </div>
                                Manage Inactive                               
                            </h1>
                        </div>
                      
                        <div className="col-sm-12">
                        
                        <div style={{margin: "10px 0", display: "flex", alignItems: "center"}}>
                            <select style={{marginRight: "10px"}} onChange={this.onChange} name="searchType" value={this.state.searchType}>
                                <option value="">All</option>
                                <option value="books">Inactive books</option>
                                <option value="editions">Inactive editions</option>
                                <option value="authors">Inactive authors</option>
                            </select>
                            found: {this.state.totalInactive ? this.state.totalInactive : 0}     
                            
                        </div>
                        <LoadingIndicator isLoading={this.state.isLoading} loadingText={"loading contributions..."}>
                            <DynamicTable
                                data={this.state.inactive}
                                tableState={this.state.tableState}
                                onTableStateUpdate={this.onTableUpdate}
                                renderRowItem={(props) => (
                                    <InactiveItemRecord key={`contrib-item-${props.index}`} {...props}/>
                                )}
                                emptyMessage="No inactive items found"
                                pagination={true}
                            >
                                <DynamicTableCol title="Type" dataRef="type" sortable/>
                                <DynamicTableCol title="Name" dataRef="text" sortable/>
                                <DynamicTableCol title="Last update" dataRef="last_update_date" sortable/>
                                <DynamicTableCol title="Link"/>
                            </DynamicTable>  
                        </LoadingIndicator>
                                                              
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const InactiveItemRecord = ({item: contribution, index}) => {
    const {id, text, type, active, last_update_date} = contribution

    let mainClasses = active ? "user-contribution dynamic-table-row" : "user-contribution dynamic-table-row dynamic-table-row--inactive" ;
    let detailLink = null;


    switch(type){
        case 'book': 
            mainClasses += " user-contribution--book"
            detailLink = `/book/${id}`;
            break;
        case 'author':
            mainClasses += " user-contribution--author"
            detailLink = `/author/${id}`;
            break;
        case 'edition':
            mainClasses += " user-contribution--edition"
            detailLink = `/book/${id}`;
            break;
        default: 
            break;
    }

    return (
        <tr className={mainClasses}>
            <th className="dynamic-table-col dynamic-table-col--sm user-contribution__type">{type}</th>
            <td className="dynamic-table-col"><Link className="dynamic-table-col__link" to={detailLink}>{text}</Link></td>
            <td className="dynamic-table-col dynamic-table-col--sm">{last_update_date}</td>
            <td className="dynamic-table-col dynamic-table-col--sm">
                <Link to={`${detailLink}/edit`} className="btn btn-primary">
                    Edit
                </Link>    
            </td>
        </tr>
    )
}






export default UserContributionSection;
