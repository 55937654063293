import React from 'react';
import { Link } from "react-router-dom";
import BookCoverPicture from './BookCoverPicture.js';

const MAXTITLELENGTH = 100;

function BookInfoViewer({ bookInfos }) {
    return (
        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-2">
            <div className="tg-postbook">
                <Link to={`/book/${bookInfos.bookid}`}>
                    <figure className="tg-featureimg">
                        <div className="tg-bookimg">
                            <div className="tg-frontcover">
                                <BookCoverPicture bookInfos={bookInfos} width={300} />
                            </div>
                            <div className="tg-backcover">
                                <BookCoverPicture bookInfos={bookInfos} width={300} />
                            </div>
                        </div>
                        <span className="tg-btnaddtowishlist">
                            <i className="icon-info"></i>
                            <span>Details</span>
                        </span>
                    </figure>
                </Link>
                <div className="tg-postbookcontent">
                    <ul className="tg-bookscategories">
                        {bookInfos.categories.map(category =>
                            <li key={category}><Link to={`/search/${category}`}>{category}</Link></li>
                        )}
                    </ul>
                    <div className="tg-booktitle">
                        <h3>
                            <Link to={`/book/${bookInfos.bookid}`} title={bookInfos.title}>
                                {shortenTitle(bookInfos.title)}
                            </Link>
                        </h3>
                    </div>
                    <span className="tg-bookwriter">
                        <Link to={`/author/${bookInfos.authorid}`}>
                            {bookInfos.firstname} {bookInfos.lastname}
                        </Link>
                    </span>
                    <span className="tg-bookyear">
                        <span>First Edition:</span> {bookInfos.year}
                    </span>
                    {bookInfos.found_in_fields ?
                        <span className="tg-bookfindreason">
                            <span>Found in fields: </span>
                            {bookInfos.found_in_fields.join(", ")}
                        </span> : null}
                </div>
            </div>
        </div>
    );
}

function shortenTitle(title) {
    if (title.length > MAXTITLELENGTH) {
        return title.substring(0, MAXTITLELENGTH) + "...";
    }
    return title;
}

export default BookInfoViewer;