import React from 'react'
import MessageAlert from "./MessageAlert"
import {withAuthContext} from "./AuthContext"

import api from "../api"

/*
    UserEditBaseInfo
    ----------------------------------------------------
    Form in a panel component that lets you edit the basic user information
*/

class UserEditBaseInfo extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            userData: {
                username: this.props.user ? this.props.user.username : "",
                email: this.props.user ? this.props.user.email : "",
                firstname: this.props.user ? this.props.user.firstname : "",
                lastname: this.props.user ? this.props.user.lastname : "",
            },
            userDataChanged: false,
            errorMessage: "",
            successMessage: "",
            isLoading: false
            
        }
    }

    updateUserData = (user) => {
        const {username, email, firstname, lastname} = user
        this.setState({
            userData: {username, email, firstname, lastname}
        })
    }

    onSubmit = async (e) => {
       e.preventDefault(); 
       this.setState({isLoading: true})
       const {username, email, firstname, lastname} = this.state.userData


        const response = await api.auth.updateUser(this.props.user.userid, username, email, firstname, lastname)

        if(response && response.success){
            // update AuthContext if user updates himself
            const {authContext} = this.props
            if(this.props.user.userid ===  authContext.user.userid){
                authContext.updateUserState({...this.state.userData})
            }
            this.setState({successMessage: response.message, errorMessage: "", userDataChanged: false, isLoading: false})
        }else{
            this.setState({errorMessage: response.message, successMessage: "", isLoading: false})
        }

    }

    componentDidUpdate(prevProps){
        if(prevProps.user !== this.props.user){
            this.updateUserData(this.props.user)
        }
    }

    onChange = (e) => {
        const {name, value} = e.target
        const userData = this.state.userData
        this.setState({userData: {...userData, [name]: value}, userDataChanged: true});
    }

    removeErrorMessage = () => this.setState({errorMessage: ""})
    removeSuccessMessage = () => this.setState({successMessage: ""})

    render(){
        const {userData} = this.state
        return(
            <div className="panel panel-primary">
                <div className="panel-heading">
                    <h2 className="panel-title">Edit base info</h2>
        
                </div>
               
                <div className="panel-body">          
                    <form>
                        <div className="row">
                            <div className="col-sm-12">
                                <MessageAlert type="error" message={this.state.errorMessage} onClose={this.removeErrorMessage}/>
                                <MessageAlert type="success" message={this.state.successMessage}  onClose={this.removeSuccessMessage}/>
                                
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="username">Username</label>
                                        <input 
                                            className="form-control" 
                                            name="username" 
                                            placeholder="Username" 
                                            value={userData.username}
                                            onChange={this.onChange}
                                        />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="email" >Email</label>
                                    <input 
                                        className="form-control" 
                                        type="email"
                                        name="email" 
                                        placeholder="Email" 
                                        value={userData.email}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6 col-xs-12">
                                <div className="form-group">       
                                    <label htmlFor="firstname">First name</label>
                                    <input 
                                        className="form-control" 
                                        name="firstname" 
                                        placeholder="First name" 
                                        value={userData.firstname}
                                        onChange={this.onChange}
                                    />
                                </div> 
                            </div>
                            <div className="col-sm-6 col-xs-12">
                                <div className="form-group">       
                                    <label htmlFor="lastname">Last name</label>
                                    <input 
                                        className="form-control" 
                                        name="lastname" 
                                        placeholder="Last name" 
                                        value={userData.lastname}
                                        onChange={this.onChange}
                                    />
                                </div> 
                            </div>
                        </div>
                                            
                        <button className="btn btn-primary" disabled={!this.state.userDataChanged || this.state.isLoading} onClick={this.onSubmit}>
                            {this.state.isLoading ? "Saving..." : "Save changes"}
                        </button>
                    </form>
                </div>
            </div>
        )
    }
}

export default withAuthContext(UserEditBaseInfo)