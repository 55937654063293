import React from 'react';
import RandomBooks from './RandomBooks.js';
import DatabaseStatistics from './DatabaseStatistics.js';


function HomeSection() {
    return (
        <div>
            <section className="tg-sectionspace tg-haslayout">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="tg-sectionhead">
                                <h2>
                                    <span>Welcome to</span>
                                    CryptoBooks
                  </h2>
                            </div>
                            <div className="tg-description">
                                <p>On this page you will find a bibliography of literature about cryptology from the 15th century until now.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <RandomBooks amount={6} />
            <DatabaseStatistics />
        </div>
    );
}

export default HomeSection;