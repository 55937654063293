import React from 'react'
import LoadingIndicator from "./LoadIndicator"
import api from "../api"
import confirmDelete from "./DialogConfirmDelete"
import MessageAlert from "./MessageAlert"
import DynamicTable, {DynamicTableCol, intialTableState} from "./DynamicTable";


const emptyCategory = {
    name: "",
    description: ""
}


class CategoriesManageSection extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            categories: [],
            insertCategory: emptyCategory,
            editCategory: emptyCategory,
            editCategoryId: null,
            isLoading: false,
            tableState: intialTableState,
            errorMessage: "",
            successMessage: "",
            showEditor: false,
            isInserting: false,
        }

        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true
        this.fetchCategories();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    fetchCategories = async () => {
        this.setState({isLoading: true})
        const {orderBy, orderDir} = this.state.tableState;
        const response = await api.open.getCategories(orderBy, orderDir);
        this.setState({isLoading: false, categories: response})
    }

    onTableUpdate = (newTableState) => {
        // update table state and refetch table data
        this.setState({tableState: newTableState}, () => {
            this.fetchCategories();
        })
    }

    onCreateCategory = async (e) => {
        e.preventDefault();
        this.setState({isInserting: true})
        // call api to insert category
        const {name, description} = this.state.insertCategory;
        const response = await api.auth.insertCategory(
            name,
            description
        )

        if(this._isMounted){
            // check response
            if(response && response.success){
                let {categories} = this.state

                // insert new category at the front of the category list
                categories.unshift({
                    name,
                    description,
                    categoryid: response.data.categoryid,
                    new: true
                })
                
                // update state on success
                this.setState({
                    editCategory: emptyCategory,
                    editCategoryId: null,
                    successMessage: response.message,
                    errorMessage: "",
                    categories,
                    isInserting: false
                })         
            }else{

                // update state on error
                this.setState({
                    errorMessage: response.message,
                    successMessage: "",
                    isInserting: false
                })
            } 
        }
    }

    onSetEditCategory = (idx) => {
        if(idx >= 0){
            this.setState({
                editCategoryId: idx,
                editCategory: this.state.categories[idx],
                showEditor: true
            })
        }else{
            this.setState({
                editCategoryId: null,
                editCategory: emptyCategory,
                showEditor: false
            })
        }
    }
    // make overlay for edit
    onEditCategory = async (e) => {
        e.preventDefault();
        this.setState({isLoading: true, showEditor: false})

        const {name, description ,categoryid} = this.state.editCategory;

        const response = await api.auth.updateCategory(
            categoryid,
            name,
            description
        )
        if(this._isMounted){
            if(response && response.success){

                let {categories} = this.state

                // update in list
                categories[this.state.editCategoryId] = {
                    categoryid,
                    name,
                    description
                }
            
                this.setState({              
                    successMessage: response.message,
                    errorMessage: "",
                    categories,
                    editCategory: emptyCategory,
                    editCategoryId: null, 
                    isLoading: false
                })
                
                
                
            }else{
                this.setState({
                    errorMessage: response.message,
                    successMessage: "",
                    isLoading: false
                })
            } 
        }
    }

    onDeleteCategory = async (category, index) => {
        const deleteConfirmation = await confirmDelete.show({
            text: `Are you sure you want to permanently remove category "${category.name}" (ID: ${category.categoryid})?`
        });

        if(deleteConfirmation) {
            // handle delete
            this.setState({})

            let {categories} = this.state;
            categories.splice(index, 1);
            this.setState({categories})

            const response = await api.auth.deleteCategory(category.categoryid);

            if(this._isMounted){
                if(response && response.success){    
                    this.setState({errorMessage: "", successMessage: response.message})
                }else{
                    this.setState({errorMessage: response.message, successMessage: ""})
                }
            }
        }
    }

    onChangeInsert = (e) => {
        const {name, value} = e.target;
        this.setState({
            insertCategory: {
                ...this.state.insertCategory,
                [name]:value
            }
        })
    }

    onChangeEdit = (e) => {
        const {name, value} = e.target;
        this.setState({
            editCategory: {
                ...this.state.editCategory,
                [name]:value
            }
        })
    }


    removeErrorMessage = () => this.setState({errorMessage: ""})
    removeSuccessMessage = () => this.setState({successMessage: ""})

    render(){
        return (
            <section >
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 page-header" style={{marginTop: "20px"}}>
                            
                            <h1 style={{fontSize: "1.8em"}}>
                                <div>
                                <small>Create / Edit / Delete Categories</small>
                                </div>
                                Manage Categories                               
                            </h1>
                        </div>


                        <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-2">
                                <div className="form-group">                        
                                    <input 
                                        className="form-control" 
                                        name="name" 
                                        placeholder="Category name" 
                                        onChange={this.onChangeInsert}
                                        value={this.state.insertCategory.name}
                                    />
                                </div>
                            </div>   
                            <div className="col-md-8">
                                <div className="form-group">                        
                                    <input 
                                        className="form-control" 
                                        name="description" 
                                        placeholder="Category description" 
                                        onChange={this.onChangeInsert}
                                        value={this.state.insertCategory.description}              
                                    />
                                </div>
                            </div>      
                            <div className="col-md-2 text-center" style={{display: "flex", alignItems: "center"}}>
                                {this.state.editCategoryId !== null ?
                                    <button className="btn btn-primary btn-block" onClick={this.onEditCategory}>Edit category</button>
                                    :
                                    <button className="btn btn-primary btn-block"  onClick={this.onCreateCategory} disabled={this.state.isInserting}>
                                        {this.state.isInserting ? "Creating category...": "Create category"}
                                    </button>
                                }
                                
                            </div>   
                        </div>   
                        </div>
                 
                        <hr />

                        <div className="col-md-12">
                            <MessageAlert type="error" message={this.state.errorMessage} onClose={this.removeErrorMessage}/>
                            <MessageAlert type="success" message={this.state.successMessage} onClose={this.removeSuccessMessage}/>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <LoadingIndicator isLoading={this.state.isLoading} loadingText="loading categories...">
                                <DynamicTable
                                    data={this.state.categories}
                                    tableState={this.state.tableState}
                                    onTableStateUpdate={this.onTableUpdate}
                                    renderRowItem={(props) => (
                                        <CategoryRecordItem 
                                            key={`category-item-${props.index}`} 
                                            {...props}
                                            onSetEditCategory={this.onSetEditCategory}
                                            onDeleteCategory={this.onDeleteCategory}
                                        />
                                    )}
                                    emptyMessage="No categories yet"
                                    pagination={false}
                                >
                                    <DynamicTableCol title="Id" dataRef="categoryid" sortable/>
                                    <DynamicTableCol dataRef="name" sortable/>
                                    <DynamicTableCol dataRef="description"/>
                                    <DynamicTableCol title="Edit"/>
                                    <DynamicTableCol title="Delete"/>
                                </DynamicTable>   
                            </LoadingIndicator>    
                            <CategoryEditor 
                                category={this.state.editCategory}
                                onEditCategory={this.onEditCategory}
                                show={this.state.showEditor}
                                onChange={this.onChangeEdit}
                                onSetEditCategory={this.onSetEditCategory}
                            />          
                        </div>    
                    </div>
                </div>
            </section>     
        )
    }
}


const CategoryRecordItem = ({item: category, index, onDeleteCategory, onSetEditCategory}) => {
    return (
        <tr className="dynamic-table-row">
            <td className="dynamic-table-col--sm">{category.categoryid}</td>
            <td>{category.name}</td>
            <td>{category.description}</td>
            <td className="dynamic-table-col--sm">
                <button className="btn btn-primary" onClick={() => onSetEditCategory(index)}>Edit</button>       
            </td>
            <td className="dynamic-table-col--sm">
                <button className="btn btn-danger" onClick={() => onDeleteCategory(category, index)}>Delete</button>
            </td>
        </tr>
    )
}

const CategoryEditor = ({category, onSetEditCategory, onEditCategory, show, onChange}) => {

    return (
        <div className={!show ? 'edit-picture-overlay' : 'edit-picture-overlay edit-picture-overlay--active'}>

        <div className="modal-dialog">
          <div className="modal-content panel-primary">
            <div className="modal-header panel-heading edit-picture-overlay__heading" >
              <button type="button" className="close" onClick={() => onSetEditCategory(-1)}>&times;</button>
               <h4 className="modal-title">Edit Category:  (ID)</h4>
            </div>
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">                        
                            <input 
                                className="form-control" 
                                name="name" 
                                placeholder="Category name" 
                                value={category ? category.name : ""}
                                onChange={onChange}
                            />
                        </div>
                    </div>   
                    <div className="col-md-12">
                        <div className="form-group">                        
                            <textarea 
                                className="form-control" 
                                name="description" 
                                placeholder="Category description" 
                                value={category ? category.description : ""}
                                onChange={onChange}
                            />
                        </div>
                    </div>    
                </div>  
            </div>
            <div className="modal-footer">           
              <button type="button" className="btn btn-primary" onClick={onEditCategory}>Save changes</button>
              <button type="button" className="btn btn-default" onClick={() => onSetEditCategory(-1)}>Cancel</button>
            </div>
          </div>
        </div>            
      </div>
    )
}


export default CategoriesManageSection;