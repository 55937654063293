import React from 'react'
import MessageAlert from "./MessageAlert"

import CollapsiblePanel from "./CollapsiblePanel"

import api from "../api"

const emptyUserData = {
    current_password: "",
    new_password: "",
    confirm_new_password: "",
}

/*
    UserChangePassword
    ----------------------------------------------------
    Form to change the users password (oldpassword => newpassword)
*/

class UserChangePassword extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            userData: emptyUserData,
            errorMessage: "",
            successMessage: "",
            isLoading: false
        }
    }

    // call api to cahnge password
    onSubmit = async (e) => {
        e.preventDefault();
        this.setState({isLoading: true})

        const {current_password, new_password, confirm_new_password} = this.state.userData
        if(new_password === confirm_new_password){
            const response = await api.auth.changeUserPassword(this.props.user.userid, current_password, new_password)

            if(response && response.success){
                this.setState({successMessage: response.message, userData: emptyUserData, errorMessage: "", isLoading: false})
            }else{
                this.setState({errorMessage: response.message, userData: emptyUserData, successMessage: "", isLoading: false})
            }
        }else{
            this.setState({errorMessage: "New passwords don't match.", userData: emptyUserData, successMessage: "", isLoading: false})
        }
    }

    // update form state
    onChange = (e) => {
        const {name, value} = e.target
        const userData = this.state.userData
        this.setState({userData: {...userData, [name]: value}});
    }

    removeErrorMessage = () => this.setState({errorMessage: ""})
    removeSuccessMessage = () => this.setState({successMessage: ""})

    render(){
        const {userData} = this.state
        return(

            <CollapsiblePanel type="primary" id="changePasswordPanel" title="Change password">

                <form className="form-horizontal">
                    <div className="form-group col-sm-12">
                        <MessageAlert type="error" message={this.state.errorMessage} onClose={this.removeErrorMessage}/>
                        <MessageAlert type="success" message={this.state.successMessage}  onClose={this.removeSuccessMessage}/>
                        
                    </div>
                    <div className="form-group col-sm-12">
                        <label htmlFor="current_password" className="sr-only"></label>
                        <input 
                            className="form-control" 
                            type="password"
                            name="current_password" 
                            placeholder="Current password" 
                            value={userData.current_password}
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="form-group col-sm-12">
                        <label htmlFor="email" className="sr-only"></label>
                        <input 
                            className="form-control" 
                            type="password"
                            name="new_password" 
                            placeholder="New password" 
                            value={userData.new_password}
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="form-group col-sm-12">
                        <label htmlFor="email" className="sr-only"></label>
                        <input 
                            className="form-control" 
                            type="password"
                            name="confirm_new_password" 
                            placeholder="Confirm new password" 
                            value={userData.confirm_new_password}
                            onChange={this.onChange}
                        />
                    </div>
                          
                    <button className="btn btn-primary" onClick={this.onSubmit}>
                        {this.state.isLoading ? "Saving..." : "Change password"}
                    </button>
                </form>
            </CollapsiblePanel>
        )
    }
}

export default UserChangePassword