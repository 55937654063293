import {OPEN_API_URL as apiUrl} from "./index"

const orderByMode = {
    none: 0,
    title: 1,
    year: 2,
    firstname: 4,
    lastname: 8
};

const ascDescMode = {
    asc: 0,
    desc: 1
};

async function performQuickSearch(word) {
    if (!word) {
        return [];
    }

    try {
        const response = await fetch(`${apiUrl}?${getUrlEncodedParams({
            "action": "quicksearch",
            "word": word
        })}`);
        const responseJson = await response.json();
      
        return responseJson.map(s => s.word);
    } catch (ex) {
        console.log(`Error while trying to perform quick search: ${ex.message}`);
        return [];
    }
}

async function performSearch(word, yearFrom, yearTo, orderBy, ascDesc, pageFrom, pageTo) {
    try {
        const params = {
            "action": "search",
            "word": word || "",
            "orderby": orderBy,
            "ascdesc": ascDesc,
            "page_from": pageFrom || "",
            "page_to": pageTo || "",
        };
        if (yearFrom) {
            params["year_from"] = yearFrom;
        }
        if (yearTo) {
            params["year_to"] = yearTo;
        }

        const url = `${apiUrl}?${getUrlEncodedParams(params)}`;
        const response = await fetch(url);
        return await response.json();
    } catch (ex) {
        console.log(`Error while trying to perform search: ${ex.message}`);
        return null;
    }
}

async function requestBookInfos(bookId) {
    try {
        const response = await fetch(`${apiUrl}?${getUrlEncodedParams({
            "action": "getbook",
            "bookid": bookId
        })}`);
        return await response.json();
    } catch (ex) {
        console.log(`Error while trying to get book infos: ${ex.message}`);
        return null;
    }
}

async function requestBookPictures(bookId) {
    try {
        var myHeaders = new Headers();
        myHeaders.append('pragma', 'no-cache');
        myHeaders.append('cache-control', 'no-cache');
        const response = await fetch(`${apiUrl}?${getUrlEncodedParams({
            "action": "getpictures",
            "bookid": bookId
        })}`, {
            headers: myHeaders
        });

        return await response.json();
    } catch (ex) {
        console.log(`Error while trying to get book pictures: ${ex.message}`);
        return null;
    }
}

async function requestEditions(bookId) {
    try {
        const response = await fetch(`${apiUrl}?${getUrlEncodedParams({
            "action": "geteditions",
            "bookid": bookId
        })}`);
 
        // return json
        return await response.json();
    } catch (ex) {
        console.log(`Error while trying to get editions: ${ex.message}`);
        return null;
    }
}

async function requestAuthorInfos(authorId) {

    try {
        const response = await fetch(`${apiUrl}?${getUrlEncodedParams({
            "action": "getauthor",
            "authorid": authorId
        })}`);

        const text = await response.text();
        const json = JSON.parse(text);
        return json;
    } catch (ex) {
        console.log(`Error while trying to get author infos: ${ex.message}`);
        return null;
    }
}

async function requestAuthorBooks(authorId) {
    try {
        const response = await fetch(`${apiUrl}?${getUrlEncodedParams({
            "action": "getbooks",
            "authorid": authorId
        })}`);
        return await response.json();
    } catch (ex) {
        console.log(`Error while trying to get author's books infos: ${ex.message}`);
        return null;
    }
}

async function requestRandomBooks(number) {
    try {
        const response = await fetch(`${apiUrl}?${getUrlEncodedParams({
            "action": "getrandombooks",
            "number": 6,
           
        })}`, { cache: "reload" });
        return  await response.json();
    } catch (ex) {
        console.log(`Error while trying to get random books infos: ${ex.message} `);
        return null;
    }
}

async function requestStatistics() {
    try {
        const response = await fetch(`${apiUrl}?${getUrlEncodedParams({
            "action": "getstatistics"
        })}`);
        return await response.json();
    } catch (ex) {
        console.log(`Error while trying to get statistics: ${ex.message} `);
        return null;
    }
}

async function sendContactMail(name, email, subject, message, g_recaptcha_response) {
    try {
        const response = await fetch(`${apiUrl}?action=sendcontactmail`, {
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: getUrlEncodedParams({
                "yourname": name,
                "youremail": email,
                "yoursubject": subject,
                "yourmessage": message,
                "g-recaptcha-response": g_recaptcha_response
            })
        });
        return await response.json();
    } catch (ex) {
        console.log(`Error while trying to send contact mail: ${ex.message} `);
        return null;
    }
}

async function requestLinks() {
    try {
        const response = await fetch(`${apiUrl}?${getUrlEncodedParams({
            "action": "getlinks"
        })}`);
        return await response.json();
    } catch (ex) {
        console.log(`Error while trying to get links: ${ex.message} `);
        return null;
    }
}

async function getPictureInfo(pictureid){
    try{
        const response = await fetch(`${apiUrl}?${getUrlEncodedParams({
            "action": "getpictureinfo",
            "pictureid": pictureid
        })}`)

        return await response.json();

    }catch(ex){
        console.log(`Error while trying to get picture: ${ex.message} `);
        return null;
    }   
}



async function getPictureFile(pictureId, auto_scale=false){
    try{
        const response = await fetch(`${apiUrl}?${getUrlEncodedParams({
            "action": "getpicture",
            "pictureid": pictureId,
            "auto_scale": auto_scale
        })}`)

 
        const blob = await response.blob();

        blob.lastModifiedDate = new Date();
        
        blob.name = "picture.jpg";
        return blob

    }catch(ex){
        console.log(`Error while trying to get picture: ${ex.message} `);
        return null;
    }
}

function getPictureUrl(pictureId, width=null, height=null) {
    const params = {
        "action": "getpicture",
        "pictureid": pictureId
    };
    if (width) {
        params.width = width;
    }
    if (height) {
        params.height = height;
    }
    return `${apiUrl}?${getUrlEncodedParams(params)}`;
}

async function getCategories(order_by="", order_dir=""){
    try{
        const response = await fetch(`${apiUrl}?${getUrlEncodedParams({
            "action": "getcategories",
            "order_by": order_by,
            "order_dir": order_dir,
        })}`)

        return await response.json();
    }catch(ex){
        console.log(`Error while trying to get categories: ${ex.message} `);
        return null;
    }  
}

function getUrlEncodedParams(params) {
    return Object.keys(params)
        .map(p => encodeURIComponent(p) + '=' + encodeURIComponent(params[p]))
        .join('&');
}




const simulateLoadDelay = false;    //Set to true to simulate load delay
if (simulateLoadDelay) {
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const oldFetch = fetch;
    // eslint-disable-next-line no-native-reassign
    fetch = async function (...args) {
        await sleep(2000);
        return await oldFetch(...args);
    }
}

export default
    {
        orderByMode,
        ascDescMode,
        performQuickSearch,
        performSearch,
        requestBookInfos,
        requestBookPictures,
        requestEditions,
        requestAuthorInfos,
        requestAuthorBooks,
        requestRandomBooks,
        requestStatistics,
        sendContactMail,
        requestLinks,
        getPictureUrl,
        getPictureFile,
        getPictureInfo,
        getCategories,

    };