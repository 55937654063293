import React from 'react'


import "./CollapsiblePanel.css"

/*
    CollapsiblePanel
    Wrapper Component for Bootstrap Panels to make them collapsible

    props:
    children: get rendered in panel body
    id: is used as identifier for the toggle mechanism
    title: title that get rendered in 

    <CollapsiblePanel type={} id="createUserPanel" title="Create new user">
        ...bodyContent
    </CollapsiblePanel>

    <CollapsiblePanel type="primary" id="myPanel" renderTitle=()

*/


class CollapsiblePanel extends React.Component{

    constructor(props){
        super(props);

        this.expandButton = React.createRef();
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
        if(this.props.initialExpand){
            if(this._isMounted){
                this.expandButton.current.click();
            }          
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }
    
    getDefaultPanelClasses = (type) => {
        switch(type){
            case 'primary': 
                return 'panel panel-collapsible panel-primary';
            case 'success': 
                return 'panel panel-collapsible panel-success';
            case 'info': 
                return 'panel panel-collapsible panel-info';
            case 'warning': 
                return 'panel panel-collapsible panel-warning';
            case 'danger': 
                return 'panel panel-collapsible panel-danger';
            default: 
                return 'panel panel-collapsible panel-default'
        }
    }



    render(){
        const {children, id, title, type, renderTitle, className, dataParent} = this.props
        let renderClasses = this.getDefaultPanelClasses(type)
        renderClasses = className ? renderClasses + " " + className : renderClasses;
    
        return (
            <div className={renderClasses}>
                    <div className="panel-heading">
                        <a className="collapsed" data-toggle="collapse" href={`#${id}`} role="button" aria-expanded="false" aria-controls={id} ref={this.expandButton} >                                                                          
                                {renderTitle ? renderTitle() : (
                                    <h3 className="panel-title">         
                                        {title || "My Title"}
                                    </h3>
                                )}                                   
                        </a>
                    </div>
                    <div className="collapse" id={id} data-parent={`#${dataParent}`}>
                        <div className="panel-body">
                            {children}
                        </div>
                    </div>
            </div>      
        )
    }
}

export default CollapsiblePanel