import React from 'react';
import api from '../api';
import LoadIndicator from './LoadIndicator';
import BookInfoViewer from './BookInfoViewer.js';

class RandomBooks extends React.Component {
    constructor(props) {
        super(props);
        this.refreshHandler = this.refreshHandler.bind(this);

        this.state = { randomBooks: null, loading: false };
    }

    componentDidMount() {
        this.fetchRandomBooks();
    }

    async fetchRandomBooks() {
        this.setState({ loading: true });
        const randomBooks = await api.open.requestRandomBooks(this.props.amount);
        this.setState({ randomBooks: randomBooks, loading: false })
    }

    async refreshHandler() {
        await this.fetchRandomBooks();
    }

    render() {
        return (
            <section className="tg-sectionspace tg-haslayout">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="tg-sectionhead">
                                <h2>
                                    <span>Random books</span>
                                    CryptoBooks
                                </h2>
                            </div>
                        </div>
                        <LoadIndicator isLoading={this.state.loading} loadingText="Loading random books...">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="row row-eq-height-wrap">
                                    {this.state.randomBooks ?
                                        this.state.randomBooks.map(bookInfos =>
                                            <BookInfoViewer
                                                key={bookInfos.bookid}
                                                bookInfos={bookInfos} />) : null}
                                </div>
                            </div>
                            <div className="tg-btns">
                                <button className="tg-btn tg-active" onClick={this.refreshHandler}>Show other</button>
                            </div>
                        </LoadIndicator>
                    </div>
                </div>
            </section>
        );
    }
}

export default RandomBooks;