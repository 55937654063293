import React from 'react';
import api from '../api';
import LoadIndicator from './LoadIndicator';

class DatabaseStatistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = { statistics: null, loading: false };
    }

    componentDidMount() {
        this.fetchStatistics();
    }

    async fetchStatistics() {
        this.setState({ loading: true });
        const statistics = await api.open.requestStatistics();
        this.setState({ statistics: statistics, loading: false })
    }

    render() {
        return (
            <section className="tg-sectionspace tg-haslayout">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="tg-sectionhead">
                                <h2>
                                    <span>Database Statistics</span>
                                    CryptoBooks
                                </h2>
                            </div>
                        </div>
                        <LoadIndicator isLoading={this.state.loading} loadingText="Loading database statistics...">
                            <StatisticsViewer statistics={this.state.statistics} />
                        </LoadIndicator>
                    </div>
                </div>
            </section>
        );
    }
}

function StatisticsViewer({ statistics }) {
    if (!statistics) {
        return null;
    }

    const colStyle = "col-xs-6 col-sm-6 col-md-4 col-md-offset-2 col-lg-4 col-lg-offset-2";

    return (
        <div>
            <div className="row">
                <div className={colStyle}>
                    <span><b>Total Books: </b></span>
                    <span>{statistics.totalbooks}</span>
                </div>
                <div className={colStyle}>
                    <span><b>Newest Book's Publication Year: </b></span>
                    <span>{statistics.maxbookyear}</span>
                </div>
            </div>
            <div className="row">
                <div className={colStyle}>
                    <span><b>Oldest Book's Publication Year: </b></span>
                    <span>{statistics.minbookyear}</span>
                </div>
                <div className={colStyle}>
                    <span><b>Total Authors: </b></span>
                    <span>{statistics.totalauthors}</span>
                </div>
            </div>
        </div>
    );
}

export default DatabaseStatistics;