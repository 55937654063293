import React from 'react';

function PrivacySection() {
    return (
        <section className="tg-sectionspace tg-haslayout">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="tg-sectionhead">
                            <h2>
                                <span>Privacy Policy</span>
                                CryptoBooks
                                </h2>
                        </div>
                        <div className="tg-description">
                            <p>
                                This declaration is intended to inform the user of this website about the type and purpose of collecting and using personal data.
                            </p>
                            <p>
                                We use the data you provide to us, such as your name or e-mail address, as well as the usage data collected when using cryptobooks.org, solely for the purpose of using and securing the website, processing your enquiry or processing your online access. Your data will not be passed on to third parties. All personal data will only be stored by us for as long as this is necessary for the stated purpose or as long as we are legally obliged to store it. We do not collect personal data from you as far as possible. You can also use this website without entering personal data yourself, i.e. you can visit the freely accessible parts of the website without telling us who you are. In the case of active participation, however, an e-mail address is required.
                            </p>
                            <p>
                                When you visit cryptobooks.org, information about your access to cryptobooks.org can be stored on our server. Examples of <b>access data</b> are: date, time, viewed page, name of your internet provider, type of your internet browser, visited pages within our internet presence, your IP address. These data are evaluated by us exclusively for statistical purposes, to improve our offer and for IT security. These data are anonymized and do not belong to the personal data.
                            </p>
                            <p>
                                <b>You are entitled to free information about your personal data stored by us and, if applicable, have a right to correct, block or delete this data.</b> To do so, please send an email to <a href="mailto:contact@cryptobooks.org">contact@cryptobooks.org</a>.
                            </p>
                            <p>
                                <b>If you have given your consent to the storage and processing of your data (e.g. for the purchase of a free newsletter), you can revoke your consent at any time with effect for the future.</b>
                            </p>
                            <p>
                                When you visit cryptobooks.org, data may be stored on your computer. These data in the form of small text files are called "<b>cookies</b>" and make it easier for you to use cryptobooks.org. Cookies enable us to store the settings you have made. Of course, you can deactivate the setting of cookies in your browser at any time for our website or delete them after the end of your session.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PrivacySection;