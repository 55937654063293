import React from 'react'
import PictureEdit from "./PictureEdit"
import confirmDelete from "./DialogConfirmDelete"
import {Prompt} from "react-router-dom"
import MessageAlert from "./MessageAlert";
import "./BookEditPictures.css"
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from "array-move"
import LoadingIndicator from "./LoadIndicator"

import api from "../api"

import "./DialogConfirmDelete.css"; 

/* 
    File Contains:
    BookEditPictures

*/

/*
    BookEditPictures:
    Editor that allows to modify the books pictures (adding, updating, set main picture, update order)
    Uses a Context to simplify passing props to deeply nested components
*/

const BookPictureContext = React.createContext({});

const BookPictureConsumer = BookPictureContext.Consumer;

const emptyPicture = {
    src: null,
    title: "",
    description: "",
}

class BookEditPictures extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            pictures: [],
            createPicture: emptyPicture,
            isCreatingPicture: false,

            editPicture: emptyPicture,
            editPictureIndex: null,
            editPictureChanged: false,
            editPictureSavable: false,
            isUpdating: false,
            showEditor: false,
          
            successMessage: "",
            errorMessage: "",
            isLoading: false,       
            isSettingMainPicture: false,
            disableOrderBtn: true,
            savingOrder: false,
        }

        this.pictureContainer = React.createRef();
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
        this.fetchBookPictures();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    componentDidUpdate(prevProps){
        // check for updated bookid prop
        if(prevProps.bookId !== this.props.bookId){
            this.fetchBookPictures();
        }
    }

    fetchBookPictures = async () => {
        const response = await api.open.requestBookPictures(this.props.bookId)

        const pictures = response.map(picture => ({
            ...picture,
            srcFile: null,
            loading: true,
            title: picture.name
        }))

        this.setState({pictures}, () => {
            // load picture files
            pictures.forEach((picture) => {
                if(this._isMounted){
                    this.fetchPicture(picture)     
                }  
            })
        })

        
    }

    // fetches scaled down picture file from api
    fetchPicture = async(picture) => {    
        const fileBlob = await api.open.getPictureFile(picture.pictureid, true) // autoscale=true   
        this.updatePictureState(fileBlob, picture)
    }

    // update picture state with new blob
    updatePictureState = (fileBlob, picture) => {
        if(fileBlob){
            const fileReaderInstance = new FileReader();
            fileReaderInstance.onload = () => {
                let res = fileReaderInstance.result;    
                
                let {pictures} = this.state
                pictures = pictures.map(pic => {      
                    if(pic.pictureid !== picture.pictureid) return pic;

                    return {
                        ...pic,
                        src: fileBlob,
                        previewUrl: res,
                        loading: false
                    }
                })

                if(this._isMounted){
                    this.setState({pictures}) 
                }             
            }


            fileReaderInstance.readAsDataURL(fileBlob);
        }else{
            let {pictures} = this.state
            pictures = pictures.map(pic => {      
                if(pic.pictureid !== picture.pictureid) return pic;

                return {
                    ...pic,
                    src: null,
                    previewUrl: null,
                    loading: false
                }
            })
        }   
    }

    // after item drop event listener for react-sortable-hoc
    onSortEnd = ({oldIndex, newIndex}) => {
        this.setState(({pictures}) => ({
            disableOrderBtn: false,
            pictures: arrayMove(pictures, oldIndex, newIndex),
        }));
    };

    // update which picture is currently  editable
    setEditPicture = (idx) => {
        if(idx < this.state.pictures.length && idx >= 0){
            const editPicture = this.state.pictures[idx];
            this.setState({editPicture, editPictureChanged: false, editPictureIndex: idx, showEditor: true, editPictureSavable: false})
        }else{
            this.setState({editPicture: emptyPicture, editPictureIndex: null, editPictureChanged: false, showEditor: false, editPictureSavable: false})
        }
    }

    // calls api to set picture to main picture of the book
    setMainPicture = async (idx) => {
        let mainPicture = null;
        this.setState({isSettingMainPicture: true})

        // remove main picture flag from pictures array
        const pictures = this.state.pictures.map((item, itemIdx) => {
            if(itemIdx === idx) mainPicture = item;
            return {...item, mainpicture: itemIdx === idx}
        })

        const pictureid = mainPicture ? mainPicture.pictureid : null;
       
        const response = await api.auth.setBookMainPicture(this.props.bookId, pictureid)

        if(response.success){
            this.setState({pictures, isSettingMainPicture: false})
        }else{
            this.setState({isSettingMainPicture: false})
        }
    }

    // on change handler for createPicture state
    onChangeCreate = (e) => {
        const {name, value} = e.target
        this.setState({
            createPicture: {
                ...this.state.createPicture,
                [name]: value
            }
        })
    }

    // on change handler for createPicture src
    onCreatePictureChange = (picture) =>{
        this.setState({
            createPicture: {
                ...this.state.createPicture,
                src: picture
            }
        }) 
    }

    // on change handler for editPicture state
    onChangeEdit = (e) =>{
        const {name, value} = e.target
        this.setState({
            editPictureSavable: true,
            editPicture: {
                ...this.state.editPicture,
                [name]: value
            }
        })
    }

    // on change handler for editPicture src
    onEditPictureChange = (picture) =>{
        this.setState({
            editPicture: {
                ...this.state.editPicture,
                src: picture
            },
            editPictureChanged: true,
            editPictureSavable: true
        }) 
    }


    // saves the current order of the sortable pictures via api
    saveSortOrder = async (e) => {
        e.preventDefault();
        this.setState({isLoading: true, saveSortOrder: true})
        const orderedPictures = this.state.pictures.reduce((prevValue, currentValue, index) => ({
            ...prevValue,
            [currentValue.pictureid]: index
        }),{})

        const response = await api.auth.updateBookPictureOrder(
            this.props.bookId,
            orderedPictures
        )

        if(response && response.success){
            this.setState({successMessage: response.message, errorMessage: "", isLoading: false, disableOrderBtn: true, saveSortOrder: false})    
        }else{
            this.setState({errorMessage: response.message, successMessage: "", isLoading: false, saveSortOrder: false})
        }
    }

    // calls api to insert new picture
    insertPicture = async (e) => {
        e.preventDefault();

        this.setState({isCreatingPicture: true})

        const {createPicture} = this.state

        const response = await api.auth.insertBookPicture(
            this.props.bookId,
            createPicture.src,
            createPicture.title,
            createPicture.description
        )

        if(response && response.success){

            const newCreatedPicture = {
                ...createPicture,
                pictureid: response.data.pictureid,
                src: createPicture.src,
                loading: true,
                picture_position: response.data.picture_position
            }

            let pictures = [
                ...this.state.pictures,
                newCreatedPicture
            ]

            this.setState({
                pictures,
                createPicture: emptyPicture,
                successMessage: response.message           
            }, () => {
                this.updatePictureState(createPicture.src, newCreatedPicture)
            })         
        }else{
            const errorMessage = response && response.message ? response.message : "Failed to insert picture.";
            this.setState({errorMessage, isCreatingPicture: false, createPicture: emptyPicture })
        }
        this.setState({isCreatingPicture: false})
    }

    // auto scroll element to given position
    // currently unused
    /*scrollTo = (element, yPos, duration) => {

        const startY = element.scrollTop;
        const goalY = yPos;
        const scrollDistance = goalY - startY;

        const scrollStep = scrollDistance / (duration / 15);
        let distanceScrolled = 0;

        const step = (newTimestamp) => {
            element.scrollTop += scrollStep;
            distanceScrolled += Math.abs(scrollStep);

            if(distanceScrolled >= Math.abs(scrollDistance)){
                return;
            }
            window.requestAnimationFrame(step);
        }

        window.requestAnimationFrame(step);
    }*/

    // call api to update picture
    updatePicture = async (e) => {
        e.preventDefault();

        let {pictures} = this.state;
        pictures = pictures.map((picture) => ({...picture, refreshImage: false}))
        this.setState({isUpdating: true, pictures})

        const {editPicture} = this.state

        if(editPicture && editPicture.src === null){
            this.setState({
                editPicture: emptyPicture,
                editPictureIndex: null,
                errorMessage: "picture can't be empty.",
                successMessage: "",
                showEditor: false,
                isUpdating: false,
                editPictureSavable: false,
            })
            return;
        }

        const response = await api.auth.updateBookPicture(
            this.props.bookId,
            editPicture.pictureid,
            this.state.editPictureChanged ? editPicture.src : null,
            editPicture.title,
            editPicture.description,
            this.state.editPictureChanged ? false: true 
        )

        if(response && response.success){

            let {pictures} = this.state

            const updatedPicture = {
                ...pictures[this.state.editPictureIndex],
                ...editPicture,
                refreshImage: true
            }

            pictures[this.state.editPictureIndex] = updatedPicture

            const shouldUpdatePicturesState = this.state.editPictureChanged 

            this.setState({
                pictures,
                editPicture: emptyPicture,
                editPictureIndex: null,
                editErrorMessage: "",
                showEditor: false,
                isUpdating: false,
                editPictureSavable: false
            }, () => {
                if(shouldUpdatePicturesState){
                    this.updatePictureState(editPicture.src, {...updatedPicture, loading: true})
                }
                
            })
        }else{
            const editErrorMessage = response && response.message ? response.message : "Failed to update book picture.";
            this.setState({editErrorMessage, isUpdating: false, editPictureSavable: false})
        }    
    }

    // call api to delete picture
    deletePicture = async (e, idx) => {
        e.preventDefault();
       
        const picture = this.state.pictures[idx]

        if(picture){
            const {pictureid} = picture; 
            // ask for delete confirmation
            const deleteConfirmation = await confirmDelete.show({
                text: `Are you sure you want to permanently remove picture (ID: ${pictureid})?`
            });

            if(deleteConfirmation) {     
                // remove picture frontend
                const editPictureIndex = this.state.editPictureIndex === idx ? null : this.state.editPictureIndex;
                const editPicture = editPictureIndex ? this.state.editPicture : emptyPicture;
                let pictures = this.state.pictures;
                pictures.splice(idx, 1);

                this.setState({
                    editPictureIndex,
                    editPicture,
                    pictures,
                })

                // remove picture frontend
                const response = await api.auth.deleteBookPicture(pictureid)
                if(response && response.success){
                        // handle successfull delete   
                        this.setState({            
                            successMessage: response.message,
                            errorMessage: "",
                        })
                }else if(response && response.message){
                    this.setState({
                        successMessage: "",
                        errorMessage: response.message,
                    })
                }else{
                    this.setState({
                        successMessage: "",
                        errorMessage: "Failed to delete book picture.",
                    })  
                }
            }
        }     
    }

    // react-sortable-hoc don't drag element if i or button is clicked
    shouldCancelStart = (e) => {
        if (e.target.tagName.toLowerCase() === 'i') {
            return true; // Return true to cancel sorting
        }

        if (e.target.tagName.toLowerCase() === 'button') {
            return true; // Return true to cancel sorting
        }
    }

    // stop selecting text when dragging over other element
    onSortMove = (args, e) => {
      
        if (document.selection) {
        document.selection.empty()
        } else {
        window.getSelection().removeAllRanges()
        }
       
    }

    // helpers to clear MessageAlert messages
    removeErrorMessage = () => {
        this.setState({errorMessage: ""})
    }

    removeSuccessMessage = () => {
        this.setState({successMessage: ""})
    }

    render(){

        return (
        <BookPictureContext.Provider value={{
            createPicture: this.state.createPicture,
            
            editPicture: this.state.editPicture,
            editPictureIndex: this.state.editPictureIndex,
            
            editPictureSavable: this.state.editPictureSavable,
            setEditPicture: this.setEditPicture,
            isUpdating: this.state.isUpdating,
            isSettingMainPicture: this.state.isSettingMainPicture,
            
            isCreatingPicture: this.state.isCreatingPicture,

          
            onChangeCreate: this.onChangeCreate,
            onCreatePictureChange: this.onCreatePictureChange,
            onChangeEdit: this.onChangeEdit,
            onEditPictureChange: this.onEditPictureChange,
            setMainPicture: this.setMainPicture,
            insertPicture: this.insertPicture,
            updatePicture: this.updatePicture,
            deletePicture: this.deletePicture,
            showEditor: this.state.showEditor,
            setPicture: this.setPicture,
        }}>
            <div>
                <div className="picture-editor__wrapper" id="setting-right-panel" ref={this.pictureContainer}>
                    <div>
                        <MessageAlert type="error" message={this.state.errorMessage} onClose={this.removeErrorMessage}/>
                        <MessageAlert type="success" message={this.state.successMessage} onClose={this.removeSuccessMessage}/>
                    </div>
                    <LoadingIndicator isLoading={this.state.isLoading} loadingText="loading pictures...">
                        <SortableList 
                            axis="xy" 
                            shouldCancelStart={this.shouldCancelStart}
                            items={this.state.pictures} 
                            onSortEnd={this.onSortEnd}
                            lockToContainerEdges={true}
                            onSortMove={this.onSortMove}
                            getContainer={() => document.getElementById('setting-right-panel')}
                        />
                    </LoadingIndicator>
                    <EditPictureOverlay />
                </div>
                <button className="btn btn-primary" onClick={this.saveSortOrder} disabled={this.state.disableOrderBtn}>
                    {this.state.saveSortOrder ? "Saving...": "Save picture order"}
                </button>
                <Prompt 
                    when={this.state.isCreatingPicture || this.state.isUpdating}
                    message='An operation is still in progress, are you sure you want to leave?'
                />
            </div>
        </BookPictureContext.Provider>
        )
    }
} 


/* 
    ++++++++++++++++++++++++++++++++++++++++++++++
                    SortableList
    ++++++++++++++++++++++++++++++++++++++++++++++
*/

const SortableList = SortableContainer(({items}) =>  (
    <div className="picture-editor">
          
        <BookPictureCreateEditor />
        {items.map((value, index) => (
            <SortableItem key={`picture-${index}`} index={index} value={value}  idx={index} />
        ))}      
    </div>  
))


/* 
    ++++++++++++++++++++++++++++++++++++++++++++++
                    BookPictureItem
    ++++++++++++++++++++++++++++++++++++++++++++++
*/

const BookPictureItem = ({idx, value}) => (
    <BookPictureConsumer>
        {({isSettingMainPicture, setEditPicture, setMainPicture, deletePicture}) => (
            <div className="picture-editor-card__wrapper">
            <div className={value.mainpicture ? "picture-editor-card picture-editor-card--main-picture picture-editor-card--movable" : "picture-editor-card picture-editor-card--movable"}>
                <div className="picture-editor-card__header">
                    {value.mainpicture && "Main Picture"}    
                    <div className="picture-editor-card__delete-btn" onClick={(e) => deletePicture(e, idx)}>   
                            <i className="icon-cross" ></i>                 
                        </div>
                </div>
                <div className="picture-editor-card__body">
                    <div className="picture-editor-card__body-main">
                        {value.loading ? 
                            <div className="picture-editor-card__img-loading">
                                Loading...
                            </div>
                        : 
                        <figure>
                            <img src={value.previewUrl} alt={value.title} className="picture-editor-card__img"/>
                        </figure>
                        }
                        
                        <div className="picture-editor-card__info">
                            <hr />
                            <h5 className="ellipsis">{value.title}</h5>
                            <hr />
                            <p className="ellipsis ellipsis--xl">{value.description}</p>
                            <hr />
                        </div>
                    </div>
                    <div>
                        <button className="btn btn-primary btn-block" onClick={() => setEditPicture(idx)} disabled={value.loading}>
                            Edit
                        </button>
                        {value.mainpicture ? 
                            <button className="btn btn-primary btn-block" onClick={() => setMainPicture(-1)} disabled={isSettingMainPicture}>
                                {isSettingMainPicture ? "Saving..." : "Remove as main picture"}
                            </button>
                            :
                            <button className="btn btn-primary btn-block" onClick={() => setMainPicture(idx)} disabled={isSettingMainPicture}>                                 
                                {isSettingMainPicture ? "Saving..." : "Choose as main picture"}
                            </button>
                        }
                    </div>
                    
                </div>
            </div>
            </div>
        )}
    </BookPictureConsumer>
)


// create sortable item from BookPictureItem
const SortableItem = SortableElement(({value, idx}) => <BookPictureItem value={value} idx={idx}/>)

/* 
    ++++++++++++++++++++++++++++++++++++++++++++++
                    BookPictureCreateEditor
    ++++++++++++++++++++++++++++++++++++++++++++++
*/

const BookPictureCreateEditor = () => (
    <BookPictureConsumer>
        {({createPicture, onCreatePictureChange, onChangeCreate, insertPicture, isCreatingPicture}) => (
            <div className="picture-editor-card__wrapper">
        
            <div className="picture-editor-card">
                <div className="picture-editor-card__header">  
                    Create picture
                </div>
                <div className="picture-editor-card__body">
                    <div className="picture-editor-card__body-main">  
                    
                        <PictureEdit 
                            data={createPicture !== null ? createPicture : emptyPicture} 
                            onChange={onChangeCreate} 
                            onPictureChange={onCreatePictureChange} 
                            pictureId="picture-edit"
                        />
        
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <button className="btn btn-primary btn-block" onClick={insertPicture} disabled={isCreatingPicture}>
                            {isCreatingPicture ? "Uploading picture..." : "Create picture"}
                        </button>                     
                    </div>
                </div>
            </div>
            </div>
        )}
    </BookPictureConsumer>
)


/* 
    ++++++++++++++++++++++++++++++++++++++++++++++
                    EditPictureOverlay
    ++++++++++++++++++++++++++++++++++++++++++++++
*/

const EditPictureOverlay = () => (
    <BookPictureConsumer>
        {({isUpdating, editPictureSavable, onChangeEdit, onEditPictureChange, editPicture, updatePicture, showEditor, setEditPicture}) => (
                <div className={!showEditor ? 'edit-picture-overlay' : 'edit-picture-overlay edit-picture-overlay--active'}>
                <div className="modal-dialog">
                <div className="modal-content panel-primary">
                    <div className="modal-header panel-heading edit-picture-overlay__heading" >
                    <button type="button" className="close" onClick={() => setEditPicture(-1)}>&times;</button>
                    <h4 className="modal-title">Edit Picture: {editPicture.pictureid} (ID)</h4>
                    </div>
                    <div className="modal-body">
                    <PictureEdit 
                            data={{...editPicture}} 
                            onChange={onChangeEdit}
                            onPictureChange={onEditPictureChange}
                            pictureId="picture-edit-overlay"
                        />                  
                    </div>
                    <div className="modal-footer">           
                    <button type="button" className="btn btn-primary" onClick={updatePicture} disabled={!editPictureSavable || isUpdating}>
                        {isUpdating ? "Saving..." : "Save changes"}
                    </button>
                    <button type="button" className="btn btn-default" onClick={() => setEditPicture(-1)}>Cancel</button>
                    </div>
                </div>
                </div>            
            </div>
        )}
    </BookPictureConsumer>      
)


export default BookEditPictures