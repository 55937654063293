import React from 'react'

import PictureEdit from "./PictureEdit"
import MessageAlert from "./MessageAlert"
import BookEditInfo from "./BookEditInfo"

import api from "../api"


/*
    Book Create Section:
    main page for book creation
    uses BookEditInfo / Picture Edit as form partials
*/

class BookCreateSection extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            bookData: {
                title: "",
                description: "",
                year: "",
                keywords: "",
                categories: "",
                picture_src: null,
                picture_title: "",
                picture_description: "",
                active: false,
            },
            errorMessage: "",
            successMessage: "",
            isLoading: false
        }  
    }

    // create new book 
    onSubmit = async (e) => {
        e.preventDefault();
        this.setState({isLoading: true})
        const {
            title,
            year,        
            keywords,
            description,
            categories,
            picture_src,
            picture_title,
            picture_description,
            active
        } = this.state.bookData

        const response = await api.auth.insertBook(
            title,
            year,        
            keywords,
            description,
            categories,
            picture_src,
            picture_title,
            picture_description,
            active,
        )

        if(response && response.success){    
            this.setState({isLoading: false}, () => {
                this.props.history.push(`/book/${response.data.bookid}`)
            })           
        }else{
            this.setState({errorMessage: response.message, isLoading: false})
        }
    }

    onChange = (e) => {       
        const {name, value} = e.target
        this.setState({
            bookData: {
                ...this.state.bookData,
                [name]: value
            }
        })
    }

    onCheckboxChange = (e) => {
        this.setState({
            bookData: {
                ...this.state.bookData,
                active: e.target.checked ? 1 : 0,
            }
        })
    }

    onPictureChange = (picture) => {
        this.setState({
            bookData: {
                ...this.state.bookData,
                picture_src: picture
            }
        })
    }
    removeErrorMessage = () => this.setState({errorMessage: ""})
    removeSuccessMessage = () => this.setState({successMessage: ""})

    render(){
        return(
            <section >
            <div className="container">
                <div className="panel panel-primary">
                    <div className="panel-heading">
                        <h1 className="panel-title" >
                                Create Book
                        </h1> 
                    </div>
                    <div className="panel-body">
                        <div>
                                <MessageAlert type="error" message={this.state.errorMessage} onClose={this.removeErrorMessage}/>
                                <MessageAlert type="success" message={this.state.successMessage} onClose={this.removeSuccessMessage}/>
                        </div>
                        <form>
                            <div className="row">
                                <div className="col-md-3 col-sm-4 col-xs-12">
                                    <h5>Book picture</h5>
                                    <PictureEdit 
                                        onChange={this.onChange}
                                        onPictureChange={this.onPictureChange}
                                        namePrefix="picture"
                                        data={this.state.bookData}
                                    />
                                </div>
                                <div className="col-md-9 col-sm-8 col-xs-12">
                                    <h5>Book info</h5>

                                    <BookEditInfo 
                                        data={this.state.bookData}
                                        onChange={this.onChange}
                                        onCheckboxChange={this.onCheckboxChange}
                                    />
                                </div>
                                
                                
                            </div>     
                            <div className="row">
                                <div className="col-md-offset-3 col-md-9">
                                    <button className="btn btn-primary" onClick={this.onSubmit} disabled={this.state.isLoading}>
                                        {this.state.isLoading ? "Loading..." : "Create book"}
                                    </button>
                                </div>    
                            </div>                      
                        </form>
                    </div>
                </div>           
            </div>
            </section>
        )
    }
}

export default BookCreateSection;