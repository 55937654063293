import React from 'react'
import api from "../api"
import {PERMISSION_DISPLAY_VALUES} from "../CryptobooksConfig"
import MessageAlert from "./MessageAlert"

import SelectMultiple from "./SelectMultiple"

import CollapsiblePanel from "./CollapsiblePanel"

const emptyUserData = {
    username: "",
    email: "",
    firstname: "",
    lastname: "",
    password: "",
    password_again: "",
    permissions: "", 
}


/* 
    Create User Form
*/

class UserCreate extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            userData: emptyUserData,
            isLoading: false,
            errorMessage: "",
            successMessage: "",
        }
    }

    onChange = (e) => {
        const {name, value} = e.target
        const userData = this.state.userData
        this.setState({userData: {...userData, [name]: value} });
    }

    onSubmit = async (e) => {
        e.preventDefault();
        
        // start loading 
        this.setState({isLoading: true, errorMessage: "", successMessage: ""})
  
        // get data from state
        const {userData} = this.state
        const {password_again, password, ...otherUserData} = userData

        if(password === password_again){
            // create parameter Array
            const parameterArray = [...Object.keys(otherUserData).map(key => otherUserData[key]), password]

            // call api to create user
            const response = await api.auth.createUser(...parameterArray)
   
            // handle api response
            if(response && response.success){
                if(this.props.onCreateSuccess) 
                    this.props.onCreateSuccess({
                        userid: response.data.userid,
                        ...otherUserData
                    });
                this.setState({successMessage: response.message, userData: emptyUserData, isLoading: false})
            }else if(response){
                this.setState({errorMessage: response.message, successMessage: "", isLoading: false})
            }

        }else{
            this.setState({errorMessage: "Passwords do not match", isLoading: false})
        }
    }

    removeErrorMessage = () => this.setState({errorMessage: ""})
    removeSuccessMessage = () => this.setState({successMessage: ""})

    render(){
        const {userData} = this.state
        return(
            <CollapsiblePanel id="createUserPanel" type="danger" title="Create new user">
                <form >

                    <div className="row">
                        <div className=" col-md-12">
                            <MessageAlert type="error" message={this.state.errorMessage} onClose={this.removeErrorMessage}/>
                            <MessageAlert type="success" message={this.state.successMessage}  onClose={this.removeSuccessMessage}/>
                            
                        </div>
                    </div>

      
                    <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="firstname" className="sr-only"></label>
                            <input 
                                className="form-control" 
                                name="username" 
                                placeholder="Username" 
                                value={userData.username}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    </div>

                    <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="email" className="sr-only"></label>
                            <input 
                                className="form-control" 
                                type="email"
                                name="email" 
                                placeholder="Email" 
                                value={userData.email}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                            
                                    <label htmlFor="firstname" className="sr-only"></label>
                                    <input 
                                        className="form-control" 
                                        name="firstname" 
                                        placeholder="First name" 
                                        value={userData.firstname}
                                        onChange={this.onChange}
                                    />
                            </div>  
                        </div>


                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="lastname" className="sr-only"></label>
                                <input 
                                    className="form-control" 
                                    name="lastname" 
                                    placeholder="Last name" 
                                    value={userData.lastname}
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                    </div>
                  
                    <div className="row">
                    <div className="col-sm-12">          
                        <div className="form-group">
                            <label htmlFor="permissions" className="sr-only"></label>
                            <SelectMultiple 
                                name="permissions" 
                                placeholder="Permissions" 
                                value={userData.permissions}
                                onChange={this.onChange} 
                                options={PERMISSION_DISPLAY_VALUES}
                            />
                            
                        </div>
                    </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">                 
                                <label htmlFor="password" className="sr-only"></label>
                                <input 
                                    type="password"
                                    className="form-control" 
                                    name="password" 
                                    placeholder="Password"
                                    value={userData.password}
                                    onChange={this.onChange} 
                                />
                            </div> 
                        </div>
                        <div className="col-md-6 col-sm-12">      
                            <div className="form-group">
                                <label htmlFor="password_again" className="sr-only"></label>
                                <input 
                                    type="password"
                                    className="form-control" 
                                    name="password_again" 
                                    placeholder="Confirm password" 
                                    value={userData.password_again}
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <button className="btn btn-primary " onClick={this.onSubmit} disabled={this.state.isLoading}>
                                {this.state.isLoading ? "Creating user..." : "Create user"}
                            </button>  
                        </div>   
                    </div>                              
                </form>
            </CollapsiblePanel>
        )
    }
}


export default UserCreate