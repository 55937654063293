import React from 'react'

import {Link} from "react-router-dom"
import AuthorEdit from "./AuthorEdit"
import LoadingIndicator from "./LoadIndicator"
import {userHasOnePermission, PERMISSION_ROLES} from "../CryptobooksConfig"
import confirmDelete from "./DialogConfirmDelete"
import api from "../api"

/*
    AuthorEditSection
    --------------------------------
    component displayed to edit the Author
    fetches author data and displays it via the form component AuthorEdit 
*/

class AuthorEditSection extends React.Component{

    constructor(props){
        super(props);

        let isAdmin = false;
        const {user} = this.props
        if(userHasOnePermission(user, PERMISSION_ROLES.admin)){
            isAdmin = true
        }

        this.state = {
            author: null,
            isLoading: false,
            isAdmin,
            isDeleting: false,
        }

        this.authorid = this.props.match ? this.props.match.params.authorId : null;
    }

    componentDidMount(){
        this.fetchAuthorData(this.authorid);
    }

    
    componentDidUpdate(prevProps){
        // refetch author data on url match authorid change
        if(prevProps.match.params.authorId !== this.props.match.params.authorId){
            this.authorid = parseInt(this.props.match.params.authorId)
            this.fetchUser(this.authorid);       
        }
    }

    fetchAuthorData = async (authorId) => {
        if (authorId) {
            this.setState({ isLoading: true });

            const author = await api.auth.getAuthor(authorId);
  
            // stop futher actions if author does not exist
            if(!author){
                this.setState({ isLoading: false });
                return;
            }

            // check where user has permissions to edit author
            if(this.props.user.userid !== author.userid  && !userHasOnePermission(this.props.user, PERMISSION_ROLES.admin)){
                this.setState({ isLoading: false });
                return;
            }
    
            // load picture data and file
            let pictureData = {
                picture_src: null,
                picture_title: "",
                picture_description: "",
            };

            
            if(author.pictureid && author.pictureid !== 0 && author.pictureid !== 1){
         
                const imageInfo = await api.open.getPictureInfo(author.pictureid);
                pictureData = {
                    id: author.pictureid,              
                    picture_title: imageInfo && imageInfo.name ? imageInfo.name : "",
                    picture_description:  imageInfo && imageInfo.description ? imageInfo.description : "",
                }

                if(imageInfo && imageInfo.filename){
                    const img = await api.open.getPictureFile(author.pictureid, true);
                    pictureData = {
                        ...pictureData,
                        picture_src: img
                    }               
                }          
            }


            this.setState({ 
                author: {
                    ...author,
                    ...pictureData,
                }, 
                isLoading: false,
            })
        }
    }

    handleAuthorDelete = async (e) => {
        e.preventDefault();
        
        
        const {author} = this.state
        if(author){

            // ask for delete confirmation
            const deleteConfirmation = await confirmDelete.show({
                text: `Are you sure you want to permanently remove author "${author.firstname} ${author.lastname}" (ID: ${author.authorid})?`
            });

            if(deleteConfirmation) {
                this.setState({isDeleting: true, isLoading: true});
                // call delete in api
                const response = await api.auth.deleteAuthor(author.authorid)

                if(response.success && response.data){
                    this.setState({isDeleting: false, isLoading: false}, () => {
                        this.props.history.push({
                            pathname: "/deleted",
                            state: {
                                title: `Author (ID: ${author.authorid}) has been successfully deleted.`
                            }
                        })
                    });         
                }else{
                    this.setState({isDeleting: false, isLoading: false}, () => {
                        this.props.history.push({
                            pathname: "/deleted",
                            state: {
                                title: `Failed to delete author (ID: ${author.authorid}). ${response.message}`
                            }
                        })
                    })
                }
            }
        }     
    }

    render(){
        const {author} = this.state

        return (
            <section >
            <div className="container" style={{padding: "30px"}}>
               
                <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 page-header" style={{marginTop: "10px", display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between"}}>
                    <h1 style={{fontSize: "1.8em"}}>
                        Edit Author:&nbsp;
                        {this.state.author && 
                            <Link to={`/author/${author.authorid}`}>{author.firstname} {author.lastname}</Link>
                        }                  
                    </h1>
                    {this.state.author && this.state.isAdmin &&
                        <div>
                            <button className="btn btn-danger" onClick={this.handleAuthorDelete} disabled={this.isDeleting}>
                                {this.state.isDeleting ? "Deleting..." : "Delete Author"}
                            </button>
                        </div>
                    }
                    
                </div>
                    {this.state.isLoading || this.state.author ?
                        <LoadingIndicator isLoading={this.state.isLoading} loadingText="loading author data...">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">            
                                {this.state.author && !this.state.author.active && (
                                        
                                        <div className="alert alert-danger" role="alert" style={{margin: "10px 0 50px 0", paddingRight: "100px"}}>
                                            Author cannot be viewed by normal users yet. An administrator is needed to activate this
                                            author entry so it becomes visible for everyone.
                                        </div>

                                    )}
                                <AuthorEdit author={this.state.author}/>
                                </div>
                            </div>
                              
                        </LoadingIndicator>  
                        :
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12"> 
                            No author found or access denied
                        </div> 
                    }                 
                </div>
            </div>
            </section>
        )
    }
}

export default AuthorEditSection;
