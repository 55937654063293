import React from 'react'
import api from "../api"
import LoadingIndicator from "./LoadIndicator"
import {Link} from "react-router-dom"
import DynamicTable, {DynamicTableCol, intialTableState} from "./DynamicTable"

import "./UserContributionsSection.css"

class UserContributionSection extends React.Component{


    constructor(props){
        super(props)

        this.state = {
            contributions: [],
            user: null,
            isLoading: false,
            searchType: "",
            searchText: "",
            tableState: {
                ...intialTableState,
                orderDir: "DESC"
            }
        } 
    }

    componentDidMount(){
        this.fetchContributions(this.props.match.params.userid);
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.match.params.userid !== this.props.match.params.userid){
            this.fetchContributions(this.props.match.params.userid);
        }

        if(prevState.searchType !== this.state.searchType){

            this.fetchContributions(this.props.match.params.userid)
        }
    }

    fetchContributions = async (userid) => {
        this.setState({isLoading: true})
        const {orderBy, orderDir, page, } = this.state.tableState;
        const {searchType} = this.state

        const contributions = await api.auth.getContributions(userid, orderBy, orderDir, page, searchType);
        const response = await api.auth.getUser(userid);

        if(response && response.success){
           

            this.setState({
                contributions: contributions.results, 
                user: response.data,
                isLoading: false, 
                totalContributions: contributions.totalContributions,
                tableState: {
                    ...this.state.tableState, 
                    totalPages: contributions.totalPages
                }
            })
        }else{
            this.setState({isLoading: false})
        }
    }

    onTableUpdate = (newTableState) => {
        // update table state and refetch table data
        this.setState({tableState: newTableState}, () => {
            this.fetchContributions(this.props.match.params.userid);
        })
    }

    onChange = (e) => {
        const {value, name} = e.target
        this.setState({[name]: value})
    }


    render(){
        return (
            <section className="tg-sectionspace tg-haslayout">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="tg-sectionhead">
                                <h2>
                                    {this.state.user ? 
                                        <React.Fragment>
                                            <Link to={`/users/${this.props.match.params.userid}/edit`}>{this.state.user.username}'s</Link>
                                            <div style={{display: "inline"}}> cryptobooks contributions</div>
                                        </React.Fragment>
                                    : "Cryptobooks contributions"}
                          

                                </h2>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div style={{margin: "10px 0", display: "flex", alignItems: "center"}}>
                                <select style={{marginRight: "10px"}} onChange={this.onChange} name="searchType" value={this.state.searchType}>
                                    <option value="">All</option>
                                    <option value="books">Book</option>
                                    <option value="editions">Edition</option>
                                    <option value="authors">Author</option>
                                </select>
                                contributions found: {this.state.totalContributions ? this.state.totalContributions : 0}
                              
                            </div>
             
                            <LoadingIndicator isLoading={this.state.isLoading} loadingText={"loading contributions..."}>
                                <DynamicTable
                                    data={this.state.contributions}
                                    tableState={this.state.tableState}
                                    onTableStateUpdate={this.onTableUpdate}
                                    renderRowItem={(props) => (
                                        <UserContributionRecord key={`contrib-item-${props.index}`} {...props}/>
                                    )}
                                    emptyMessage="No contributions yet."
                                    pagination={true}
                                >
                                    <DynamicTableCol title="Type" dataRef="type" sortable/>
                                    <DynamicTableCol title="Name" dataRef="text" sortable/>
                                    <DynamicTableCol title="Active" dataRef="active" sortable/>
                                    <DynamicTableCol title="Last update" dataRef="last_update_date" sortable/>
                                    <DynamicTableCol title="Link"/>
                                </DynamicTable>                   
                            </LoadingIndicator>                              
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}


const UserContributionRecord = ({item: contribution, index}) => {
    const {id, text, type, active, last_update_date} = contribution

    let mainClasses = active ? "user-contribution dynamic-table-row" : "user-contribution dynamic-table-row dynamic-table-row--inactive" ;
    let detailsLink = null;


    switch(type){
        case 'book': 
            mainClasses += " user-contribution--book"
            detailsLink = `/book/${id}`;
            break;
        case 'author':
            mainClasses += " user-contribution--author"
            detailsLink = `/author/${id}`;
            break;
        case 'edition':
            mainClasses += " user-contribution--edition"
            detailsLink = `/book/${id}`;
            break;
        default:
            break;
    }

    return (
        <tr className={mainClasses}>
           <th className="dynamic-table-col dynamic-table-col--sm user-contribution__type">{type}</th>
            <td className="dynamic-table-col"><Link className="dynamic-table-col__link" to={detailsLink}>{text}</Link></td>
            <td className="dynamic-table-col dynamic-table-col--sm">{active ? "active" : "inactive"}</td>
            <td className="dynamic-table-col dynamic-table-col--sm">{last_update_date}</td>
            <td className="dynamic-table-col dynamic-table-col--sm">
                <Link to={`${detailsLink}/edit`} className="btn btn-primary">
                    Edit
                </Link>    
            </td>
        </tr>
    )
}





export default UserContributionSection;