import React from 'react'
import MessageAlert from "./MessageAlert"
import UserRecordItem from "./UserRecordItem"
import api from "../api"

// https://allenfang.github.io/react-bootstrap-table/start.html

class UserRecordList extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            successMessage: "",
            errorMessage: "",
            users: this.props.users || [],
            
        }
    }

   

    componentDidUpdate(prevProps){
        if(this.props.users !== prevProps.users){
            this.setState({users: this.props.users})
        }
    }

        
    onDelete = async (userid) => {
        // remove user frontend
        let removed = null
        const newUsers = this.state.users.filter((user) => {
            if(user.userid !== userid) return user;
            removed = user;
        })
        this.setState({users: newUsers})
        
        const response = await api.auth.deleteUser(userid)
      
        if(response && response.success){
            // on successfull delete  remove user from user state
            this.setState({successMessage: `User '${removed.username}' with ID: ${removed.userid} was successfully removed!`, errorMessage: ""})
        }else{
            // on delte error display error message
            this.setState({errorMessage: response.message, successMessage: ""})
        }
    }

    // helpers for the Message alert Components
    removeErrorMessage = () => this.setState({errorMessage: ""})
    removeSuccessMessage = () => this.setState({successMessage: ""})

    render(){
        return(
        <div>
            
            <MessageAlert type="error" message={this.state.errorMessage} onClose={this.removeErrorMessage}/>
            <MessageAlert type="success" message={this.state.successMessage} onClose={this.removeSuccessMessage}/>
 
            <div className="table-responsive">
                <table className="table table-bordered table-striped table-sm">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Permissions</th>
                        <th style={{width: "1px", whiteSpace: "nowrap"}}>Edit</th>
                        <th style={{width: "1px", whiteSpace: "nowrap"}}>Delete</th>                  
                    </tr>
                </thead>
                
                    <tbody>
                    {this.state.users && this.state.users.length > 0 ? 
                        this.state.users.map((user) => (
                            <UserRecordItem key={`user-id-${user.userid}`} user={user} onDelete={this.onDelete}/>
                        ))
                        :
                        (
                            <tr><td colSpan="6" className="text-center">No users to display</td></tr>
                        )
                    }
                    </tbody>
                </table>
            </div>
        </div>
        )
    }
}

export default UserRecordList
