import {AUTH_API_URL, authFetch, buildApiUrl, authFetchUploadFile} from "./index"

/************************* USER RELATED API CALLS ***********************/

// create user
async function createUser(username, email, firstname, lastname, permissions, password){
    const url = buildApiUrl(AUTH_API_URL, "insert_user");
    const response = await authFetch(url, "POST", {
        username,
        email,
        firstname,
        lastname,
        permissions,
        password
    })
    return response;
}

// update user
async function updateUser(userid, username, email, firstname, lastname){
    const url = buildApiUrl(AUTH_API_URL, "update_user");
    const response = await authFetch(url, "POST", {
        userid,
        username, 
        email, 
        firstname,
        lastname
    })
    return response;
}

// update user admin
async function updateUserAdmin(userid, permissions, password){
    const url = buildApiUrl(AUTH_API_URL, "update_user_admin");
    const response = await authFetch(url, "POST", {
        userid,
        permissions,
        password
    })
    return response;
}

// change user password
async function changeUserPassword(userid, oldpassword, newpassword){
    const url = buildApiUrl(AUTH_API_URL, "change_password");
    const response = await authFetch(url, "POST", {
        userid,
        oldpassword,
        newpassword
    })
    return response
}

// delete user
async function deleteUser(userid){
    const url = buildApiUrl(AUTH_API_URL, "delete_user");
    const response = await authFetch(url, "POST", {userid})
    return response;
}

// login
async function login(username, password){
    const url = buildApiUrl(AUTH_API_URL, "login")
    const response = await authFetch(url, "POST", {username, password});
    return response;
}

// logout
async function logout(){
    const url = buildApiUrl(AUTH_API_URL, "logout")
    const response = await authFetch(url, "GET");
    return response;
}

// get users (gets all users)
const getUsers = async () => {
    const url = buildApiUrl(AUTH_API_URL, "get_users")
    const response = await authFetch(url, "GET");
    return response;
}

// get user 
const getUser = async (userid) => {
    const url = buildApiUrl(AUTH_API_URL, "get_user", {userid})
    const response = await authFetch(url, "GET");
    return response;
}

// check user login status
const checkUserLoginStatus = async () => {
    const url = buildApiUrl(AUTH_API_URL, "check_user_login_status");
    const response = await authFetch(url, "GET");

    return response
}


/************************* AUTHOR RELATED API ACTIONSS ***********************/
const insertAuthor = async ( 
    firstname, 
    lastname, 
    pseudonyms, 
    country, 
    born, 
    died, 
    description, 
    picture, 
    picture_title, 
    picture_description,
    active,
    ) => {
    const url = buildApiUrl(AUTH_API_URL, "insert_author");
    const response = await authFetchUploadFile(url, "POST", {
        firstname, 
        lastname, 
        pseudonyms, 
        country, 
        born, 
        died, 
        description, 
        picture, 
        picture_title, 
        picture_description,
        active
    })
    return response;
}

const updateAuthor = async (
    authorid,
    firstname, 
    lastname, 
    pseudonyms, 
    country, 
    born, 
    died, 
    description, 
    picture, 
    picture_title, 
    picture_description,
    keep_old_picture,
    active,   
) => {
    const url = buildApiUrl(AUTH_API_URL, "update_author");
    const response = await authFetchUploadFile(url, "POST", {
        authorid, 
        firstname, 
        lastname, 
        pseudonyms, 
        country, 
        born, 
        died, 
        description, 
        picture, 
        picture_title, 
        picture_description,
        keep_old_picture,
        active,
    })
    return response;
}

const deleteAuthor = async (authorid) => {
    const url = buildApiUrl(AUTH_API_URL, "delete_author");
    const response = await authFetch(url, "POST", {
        authorid
    })
    return response;
}


/* BOOK RELATED API CALLS */

const insertBook = async (
    title,
    year,
    keywords,
    description,
    categories,
    picture,
    picture_title,
    picture_description,
    active
) => {
    const url = buildApiUrl(AUTH_API_URL, "insert_book");
    const response = await authFetchUploadFile(url, "POST", {
        title,
        year,
        keywords,
        description,
        categories,
        picture,
        picture_title,
        picture_description,
        active
    });
    return response;
}

const updateBook = async (
    bookid,
    title,
    year,
    keywords,
    description,
    categories,
    active
) => {
    const url = buildApiUrl(AUTH_API_URL, "update_book");
    const response = await authFetchUploadFile(url, "POST", {
        bookid,
        title,
        year,
        keywords,
        description,
        categories,
        active,
    });
    return response;
}


const deleteBook = async (bookid) => {
    const url = buildApiUrl(AUTH_API_URL, "delete_book");
    const response = await authFetchUploadFile(url, "POST", {
        bookid
    });
    return response;
}



/* EDTIION RELATED API CALLS */

const insertEdition = async (
    bookid,
    number,
    revisedtitle,
    year,
    pages,
    language,
    links,
    keywords,
    printtype,
    publisher,
    addendum, 
    author_display_name,
    active,
) => {
    const url = buildApiUrl(AUTH_API_URL, "insert_edition");

    const response = await authFetch(url, "POST", {
        bookid,
        number,
        revisedtitle,
        year,
        pages,
        language,
        links,
        keywords,
        printtype,
        publisher,
        addendum, 
        author_display_name,
        active,
    });
    return response;
}

const updateEdition = async (
    editionid,
    bookid,
    number,
    revisedtitle,
    year,
    pages,
    language,
    links,
    keywords,
    printtype,
    publisher,
    addendum, 
    author_display_name,
    active,
) => {
    const url = buildApiUrl(AUTH_API_URL, "update_edition");
    const response = await authFetch(url, "POST", {
        editionid,
        bookid,
        number,
        revisedtitle,
        year,
        pages,
        language,
        links,
        keywords,
        printtype,
        publisher,
        addendum, 
        author_display_name,
        active,
    });
    return response;
}


const getEditions = async (bookid, active=false) => {
    const url = buildApiUrl(AUTH_API_URL, "get_editions", {
        bookid,
        active
    });
    const response = await authFetch(url, "GET")
    return response;
}


const deleteEdition = async (editionid) => {
    const url = buildApiUrl(AUTH_API_URL, "delete_edition");
    const response = await authFetch(url, "POST", {
        editionid
    })
    return response;
}

const getBook = async (bookid, active=false) => {
    const url = buildApiUrl(AUTH_API_URL, "get_book",{
        bookid,
        active
    });
    const response = await authFetch(url, "GET");
    return response;
}

const getAuthor = async (authorid) => {
    const url = buildApiUrl(AUTH_API_URL, "get_author",{
        authorid,
    });
    const response = await authFetch(url, "GET");
    return response;
}


/*

    BOOK PICTURE RELATED API CALLS

*/

const insertBookPicture = async (bookid, picture, picture_title, picture_description) => {
    const url = buildApiUrl(AUTH_API_URL, "insert_book_picture");
    const response = await authFetchUploadFile(url, "POST", {
        bookid,
        picture,
        picture_title,
        picture_description
    })
    return response;
}

const updateBookPicture = async (bookid, pictureid, picture, picture_title, picture_description, keep_old_picture=false) => {
    const url = buildApiUrl(AUTH_API_URL, "update_book_picture");
    const response = await authFetchUploadFile(url, "POST", {
        bookid,
        pictureid,
        picture,
        picture_title,
        picture_description,
        keep_old_picture
    })
    return response;
}

const deleteBookPicture = async (pictureid) => {
    const url = buildApiUrl(AUTH_API_URL, "delete_book_picture");
    const response = await authFetch(url, "POST", {
        pictureid,
    })
    return response;
}

const setBookMainPicture = async (bookid, pictureid) => {
    const url = buildApiUrl(AUTH_API_URL, "set_book_main_picture");
    const response = await authFetch(url, "POST", {
        bookid,
        pictureid,
    })
    return response;
}

const updateBookPictureOrder = async (bookid, ordered_pictures) => {
    const url = buildApiUrl(AUTH_API_URL, "update_book_picture_order");
    const response = await authFetch(url, "POST", {
        bookid,
        ordered_pictures,
    })
    return response;
}


const getAuthorBooks = async (authorid, active=false) => {
    const url = buildApiUrl(AUTH_API_URL, "get_author_books", {
        authorid
    });
    const response = await authFetch(url, "GET");
    return response;
}

const getBookAuthors = async (bookid, active=false) => {
    const url = buildApiUrl(AUTH_API_URL, "get_book_authors", {
        bookid,
        active
    });
    const response = await authFetch(url, "GET");
    return response;
}

const searchAuthors = async (search_text) => {
    const url = buildApiUrl(AUTH_API_URL, "search_authors", {
        search_text
    });
    const response = await authFetch(url, "GET");
    return response;
}


const addBookAuthor = async (bookid, authorid) => {
    const url = buildApiUrl(AUTH_API_URL, "add_book_author");
    const response = await authFetch(url, "POST", {
        bookid,
        authorid
    });
    return response;
}

const removeBookAuthor = async (bookid, authorid) => {
    const url = buildApiUrl(AUTH_API_URL, "remove_book_author");
    const response = await authFetch(url, "POST", {
        bookid,
        authorid
    });
    return response;
}


const getContributions = async (userid, order_by, order_dir, page, search_type) => {
    const url = buildApiUrl(AUTH_API_URL, "get_contributions", {
        userid,
        order_by,
        order_dir,
        page,
        search_type
    });
    const response = await authFetch(url, "GET");
    return response;  
}


const getInactive = async (order_by="", order_dir="", page=1, search_type="") => {
    const url = buildApiUrl(AUTH_API_URL, "get_inactive", {
        order_by,
        order_dir,
        page,
        search_type
    });
    const response = await authFetch(url, "GET");
    return response;  
}


const insertCategory = async (name, description) => {
    const url = buildApiUrl(AUTH_API_URL, "insert_category");
    const response = await authFetch(url, "POST", {
        name,
        description
    });
    return response; 
}

const updateCategory = async (categoryid, name, description) => {
    const url = buildApiUrl(AUTH_API_URL, "update_category");
    const response = await authFetch(url, "POST", {
        categoryid,
        name,
        description
    });
    return response; 
}

const deleteCategory = async (categoryid) => {
    const url = buildApiUrl(AUTH_API_URL, "delete_category");
    const response = await authFetch(url, "POST", {
        categoryid
    });
    return response; 
}


export default {
    // user exports
    getUsers,
    getUser,
    login,
    logout,
    checkUserLoginStatus,
    createUser,
    updateUser,
    updateUserAdmin,
    changeUserPassword,
    deleteUser,

    // author exports
    insertAuthor,
    updateAuthor,
    deleteAuthor,

    // book exports
    insertBook,
    updateBook,
    deleteBook,

    // edition exports
    insertEdition,
    deleteEdition,
    updateEdition,

    // category exports 
    insertCategory,
    updateCategory,
    deleteCategory,

    // book picture exports
    insertBookPicture,
    updateBookPicture,
    deleteBookPicture,
    setBookMainPicture,
    updateBookPictureOrder,

    // new get api functions
    getEditions,
    getBook,
    getBookAuthors,
    getAuthorBooks,
    searchAuthors,
    getContributions,

    getAuthor,
    getInactive,

    addBookAuthor,
    removeBookAuthor,

}