import React from 'react';
import "./ImprintSection.css"

function ImprintSection() {
    return (
        <section className="tg-sectionspace tg-haslayout">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="tg-sectionhead">
                            <h2>
                                <span>Imprint</span>
                                CryptoBooks
                            </h2>
                        </div>
                        <div className="tg-description">
                            <table className="ImprintTable">
                                <tbody>
                                    <tr>
                                        <td>LEGAL CONTACT:</td>
                                        <td>
                                            Tobias Schrödel <br />
                                            Ramsauer Rednermanagement <br />
                                            Hauptstr. 29, 86925 Fuchstal <br />
                                            Germany <br />
                                            <img src="images/mailCryptobooks.png" alt="Mail address of CryptoBooks." />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>CONTENT RESPONSIBLE:</td>
                                        <td>
                                            Nils Kopal <br />
                                            <img src="images/mailNils.png" alt="Mail address of Nils Kopal." /> <br />
                                            Tobias Schrödel <br />
                                            <img src="images/mailTobias.png" alt="Mail address of Tobias Schrödel." />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>BACKEND LOGIC MySQL &amp; JSON API:</td>
                                        <td>
                                            Nils Kopal <br />
                                            <img src="images/mailNils.png" alt="Mail address of Nils Kopal." /> <br />
                                            Elias Schmittel <br />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>FRONTEND LOGIC (REACT):</td>
                                        <td>
                                            Sven Rech <br />
                                            Elias Schmittel
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>PAGE DESIGN:</td>
                                        <td>
                                            <a href="https://subsolardesigns.com">https://subsolardesigns.com</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p>
                            <img src="images/decrypt-logo.png" width="200" alt="Logo of the DECRYPT project" /> <br />
                                The DECRYPT project supports this online cryptography bibliography. <br />
                                This work has been supported by the Swedish Research Council, grant 2018-06074, DECRYPT - Decryption of historical manuscripts. 
                            </p>
                            <p>
                                CryptoBooks is a non-commercial project. It is a non-profit project and is based mostly on voluntary work. The CryptoBook project aims to aware in all publications the copyright and performance protection rights of content used, to use its own content, especially to use graphics, sound documents, video sequences and texts or to use license-free content, especially graphics, sound documents, video sequences and texts. All trademarks used on this website possibly protected by third parties or copyrighted works are subject without any limits to the provision of the applicable copyright and intellectual property law or trademark law and the ownership rights of the registered owners. The mere use is not the conclusion that trademarks or contents are not protected by third party rights. The copyright and intellectual property rights for published and internally generated content remains with CryptoBooks and the relevant picture agencies / photographers mentioned. Any reproduction, modification, distribution, translation, storage, processing or reproduction or other use of such content, especially graphics, audio, video, text and photographs is not permitted.
                            </p>
                            <h3>Disclaimer</h3>
                            <p>
                                Referring to §7 par. 1 of the German Tele-Media Act (TMG), as a service provider we are responsible as a service provider for our own content on this website. Beside the information we provide firsthand, this website contains links to third party websites. Before setting the links, we evaluated the content of these websites. However, as to §7 par. 2 of the TMG, we are not obligated to continous monitoring of that content. As soon as we find out that one of the websites we link to contains unlawful data, we will remove the associated link.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ImprintSection;