import React, { Fragment } from 'react';
import './QuickSearchBar.css';
import api from '../api';
import { FormGroup, Label, Input, Col } from 'reactstrap';

class QuickSearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.suggestionClicked = this.suggestionClicked.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showAdvancedSearch = this.showAdvancedSearch.bind(this);
        this.hideAdvancedSearch = this.hideAdvancedSearch.bind(this);
        this.handleDateFilterChange = this.handleDateFilterChange.bind(this);

        const currentYear = new Date().getFullYear();
        this.state = {
            searchInput: "",
            suggestions: [],
            useAdvancedSearch: false,
            useYearFromFilter: false,
            useYearToFilter: false,
            yearFromFilter: currentYear - 500,
            yearToFilter: currentYear
        };
    }

    async handleSearchChange(event) {
        this.setState({ searchInput: event.target.value });
        const suggestions = await api.open.performQuickSearch(event.target.value.trim());
        this.setState({ suggestions: suggestions });
    }

    suggestionClicked(suggestion) {
        this.setState({ searchInput: suggestion, suggestions: [] });
        this.props.onSearchRequestHandler(suggestion);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ suggestions: [] });

        if (this.useYearFilter()) {
            this.props.onSearchRequestHandler(
                this.state.searchInput || null,
                this.state.useYearFromFilter ? this.state.yearFromFilter : null,
                this.state.useYearToFilter ? this.state.yearToFilter : null);
        } else if (this.state.searchInput) {
            this.props.onSearchRequestHandler(this.state.searchInput);
        }
    }

    showAdvancedSearch(event) {
        event.preventDefault();
        this.setState({ useAdvancedSearch: true });
    }

    hideAdvancedSearch(event) {
        event.preventDefault();
        this.setState({ useAdvancedSearch: false });
    }

    useYearFilter() {
        return this.state.useAdvancedSearch &&
            (this.state.useYearFromFilter || this.state.useYearToFilter);
    }

    render() {
        let id = 0;
        const allowSearch = !(!this.state.searchInput && !this.useYearFilter());
        return (
            <div className="tg-searchbox">
                <form className="tg-formtheme tg-formsearch" style={this.state.useAdvancedSearch ? {background: "#fff", border: "3px solid #fff",  boxShadow: "0 .2rem .5rem rgba(0,0,0,.15)", paddingBottom: "10px" } : {}} onSubmit={this.handleSubmit}>
                    <fieldset>
                        <div className="autocomplete" style={{ width: "80%" }}>
                            <input name="search" type="text" className="autocompleteInput typeahead form-control"
                                value={this.state.searchInput}
                                autoComplete="off" onChange={this.handleSearchChange}
                                placeholder="Search by title, author, keyword, ..." />
                            <div className="autocomplete-items">
                                {this.state.suggestions.map(s =>
                                    <div key={id++} onClick={() => this.suggestionClicked(s)}>{s}</div>)}
                            </div>
                        </div>
                        <button type="submit" disabled={!allowSearch}>
                            <i className="icon-magnifier"></i>
                        </button>
                    </fieldset>
                    <div>
                        {this.getAdvancedSearch(this.state.useAdvancedSearch)}
                    </div>
                </form>
            </div>
        );
    }

    handleDateFilterChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [target.name]: value });
    }

    getAdvancedSearch(useAdvancedSearch) {
        if (!useAdvancedSearch) {
            return (
                <div>
                    <a href="#" onClick={this.showAdvancedSearch}>+  Advanced search</a>
                </div>
            );
        }

        return (
            <div>
                <FormGroup row>
                    <Label for="yearFromFilter" sm={4}>Year from:</Label>
                    <Col sm={4}>
                        <Input type="number" id="yearFromFilter" name="yearFromFilter"
                            value={this.state.yearFromFilter}
                            disabled={!this.state.useYearFromFilter}
                            onChange={this.handleDateFilterChange}
                            max={this.state.useYearToFilter ? this.state.yearToFilter : null} />

                    </Col>
                    <Label check sm={4}>
                        <Input type="checkbox" name="useYearFromFilter"
                            checked={this.state.useYearFromFilter}
                            onChange={this.handleDateFilterChange} /> Use filter</Label>
                </FormGroup>
                <FormGroup row>
                    <Label for="yearToFilter" sm={4}>Year to:</Label>
                    <Col sm={4}>
                        <Input type="number" id="yearToFilter" name="yearToFilter"
                            value={this.state.yearToFilter}
                            disabled={!this.state.useYearToFilter}
                            onChange={this.handleDateFilterChange}
                            min={this.state.useYearFromFilter ? this.state.yearFromFilter : null} />
                    </Col>
                    <Label check sm={4}>
                        <Input type="checkbox" name="useYearToFilter"
                            checked={this.state.useYearToFilter}
                            onChange={this.handleDateFilterChange} /> Use filter</Label>
                </FormGroup>
                <div>
                    <a href="#" onClick={this.hideAdvancedSearch}>-  Advanced search</a>
                </div>
            </div>
        );
    }
}

export default QuickSearchBar;