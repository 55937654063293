/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown/with-html";
import api from '../api';
import AuthorDetails from './AuthorDetails.js';
import EditionsViewer from './EditionsViewer.js';
import BookCoverPicture from './BookCoverPicture.js';
import ModalPictureDialog from './ModalPictureDialog.js'
import LoadIndicator from './LoadIndicator';
import {userHasOnePermission, PERMISSION_ROLES} from "../CryptobooksConfig"
import {withAuthContext} from "./AuthContext"

class BookDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            bookInfos: null, 
            bookPictures: null, 
            loading: false,
            canEdit: false,
        };
    }

    componentDidMount() {
        this.fetchBookDetails(this.props.bookId);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.bookId !== prevProps.bookId) {
            this.fetchBookDetails(this.props.bookId);
        }

        if(this.state.bookInfos && this.state.bookInfos.owner){
            const owner = this.state.bookInfos.owner

            if(!prevState.bookInfos || prevState.bookInfos.owner !== owner){
                // check if user owner or admin
                const user = this.props.authContext.user;

                if(user && (userHasOnePermission(user, PERMISSION_ROLES.admin) || user.userid === owner)){
                    this.setState({canEdit: true})
                }else{
                    this.setState({canEdit: false})
                }
                
            }
          
        }
    }

    async fetchBookDetails(bookId) {
        if (bookId) {
       
            this.setState({ bookInfos: null, bookPictures: null, loading: true });
            let bookInfos = null;
            let bookPictures = null;

            const response = await api.auth.getBook(bookId)       
            if(response && response.success){
                bookInfos = response.data;
                if(bookInfos){
                    bookPictures = await api.open.requestBookPictures(bookId);  
                }
            }

            this.setState({ bookInfos, bookPictures, loading: false })   
        }
    }


    render() {
        if (this.state.loading || this.state.bookInfos ) {
            let bookInfos = this.state.bookInfos;
            if (!bookInfos) {
                bookInfos = {
                    pictureid: null,
                    title: "-",
                    categories: [],
                    authorid: null,
                    firstname: "-",
                    lastname: "-",
                    year: "-",
                    description: "-",
                    bookId: null
                }
            }
            return (
                <div>
                    <LoadIndicator isLoading={this.state.loading} loadingText="Loading book details...">
                        <section className="tg-bglight tg-haslayout">
                        <div className="container">
         
                
                            <div className="row">
                                <div className="tg-featureditm" style={{position: "relative"}}>

                                    {!bookInfos.active && (
       
                                            <div className="alert alert-danger" role="alert" style={{margin: "10px 0 50px 0", paddingRight: "100px"}}>
                                                Book cannot be viewed by normal users yet. An administrator is needed to activate this
                                                book entry so it becomes visible for everyone.
                                            </div>
                         
                                    )}
                          
                                    {this.state.canEdit && 
                                        <Link to={`/book/${bookInfos.bookid}/edit`} className="btn btn-primary" style={{position: "absolute", right: "10px", top: "20px", zIndex: "1"}}>
                                            Edit book
                                        </Link>
                                    }
                                    


                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <figure>
                                            <BookCoverPicture bookInfos={bookInfos} withDialog={true} width={350} />
                                        </figure>
                                    </div>
                                    <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                        <div className="tg-featureditmcontent">
                                            <div className="tg-themetagbox">
                                                {bookInfos.categoryNames && bookInfos.categoryNames.map(category =>
                                                    <span className="tg-themetag" key={category} style={{ margin: 5 }}>{category}</span>
                                                )}
                                            </div>
                                            <div className="tg-booktitle">
                                                <h3>{bookInfos.title}</h3>
                                            </div>

                                            <span className="tg-bookwriter">
                                                <Link to={`/author/${bookInfos.authorid}`}>
                                                    {bookInfos.firstname} {bookInfos.lastname}
                                                </Link>
                                            </span>
                                            <span className="tg-bookyear">
                                                First edition: {bookInfos.year}
                                            </span>
                                        </div>
                                    </div>             
                                </div>
                            </div>
     

                            <section className="tg-sectionspace tg-haslayout" style={{marginTop: "20px"}}>
                                <div className="container">
                                    <div className="row">
                                        <div className="tg-newrelease">
                                       
                                                <div className="tg-sectionhead">
                                                    <h2 style={{lineHeight: "38px"}}>
                                                        <span>About the book</span>
                                                        {bookInfos.title}
                                                    </h2>
                                                </div>
                                                <div>
                                                    <ReactMarkdown
                                                        source={bookInfos.description ? bookInfos.description : "No description yet."}
                                                        escapeHtml={false} />
                                                </div>
                                
                                        </div>
                                    </div>
                                </div>
                            </section>


                            {bookInfos.authorids && bookInfos.authorids.length > 0 ? 
                                bookInfos.authorids.map(
                                    authorid => <AuthorDetails key={authorid} authorId={authorid} /> 
                                )
                            : 
                                <section className="tg-sectionspace tg-haslayout">
                                <div className="container">                
                                    <div className="row">                 
                                        <div className="tg-sectionhead">
                                            <h2>                       
                                                Authors
                                            </h2>
                                        </div>
                                        <p>No authors associated with this book yet.</p>   
                                    </div>
                                </div>
                                </section>
                            }         

                          
                            <section className="tg-sectionspace tg-haslayout" style={{marginTop: "20px"}}>
                                <div className="container">
                                    <div className="row">
                                        <div className="tg-newrelease">
                                       
                                                <div className="tg-sectionhead" style={{marginBottom: "-30px"}}>
                                                    <h2>                       
                                                        Book editions
                                                    </h2>
                                                </div>
                                                <div>
                                                    <EditionsViewer bookId={this.props.bookId} bookTitle={bookInfos.title} />
                                                </div>
                                
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="tg-sectionspace tg-haslayout" style={{marginTop: "20px"}}>
                                <div className="container">
                                    <div className="row">
                                        <div className="tg-newrelease">
                                       
                                                <div className="tg-sectionhead" style={{marginBottom: "-30px"}}>
                                                    <h2>                       
                                                        Book pictures
                                                    </h2>
                                                </div>
                                                <div >
                                                <BookPicturesSection bookPictures={this.state.bookPictures} />
                                                </div>
                                
                                        </div>
                                    </div>
                                </div>
                            </section>
                            
                            
              
                            
                        </div>
                           
                    </section>
                    </LoadIndicator>
                </div>
            );
        }
        return <BookNotFound bookid={this.props.bookId}/>;
    }
}

function BookNotFound({bookid}){
    return (
        <div className="container">   
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h2>Book Not Found</h2>
                <p>No book with ID: {bookid} found.</p>
            </div>
        </div>
    )
}

function BookPicturesSection({ bookPictures }) {
    if (!bookPictures) {
        return <p>No pictures associated with this book yet.</p>;
    }
    return (
        <section className="tg-sectionspace tg-haslayout">
            <div className="container">
                <div className="row">
                    <div className="tg-newrelease">
                        <div className="col-xs-20 col-sm-20 col-md-12 col-lg-12">
                            <div className="row row-eq-height-wrap">
                                {bookPictures.map(bookPicture =>
                                    <BookPicture key={bookPicture.pictureid} bookPicture={bookPicture} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

function BookPicture({ bookPicture }) {
    const pictureUrl = api.open.getPictureUrl(bookPicture.pictureid, 350);
    const fullScalePictureUrl = api.open.getPictureUrl(bookPicture.pictureid);
    const description = `Book picture "${bookPicture.name}"`;
    return (
        <div className="col-xs-8 col-sm-8 col-md-6 col-lg-4">
            <div className="tg-postbook">
                <ModalPictureDialog pictureUrl={fullScalePictureUrl} title={description}>
                    <img src={pictureUrl} alt={description} />
                </ModalPictureDialog>
                <div className="tg-postbookcontent">
                    <div style={{fontSize: "13px", lineHeight: "13px"}}>
                        <div>Name: {bookPicture.name || "-"}</div>
                  
                        <div style={{margin: "5px 0"}}>Description:</div>
                        <div>

                            <ReactMarkdown
                                source={bookPicture.description ? bookPicture.description : "-"}
                                escapeHtml={false} />
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
    );
}

export default withAuthContext(BookDetails);