import React from 'react'
import {Link, withRouter} from "react-router-dom"

import {withAuthContext} from "./AuthContext"
import MessageAlert from "./MessageAlert"


/*
    User Login section
    ----------------------------------------------------
    Main page for the user to login 
*/

class LoginSection extends React.Component{

    constructor(props){
        super(props);
        
        this.state = {
            username: "",
            password: "",
            loading: false,
            errorMessage: "",
            successMessage: "",
        }
    }

    // arrow function to not have to bind this to onChange
    onChange = (e) => {
        const {name, value} = e.target
        this.setState({[name]: value}) 
    }

    // arrow function to not have to bind this to onSubmit
    onSubmit = async (e) => {
        e.preventDefault(); 

        const {username, password, loading} = this.state

        if(!loading){

            this.setState({loading: true})

            const {login} = this.props.authContext;
            const response = await login(username, password)

            // handle Login succes / failure
            if(response){
                if(response.success){
                    this.setState({successMessage: response.message, loading: false})

                    // redirect either to set redirectUrl or Homepage
                    const {state} = this.props.location
                    const redirectPath = state && state.from ? state.from : "/"
                    this.props.history.push(redirectPath)

                    return;
                }else{                   
                    this.setState({errorMessage: response.message})
                }
            }
            this.setState({loading: false})
        }
    }

    removeErrorMessage = () => this.setState({errorMessage: ""})

    render(){
        return (
            <div className="container" style={{marginBottom: "200px"}}>
                <div className="row">
                    <div className="col-lg-8 col-lg-offset-2">
                  
                        <div className="page-header">
                            <h1>Login to cryptobooks.org</h1>
                            <p className="text-left">
                                CryptoBooks is an open database of cryptology books. Contribute by logging in.
                                No account yet? <Link to="/contact">Contact us</Link> to get an account.
                            </p>
                        </div>
                        
                        <MessageAlert type="error" message={this.state.errorMessage} onClose={this.removeErrorMessage} />
                        	
                        <form>
                            
                            <div className="form-group">
                                <label htmlFor="username">Username:</label>
                                <input type="text" name="username" className="form-control" value={this.state.username} onChange={this.onChange}/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="password">Password:</label>
                                <input type="password" name="password" className="form-control" value={this.state.password} onChange={this.onChange}/>
                            </div>
                            
                            <button className="btn btn-primary" onClick={this.onSubmit} onKeyDown={this.checkForSubmit} disabled={this.state.loading}>Login</button>
                        </form>                 
                    </div>
                </div>
            </div>
        )
    }
}

export default withAuthContext(withRouter(LoginSection));
