import React from 'react'
import api from "../api"
import SelectMultiple from "./SelectMultiple"
import {withAuthContext} from "./AuthContext"
import {userHasOnePermission, PERMISSION_ROLES} from "../CryptobooksConfig"



class BookInfoEdit extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            categoryOptions: [],
            isAdmin: false,
        }
    }

    componentDidMount = async () => {
        const categoryOptions = await api.open.getCategories();
        this.setState({categoryOptions})

        this.checkIsAdmin();
    }

    componentDidUpdate(prevProps){
        if(this.props.authContext && prevProps.authContext.user.userid !== this.props.authContext.user.userid){
            this.checkIsAdmin();
        }
    }

    checkIsAdmin = () => {
        if(this.props.authContext){
            if(userHasOnePermission(this.props.authContext.user, PERMISSION_ROLES.admin)){
                this.setState({isAdmin: true})
            }else{
                this.setState({isAdmin: false})
            }
        }   
    }

    render(){

        const {data} = this.props


        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="title">Titel</label>
                            <input 
                                className="form-control" 
                                name="title" 
                                placeholder="Title" 
                                value={data.title}
                                onChange={this.props.onChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-xs-12">
                        <div className="form-group">
                            <label htmlFor="lastname">Year</label>
                            <input 
                                className="form-control" 
                                name="year" 
                                type="number"
                                min="0"
                                max="9999"
                                placeholder="Year" 
                                value={data.year ? data.year : ""}
                                onChange={this.props.onChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-8 col-xs-12">
                        <div className="form-group">
                            <label htmlFor="keywords">Keywords</label>
                            <input 
                                className="form-control" 
                                name="keywords" 
                                placeholder="keywords" 
                                value={data.keywords}
                                onChange={this.props.onChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="categories">Categories</label>
                            <SelectMultiple 
                                className="form-control" 
                                name="categories" 
                                placeholder="categories" 
                                value={data.categories}
                                onChange={this.props.onChange}
                                options={this.state.categoryOptions.reduce((res, {categoryid, name}) => ({...res, [categoryid]: name}), {})}
                            />                   
                        </div>
                    </div>
                </div>
                   
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <textarea name="description" placeholder="description" className="form-control" value={data.description} onChange={this.props.onChange}/>
                        </div>
                    </div>
                </div>

                    {/* hide for non admins */}

                    {this.state.isAdmin && 
                        <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="checkbox-inline">
                                    <input 
                                        type="checkbox" 
                                        name="active" 
                                        value={true} 
                                        onChange={this.props.onCheckboxChange} 
                                        checked={data.active}
                                        /> 
                                        active
                                </label>
                            </div>
                        </div>
                        </div>
                    }              
            </div>
        
        )
    }
}

export default withAuthContext(BookInfoEdit)