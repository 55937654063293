import React, { Component } from 'react';
import { render } from 'react-dom';
import "./DialogConfirmDelete.css"; 

let resolve;


class DialogConfirmDelete extends Component {
  static create() {
    const containerElement = document.createElement('div');
    document.body.appendChild(containerElement);
    return render(<DialogConfirmDelete />, containerElement);
  }

  constructor() {
    super();

    this.state = {
      isOpen: false,
      showConfirmProps: {},
      heading: "",
      text: "",
    };

  }

  handleCancel = () => {
    this.setState({ isOpen: false });
    resolve(false);
  }

  handleConfirm = () => {
    this.setState({ isOpen: false });
    resolve(true);
  }

  show = (settings={}) => {
    this.setState({ isOpen: true, ...settings});
    return new Promise((res) => {
      resolve = res;
    });
  }

  render() {
    const { isOpen } = this.state;

    return (
        <div className={!isOpen ? 'delete-dialog' : 'delete-dialog delete-dialog--active'}>

          <div className="modal-dialog">
            <div className="modal-content panel-danger">
              <div className="modal-header panel-heading">
                <button type="button" className="close" onClick={this.handleCancel}>&times;</button>
                <h4 className="modal-title">{this.state.heading ? this.state.heading : "Confirm deletion"}</h4>
              </div>
              <div className="modal-body">
                {this.state.text ?
                  this.state.text 
                  : "Are you sure you want to permanently remove this item?"
                }
                
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default" onClick={this.handleCancel}>Abort</button>
                <button type="button" className="btn btn-danger" onClick={this.handleConfirm}>Delete</button>
              </div>
            </div>
          </div>
           
        </div>
    );
  }
}

export default DialogConfirmDelete.create();

/* 

 <div className="delete-dialog__modal">
                <h2>Confirm Delete?</h2>
                <p>Are you sure you want to delete this item?</p>
                <div>
                    <button className="btn btn-danger" onClick={this.handleConfirm}>Delete</button>
                    <button className="btn btn-dange" onClick={this.handleCancel}>Cancel</button>
                </div>
            </div>*/