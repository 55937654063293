import React from 'react'

import "./DynamicTable.css";

/*  

    display data in table form

    make it searchable

    &s=
    &=
    &itemsPerPage=20
    &sortDir=[]
    &sortBy=[colName]

    ussage

    <DynamicTable
        data={}
        renderRow={(itemData, rowIdx) => (

        )}
        onSort={}
    >
        <DynamicTableCol title="" dataRef="name" sortable=true />
        <DynamicTableCol />

    </DynamicTable>

*/




export const intialTableState = {
    orderBy: "",
    orderDir: "ASC",
    page: 1,
    totalPages: 1,
}

/*
    updating:
    deleting:
    active:
*/
export const getDynamicTableRowClasses = (item) => {
    let classes = "dynamic-table-row";
    if(item && item.updating){
        classes += " dynamic-table-row--updating"
    }

    if(item && item.deleting){
        classes += " dynamic-table-row--deleting"
    }

    if(item && item.active === false){
        classes += " dynamic-table-row--inactive"
    }

    return classes
}


class DynamicTable extends React.Component{

    constructor(props){
        super(props);

        this.columnCount = this.props.children.length;
    }

    onPageClick = (pageIdx) => {
        this.props.onTableStateUpdate({
            ...this.props.tableState,
            page: pageIdx
        })
    }

    onOrderClick = (dataRef) => {
        let {orderBy, orderDir} = this.props.tableState
        
        // order dir to asc on swithc
        // order dir switch if not switched

        if(dataRef !== orderBy){
            orderBy = dataRef;
            orderDir = "ASC"
        }else{
            orderDir = orderDir === "ASC" ? "DESC" : "ASC"; 
        }

        this.props.onTableStateUpdate({
            ...this.props.tableState,
            orderBy,
            orderDir
        })
        
    }


    renderTableColumns = () => {
        return React.Children.map(this.props.children, child =>
            React.cloneElement(child, {onOrderClick: this.onOrderClick, tableState: this.props.tableState})
        ); 
    }

    renderPaginationButtons = () => {
        const {page, totalPages} = this.props.tableState;

        // get 5 or les between always 1 and last
        const showPagesButtons = [1]
        //showPagesButtons = new Set(showPagesButtons);

        for(let i = page - 2; i <= page + 2; i++){
            if(i > 0 && i <= totalPages){
                showPagesButtons.push(i)
            }
        }

        if(totalPages > 1){
            showPagesButtons.push(totalPages)
        }

        const pageNumsSet = new Set(showPagesButtons);
        const pageNumArray = [...pageNumsSet];

        if(pageNumArray.length === 1){
            return null;
        }
        
        return pageNumArray.map(pageNum => (
            <button
                className={pageNum === page ? "dynamic-table__pagination-btn dynamic-table__pagination-btn--current" : "dynamic-table__pagination-btn"}
                onClick={() => this.onPageClick(pageNum)}
                key={`page-btn-${pageNum}`}
            >
                {pageNum}
            </button>
        ))      
    }

    renderRow = (item, index) => {

        if(item.updating){
            return <tr className="dynamic-table-row--updating"><td colSpan={this.columnCount} className="text-center">Updating...</td></tr>
        }

        if(item.deleting){
            return <tr className="dynamic-table-row--updating"><td colSpan={this.columnCount} className="text-center">Deleting...</td></tr>
        }

        return this.props.renderRowItem({item, index});
    }

    render(){
      

        return (
            <div>
            <div className="table-responsive">
                <table className="table table-bordered table-striped table-sm">
                    <thead>
                        <tr>
                          
                            {this.renderTableColumns()}                               
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data && this.props.data.length > 0 ? 
                            this.props.data.map((item, index) => {
                                return this.renderRow(item, index);          
                            })
                            :
                            <tr><td colSpan={this.columnCount} className="text-center">{this.props.emptyMessage || "No items to display"}</td></tr>
                        }            
                    </tbody>
                </table>
            </div>
            {this.props.pagination && 
                <div className="dynamic-table__pagination">
                    {this.renderPaginationButtons()}
                </div>  
            }       
            </div>
        )
    }
}

export class DynamicTableCol extends React.Component{


    onOrderClick = () => {
        const {onOrderClick, dataRef, sortable} = this.props;

        if(dataRef && sortable && onOrderClick){
            this.props.onOrderClick(dataRef);
        }else{
            console.error("To sort this column the table col must be sortable and have a valid dataRef")
        }
   
    }

    render(){

        const {title, dataRef, sortable, tableState} = this.props;
        const displayTitle = title ? title : dataRef;

        const currentlySorted = tableState.orderBy === dataRef
       
        let className = sortable ? "dynamic-table-col  dynamic-table-col--sortable" : "dynamic-table-col";


        className = currentlySorted ? className += " dynamic-table-col--active" : className
     
        return (
            <th className={className} onClick={this.onOrderClick}>
                <div className="dynamic-table-col__container" >
                    {displayTitle}
                    {sortable &&
                        (   
                            <div className="dynamic-table-col__sort-btn" >
                                { currentlySorted && tableState.orderDir === "DESC" ?
                                     <i className="icon-menu4"></i> 
                                    :
                                    <i className="icon-menu3"></i> 
                                }   
                            </div>                         
                        )
                    }
                </div>          
            </th>
        )
    }
}



export default DynamicTable