import React from 'react'
import "./SelectMultiple.css"


/*


props:
    - value
    - onChange
    - placeholder
    - name
    - seperator



<SelectMultiple 
    name="name" 
    placeholder="DisplayName" 
    value={state.value}
    onChange={this.onChange} 
>
    <option value="value">DisplayName</option>
    <option value="value">DisplayName</option>
    <option value="value">DisplayName</option>
    <option value="value">DisplayName</option>
</SelectMultiple>


value and display name  as object

{
    value: displayName
    0: Historic

}


<SelectMultiple 
    name=""
    onChange=""
    value={state seperated value}
    sperator=""

/>


<SelectMultiple 

    options={}
/>


options = displayValue, value


value: displayvalue

value_array => 

options[value] => displayName



*/




class SelectMultiple extends React.Component{
    constructor(props){
        super(props);

        this.separator = this.props.seperator || ","; 
        this.inputRef = React.createRef();

        this.state = {
            select_display: "",
            value_array: this.parseValue(this.props.value),
        }
    }

    parseValue = (value) => value ? value.split(this.separator) : []

    componentDidUpdate = (prevProps) => {
        if(prevProps.value !== this.props.value){
            this.setState({value_array: this.parseValue(this.props.value)})
        }
    }

    onSelectChange = (e) => {
        const inputValue = e.target.value
        const currentValue = this.props.value
        const {value_array} = this.state

        // block any input to select
        this.setState({select_display: inputValue})
       
        // check if inputValue has not already been added
        if(!value_array.includes(inputValue)){
            // add inputValue to total value sperated by seperator
            const newValue = currentValue ? [currentValue, inputValue].join(this.separator) : inputValue
            this.updateValue(newValue)
        }

        // unblock input to select
       
        this.setState({select_display: ""})

    }

    removeItem = (removeValue) => {
        const {value_array} = this.state

        // remove item from value
        const newValue = value_array.reduce(
            (res, itemValue) => itemValue === removeValue ? res : [...res, itemValue]
        ,[]).join(",")

       this.updateValue(newValue)
    }

    // triggers onChange event of the hidden real input
    updateValue = (value) => {
        var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
        nativeInputValueSetter.call(this.inputRef.current, value);

        var ev = new Event('input', { bubbles: true});
        this.inputRef.current.dispatchEvent(ev)
    }

    render(){
        return (
            <div className="select-multiple">
                <input className="select-multiple__hidden-input" 
                    value={this.props.value}
                    onChange={this.props.onChange}
                    name={this.props.name}
                    ref={this.inputRef}
                />
                <input 
                    className={`form-control select-multiple__input ${this.props.value && 'select-multiple__input--hide-placeholder'}`}
                    disabled 
                    placeholder={this.props.placeholder}
                />
                <select name="multiple_select" className="form-control select-multiple__select" onChange={this.onSelectChange} value={this.state.select_display} disabled={this.state.isLoading}>
                    <option value="">Add new...</option>

                    {this.props.options && Object.keys(this.props.options).map((key) => {
                        return <option key={`select-${key}`} value={key}>{this.props.options[key]}</option>
                    })}
                </select>
                <div className="select-multiple__items">
                    {this.state.value_array.map((value, idx) => (
                        <SelectMultipleDisplayItem key={`sel-item-${value}-${idx}`} value={value} displayName={this.props.options[value]} onRemove={this.removeItem} />
                    ))}     
                </div>
            </div>)
        }
}


const SelectMultipleDisplayItem = ({value, displayName, onRemove}) => (
    <div className="select-item">
        <span>{displayName}</span>
        <button type="button" className="close select-item__close" onClick={() => onRemove(value)}>
            <span>x</span>
        </button>
    </div>
)

export default SelectMultiple


/*
class SelectMultiple extends React.Component {

    constructor(props){
        super(props);

        this.separator = this.props.seperator || ","; 
        this.inputRef = React.createRef();

        this.state = {
            select_display: "",
            value_array: this.parseValue(this.props.value),
            isLoading: false,
        }
    }

    parseValue = (value) => value ? value.split(this.separator) : []


    // update display value if props value changes
    componentDidUpdate = (prevProps) => {
        if(prevProps.value !== this.props.value){
            this.setState({value_array: this.parseValue(this.props.value)})
        }
    }

    onSelectChange = (e) => {
        const inputValue = e.target.value
        const currentValue = this.props.value
        const {value_array} = this.state

        // block any input to select
        this.setState({isLoading: true, select_display: inputValue})
       
        // check if inputValue has not already been added
        if(!value_array.includes(inputValue)){
            // add inputValue to total value sperated by seperator
            const newValue = currentValue ? [currentValue, inputValue].join(this.separator) : inputValue
            this.updateValue(newValue)
        }

        // unblock input to select
        setTimeout(() => 
            this.setState({isLoading: false, select_display: ""})
        ,300)
    }

    removeItem = (removeValue) => {
        const {value_array} = this.state

        // remove item from value
        const newValue = value_array.reduce(
            (res, itemValue) => itemValue === removeValue ? res : [...res, itemValue]
        ,[]).join(",")

       this.updateValue(newValue)
    }

    // triggers onChange event of the hidden real input
    updateValue = (value) => {
        var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
        nativeInputValueSetter.call(this.inputRef.current, value);

        var ev = new Event('input', { bubbles: true});
        this.inputRef.current.dispatchEvent(ev)
    }

    render(){
    return (
        <div className="select-multiple">
            <input className="select-multiple__hidden-input" 
                value={this.props.value}
                onChange={this.props.onChange}
                name={this.props.name}
                ref={this.inputRef}
            />
            <input 
                className={`form-control select-multiple__input ${this.props.value && 'select-multiple__input--hide-placeholder'}`}
                disabled 
                placeholder={this.props.placeholder}
            />
            <select name="multiple_select" className="form-control select-multiple__select" onChange={this.onSelectChange} value={this.state.select_display} disabled={this.state.isLoading}>
                <option value="">Add New...</option>
                {this.props.options && Object.keys(this.props.options).map(key =>{
                    const value = this.props.options[key].value
                    const displayValue = this.props.options[key].displayValue
                    if(!value) return null

                    return <option key={`select-${key}`} value={value}>{displayValue}</option>
                })}

                {this.props.children}
            </select>
            <div className="select-multiple__items">
                {this.state.value_array.map((value, idx) => (
                    <SelectMultipleDisplayItem key={`sel-item-${value}-${idx}`} value={value} onRemove={this.removeItem} />
                ))}     
            </div>
        </div>)
    }
}

const SelectMultipleDisplayItem = ({value, onRemove}) => (
    <div className="select-item">
        <span>{value}</span>
        <button type="button" className="close select-item__close" onClick={() => onRemove(value)}>
            <span>x</span>
        </button>
    </div>
)



export default SelectMultiple*/