import React from 'react';
import QuickSearchBar from './QuickSearchBar.js';
import { Link, withRouter } from "react-router-dom";
import {AuthConsumer} from "./AuthContext"
import {userHasOnePermission, PERMISSION_ROLES} from "../CryptobooksConfig"

import "./AppHeader.css";


function HeaderNavbar(){
  return (
    <AuthConsumer>
      {({user, isLoggedIn, logout}) => (
      <div className="tg-topbar">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">

              <nav className="main-navbar">
                <div className="navigation-header">
                <button type="button" className="navbar-toggle main-navbar__toggle collapsed" data-toggle="collapse" data-target="#cryptobooks-main-nav" aria-expanded="false">
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                </div>

                <div className="collapse navbar-collapse" id="cryptobooks-main-nav">
                <div className="main-navbar__navigation">

                  <ul className="main-navbar__links">
                    <li>
                      <Link to="/">
                        <i className="icon-home2"></i>
                        <em>Home</em>
                      </Link>
                    </li>
                    <li>
                      <Link to="/books">
                        <i className="icon-books"></i>
                        <em>All books</em>
                      </Link>
                    </li>
                  </ul>

                  <ul className="main-navbar__links main-navbar__links--right">
                    <li>
                      <Link to="/imprint">
                        <i className="icon-question-circle"></i>
                        <em>Imprint</em>
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact">
                        <i className="icon-envelope"></i>
                        <em>Contact</em>
                      </Link>
                    </li>
                    <li>
                      <Link to="/links">
                        <i className="icon-link"></i>
                        <em>Links</em>
                      </Link>
                    </li>
              
                    { isLoggedIn ? 
                      <React.Fragment>
                        <li className="dropdown">
                          <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                              <i className="icon-user"></i>{user.username} <span className="caret"></span>
                          </a>
                          <ul className="dropdown-menu">
                            <li><Link to={`/users/${user.userid}/edit`}>Profile</Link></li>
                            <li><Link to="/book">Add book</Link></li>
                            <li><Link to="/author">Add author</Link></li>
                            <li><Link to={`/users/${user.userid}/contributions`}>Contributions</Link></li>

                            {userHasOnePermission(user, PERMISSION_ROLES.admin) && (
                              <React.Fragment>
                                <li role="separator" className="divider"></li>
                                <li><Link to="/users">Manage users</Link></li>
                                <li><Link to="/categories">Manage categories</Link></li>
                                <li><Link to="/manage-inactive">Manage inactive</Link></li>
                              </React.Fragment>
                            )}
                            
                            <li role="separator" className="divider"></li>
                            <li><a onClick={(e)=> {e.preventDefault(); logout()}} style={{cursor: "pointer"}}>Logout </a></li>
                          </ul>                                     
                        </li>                
                      </React.Fragment>
                      :
                      <li>
                          <Link to="/login">
                            <i className="icon-user"></i>
                            <em>Login</em>
                          </Link>
                      </li>
                    }

                  </ul>
                </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      )}
    </AuthConsumer>
  );
}


function AppHeader(props) {
  const handleSearchRequest = function (searchInput, yearFrom, yearTo) {
    let url = `/search`;
    if (searchInput) {
      url = `${url}/${searchInput}`
    }
    if (yearFrom || yearTo) {
      url = `${url}?yearFrom=${yearFrom || ''}&yearTo=${yearTo || ''}`;
    }
    props.history.push(url);
  };

  return (
    <div >
      <HeaderNavbar />

      <div className="tg-middlecontainer">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong className="tg-logo">
                <Link to="/">
                  <img src="/images/cb_logo.jpg" alt="CRYPTOBOOKS" />
                </Link>
              </strong>
              <QuickSearchBar
                onSearchRequestHandler={handleSearchRequest} />
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default withRouter(AppHeader);