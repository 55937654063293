import React from 'react'



const MessageAlert = ({type, message, onClose}) => {

       
    let alertTypeClass = "";
    switch(type){
        case 'success': alertTypeClass = "alert-success"; break;
        case 'info': alertTypeClass = "alert-info"; break;
        case 'warning': alertTypeClass = "alert-warning"; break;
        case 'error': alertTypeClass = "alert-danger"; break;
        default: alertTypeClass = "alert-info"; 
    }

    return message &&  (  
        <div className={`alert ${alertTypeClass}`} role="alert">
            <strong className="text-uppercase">{type === "success" ? "" : `${type}!`}</strong> {message}
            <button type="button" className="close" onClick={onClose}  aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    )
}


export default MessageAlert