import React from 'react'

import PictureInput from "./PictureInput"


export const emptyPictureState = {
    src: null,
    title: "",
    description: "",
}


const PictureEdit = ({data, namePrefix, onChange, onPictureChange, pictureId}) =>{
   
    const srcName = namePrefix ? namePrefix + "_src" : "src";
    const titleName = namePrefix ? namePrefix + "_title" : "title";
    const descriptionName = namePrefix ? namePrefix + "_description" : "description";

    return (
    <div className="picture-edit">     
        <PictureInput 
            name={srcName}
            onChange={onPictureChange}
            value={data[srcName] || null}
            id={pictureId || null}
        />
        
        <div className="form-group form-group-sm">
            <input 
                className="form-control" 
                name={titleName}
                placeholder="Picture title"            
                onChange={onChange}
                value={data[titleName] || ""}
            />
        </div>

        <div className="form-group form-group-sm">
            <textarea 
                className="form-control" 
                placeholder="Picture description"
                name={descriptionName}
                onChange={onChange}
                value={data[descriptionName] || ""}
            ></textarea>
        </div>
    </div>
)}


    
export default PictureEdit;